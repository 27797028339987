<template>
  <div>

    <b-button expanded type="is-light"  icon-left="refresh" @click="fetchData()">Aktualisieren</b-button>


    <div class="tile is-ancestor">
      <div class="tile is-vertical" v-for="image in data" v-bind:key="image.id">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by3">
              <img :src=image.url />
            </figure>
          </div>
          <footer class="card-footer">
            <a @click="deleteImage(image.id)" class="card-footer-item">Löschen</a>
          </footer>
        </div>
      </div>
    </div>






<!--    <b-image v-for="image in data" v-bind:key="image.id"-->
<!--        :src=image.url style="max-width:300px;max-height:300px;display: inline-block"-->

<!--    >-->
<!--      <template #placeholder>-->
<!--        <b-skeleton-->
<!--            class="skeleton-placeholder"-->
<!--            height="100%"-->
<!--        ></b-skeleton>-->
<!--      </template>-->
<!--    </b-image>-->

    <b-button expanded type="is-primary is-light is-large"  icon-left="upload" @click="openMediaUploader()">Upload</b-button>
  </div>
</template>

<script>
import MediaUploadPrompt from "@/components/MediaUploadPrompt";
import apiservice from "@/services/apiservice";

export default {
  data() {
    return {
      name: "MediaViewer",
      data: []


    }
  },
  props: ['object_type', 'object_id'],
  methods: {
    openMediaUploader: function() {
      let self = this
      console.log(self.object_type)
      this.$buefy.modal.open({
        component: MediaUploadPrompt,
        parent: this,
        props: {
          'object_type': self.object_type,
          'object_id': self.object_id
        },
        onCancel: function () {
          console.log()
          this.fetchData()
        },
        onConfirm: function () {
          console.log()
          this.fetchData()
        },
        events: {
        }
      })
    },
    fetchData: function() {
      console.log("Fetch media")
      apiservice()
          .get('media/image/' + this.object_type  + '/' + this.object_id)
          .then(response => (this.data = response.data.data));
    },
    deleteImage: function(id) {
      console.log("Delete media")

      let self = this;
      this.$buefy.dialog.confirm({
        title: 'Bild löschen?',
        message: 'Sind Sie sicher, dass Sie das Bild aus diesem Artikel löschen möchten?',
        confirmText: 'Ja, löschen',
        cancelText: 'Nein',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          apiservice()
              .delete('media/image/' + this.object_type  + '/' + this.object_id + '/' + id)
              .then(function (response) {

                self.$buefy.toast.open({
                  message: 'Bild gelöscht',
                  position: 'is-bottom',
                  type: 'is-success'
                });
                console.log(response);
                self.fetchData()
              })
              .catch(function (error) {
                self.$buefy.dialog.alert({
                  title: 'Fehler',
                  message: 'Ein Fehler ist aufgetreten: ' + error,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                console.log(error);
              });
        }
      })




    },


  },
  beforeMount() {
    this.fetchData()
  }
}
</script>