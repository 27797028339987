<template>
  <b-select placeholder="Lieferant" v-model="value"
            v-bind:class="{ 'is-loading': suppliers == [] }"
            @input="updateValue">
    <option
        v-for="option in suppliers"
        :value="option"
        :key="option.id">
      {{ option.name }}
    </option>
  </b-select>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'SupplierPicker',
  data() {
    return {
      suppliers: []
    }
  },
  props: {
    value: Object
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get("suppliers")
          .then(response => (this.suppliers = response.data.data));
    },
    updateValue() {
      this.$emit('input', this.value)
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>