import { render, staticRenderFns } from "./SupplierOverview.vue?vue&type=template&id=628382c4&scoped=true&"
import script from "./SupplierOverview.vue?vue&type=script&lang=js&"
export * from "./SupplierOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "628382c4",
  null
  
)

export default component.exports