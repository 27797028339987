<template>
  <b-select placeholder="Einheit" v-model="value" v-bind:class="{ 'is-loading': items == [] }" @input="updateValue">
    <option
        v-for="option in items"
        :value="option"
        :key="option.id">
      {{ option.full_name }} ({{option.username}}) <!--({{ option.name }})-->
    </option>
  </b-select>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'UserPicker',
  data() {
    return {
      items: []
    }
  },
  props: {
    value: Object
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get("account")
          .then(response => (this.items = response.data.data));
    },
    updateValue() {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  },
  computed: {
  },
  beforeMount(){
    this.fetchData()
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>