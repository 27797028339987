<template>
  <div class="dashboard">
    <section class="hero is-large is-primary">
      <div class="hero-body">
        <p class="title">
          Dashboard
        </p>
        <p class="subtitle">
          Benker Fliesenfachgeschäft GmbH
        </p>
      </div>
    </section>
    <div><b-field>
      <b-button icon-left="chevron-right" icon-right="chevron-right" icon-pack="fa" expanded class="is-primary is-medium" @click="$router.push({ path: `/stockmobile` })">
        Lager mobil
      </b-button>
    </b-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
