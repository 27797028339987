<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Lieferant für "{{ article.name }}"</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
<!--        <b-field grouped>-->
<!--&lt;!&ndash;          <b-input v-model="data.color"></b-input>&ndash;&gt;-->
<!--          <b-numberinput controls-position="compact" controls-rounded v-model="data.factor" step="0.01"></b-numberinput>-->
<!--          <UnitPicker  />-->
<!--          <b-button type="is-text" style="text-decoration: none">je {{ default_unit.name }} ({{ default_unit.symbol }})</b-button>-->
<!--        </b-field>-->


        <b-field label="Lieferant" horizontal>
          <SupplierPicker v-model="data.supplier" />
        </b-field>

        <b-field label="EK-Preis, effektiv (€)" horizontal>
          <b-numberinput controls-position="compact" controls-rounded v-model="data.cost_price" step="0.01"></b-numberinput>
        </b-field>

        <b-field label="Listenpreis (€)" horizontal>
          <b-numberinput controls-position="compact" controls-rounded v-model="data.list_price" step="0.01"></b-numberinput>
        </b-field>

        <b-field label="Standardlieferant" horizontal>
          <b-switch v-model="data.default_supplier">
            {{ data.default_supplier ? "Ja" : "Nein" }}
          </b-switch>
        </b-field>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="OK"
            type="is-primary"
            :disabled="data.supplier==null || data.cost_price==null"
            @click="confirm" />
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


// import apiservice from "@/services/apiservice";
import SupplierPicker from "@/components/SupplierPicker";
import apiservice from "@/services/apiservice";

export default {
  name: 'ArticleSupplierPrompt',
  components: {SupplierPicker},
  data() {
    return {
      data: {'default_supplier': true},
      isFetching: true
    }
  },
  props: {
    // value: Object,
    // default_unit: Object
    article: Object,
    id: Number
  },
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("articles/supplier/" + this.id)
          .then(response => (this.data = response.data.data))
          .catch(function (error) {
            this.$buefy.dialog.Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
      this.isFetching = false;
    },
    confirm() {
      this.data.supplier_id = this.data.supplier.id
      this.$emit('confirm', this.data)
      this.$emit('close')
      // apiservice()
      //     .get("articles/" + this.value.id + "/units")
      //     .then(response => {
      //       this.$emit('confirm', response.data.data)
      //       this.$emit('close')
      //     })
      }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>