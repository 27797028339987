<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Lieferanten für "{{article.name}}"</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
          <div class="control">
            <b-button class="button is-primary" @click="addArticleSupplier('new')" expanded icon-pack="fa" icon-left="link">Neuer Lieferant</b-button>
          </div>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="true"
            :pagination-position="bottom"
            :pagination-simple="true"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
        >

          <template>


            <b-table-column field="supplier.name" label="Lieferant" searchable sortable v-slot="props">
              {{ props.row.supplier.name }}
            </b-table-column>

            <b-table-column field="cost_price" label="EK-Preis" searchable sortable v-slot="props">
              {{ props.row.cost_price }}
            </b-table-column>

            <b-table-column field="list_price" label="Listenreis" searchable sortable v-slot="props">
              {{ props.row.list_price }}
            </b-table-column>

            <b-table-column field="default_supplier" label="Standard-Lieferant" v-slot="props">
              {{ props.row.default_supplier ? "Ja" : "Nein" }}
            </b-table-column>

            <b-table-column field="action" label="Aktionen" v-slot="props">
              <b-button class="button is-primary" @click="addArticleSupplier(props.row.id)" expanded icon-pack="fa" icon-left="edit"></b-button>
            </b-table-column>

          </template>
        </b-table>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Schließen"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";
import ArticleSupplierPrompt from "@/components/ArticleSupplierPrompt";
// import ServiceSummary from "@/components/ServiceSummary";

export default {
  name: 'ArticleSuppliers',
  data() {
    return {
      data: [],
      isFetching: true
    }
  },
  props: ['article', 'default_unit'],
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("articles/" + this.article.id + "/suppliers")
          .then(response => (this.data = response.data.data));
      this.isFetching = false;
    },
    confirm() {
      this.$emit('confirm', this.value)
      this.$emit('close')
    },
    addArticleSupplier: function(id) {
      this.$buefy.modal.open({
        component: ArticleSupplierPrompt,
        parent: this,
        props: {
          // 'default_unit': this.default_unit,
          'article': this.article,
          'id': id
        },

        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {

            // let post_data = {'id':value.id}
            let self = this;

            apiservice().post("articles/" + this.article.id + "/suppliers", value)
                .then(function (response) {
                  self.$buefy.toast.open({
                    message: 'Lieferant hinzugefügt',
                    position: 'is-bottom',
                    type: 'is-success'
                  })
                  console.log(response);
                  self.fetchData();
                })
                .catch(function (error) {
                  self.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                  console.log(error);
                });



            this.fetchData();
          }

        }
      })
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>