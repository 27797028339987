<template>
  <div class="stockmobile">
    <section class="hero is-primary mb-3">
      <div class="hero-body">
        <p class="title">
          Lager Mobil
        </p>
<!--        <p class="subtitle">-->
<!--          Primary subtitle-->
<!--        </p>-->
      </div>
    </section>

<!--    <b-field>-->
<!--      <b-button icon-left="plus" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="$router.push({ path: `/stockmobile/incoming` })">-->
<!--        Wareneingang-->
<!--      </b-button>-->
<!--    </b-field>-->

    <b-field>
      <b-button icon-left="box-open" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="$router.push({ path: `/stockmobile/picking` })">
        Kommissionieren
      </b-button>
    </b-field>

    <b-field>
      <b-button icon-left="truck" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="$router.push({ path: `/stockmobile/shipping` })">
        Warenausgang
      </b-button>
    </b-field>


  </div>
</template>

<script>
export default {
  name: 'StockMobile',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
