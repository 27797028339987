<template>
  <b-field>
<!--    <b-tooltip position="is-bottom" multilined>-->
    <span class="input is-primary" type="is-primary" @click="summary">{{ value ? value.name : '-/-' }}</span>

<!--      <template v-slot:content>-->
<!--        <div style="text-align: left"><b>{{ value.name }}</b><br />{{ value.street}}<br />{{value.zip}} {{value.place}}<br />{{value.country}}<br /><br />-->
<!--          {{ value.phone }}</div>-->
<!--      </template>-->
<!--    </b-tooltip>-->
    <p class="control">
      <b-button type="is-primary" @click="pickPrompt" :disabled="this.$attrs.disabled"><i class="fa fa-edit"></i></b-button>
    </p>


  </b-field>
</template>

<script>

// import apiservice from "@/services/apiservice";
import CustomerPickerPromt from "@/components/CustomerPickerPromt";
import CustomerSummary from "@/components/CustomerSummary";


export default {
  name: 'CustomerPicker',
  data() {
    return {
      items: []
    }
  },
  props: {
    value: Object
  },
  methods: {
    updateValue() {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    },
    summary() {
      if (this.value) {
        this.$buefy.modal.open({
          component: CustomerSummary,
          parent: this,
          props: {
            'customer_id': this.value.id
          },
          onCancel: function () {
            console.log()
          },
          events: {
          }
        })
      }
    },
    pickPrompt: function() {
      let self = this;
      this.$buefy.modal.open({
        component: CustomerPickerPromt,
        parent: this,
        props: {
          // "question": question
        },
        onCancel: function () {
          console.log();
        },
        events: {
          'confirm': newValue => {
            self.value = newValue;
            self.updateValue();
          }
        }
      })
    }
  }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>