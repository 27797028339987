<template>
  <b-select placeholder="Artikelgruppe" v-model="value"
            v-bind:class="{ 'is-loading': articleCategories == [] }" @input="updateValue">
    <option
        v-for="option in articleCategories"
        :value="option"
        class="is-loading"
        :key="option.id"
        >
      {{ option.name }}
    </option>
  </b-select>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'ArticleCategoryPicker',
  data() {
    return {
      articleCategories: []
    }
  },
  props: {
    value: Object
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get("articles/categories")
          .then(response => (this.articleCategories = response.data.data));
    },
    updateValue() {
      this.$emit('input', this.value)
    }
  },
  computed: {
  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>