<template>


      <b-field>
        <b-autocomplete
            :disabled="this.$attrs.disabled"
            v-model="manufacturer_input"
            ref="autocomplete"
            :data="filteredDataArray(Object.keys(this.data))"
            :keep-first="keepFirst"
            :open-on-focus=true
            placeholder="Hersteller"
            @select="option => manufacturer = option"
            :selectable-header="selectable">
          <template #empty>Keine Ergebnisse für {{manufacturer_input}}</template>
        </b-autocomplete>

        <b-autocomplete
            :disabled="this.$attrs.disabled"
            v-model="series_input"
            ref="autocomplete"
            :data="filteredSeries()"
            :keep-first=true
            :open-on-focus=true
            placeholder="Serie"
            @select="option => series = option">
          <template #empty>Keine Ergebnisse für {{series_input}}</template>
        </b-autocomplete>

        <b-autocomplete
            :disabled="this.$attrs.disabled"
            v-model="color_input"
            ref="autocomplete"
            :data="filteredColors()"
            :keep-first=true
            :open-on-focus=true
            placeholder="Farbe"
            @select="option => color = option">
          <template #empty>Keine Ergebnisse für {{color_input}}</template>
        </b-autocomplete>
      </b-field>

</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'SeriesPicker',
  data() {
    return {
      data: {
        "[LEER]": {
          "[LEER]": ['[LEER]']
        }
      },
      manufacturer_input: "",
      series_input: "",
      color_input: "",

      manufacturer: "",
      series: "",
      color: ""
    }
  },
  props: {
    value: Array
  },
  watch: {

    manufacturer: function() {
      this.updateValue();
    },
    series: function() {
      this.updateValue();
    },
    color: function() {
      this.updateValue();
    }
  },
  computed: {

  },
  methods: {
    filteredDataArray: function(values) {
      return values.filter((option) => {
        return option
            .toString()
            .toLowerCase()
            .indexOf(this.manufacturer_input.toLowerCase()) >= 0
      })
    },
    filteredSeries: function() {
      if (!this.manufacturer || this.manufacturer == ""){
        return [""]
      }
      return Object.keys(this.data[this.manufacturer]).filter((option) => {
        return option
            .toString()
            .toLowerCase()
            .indexOf(this.series_input.toLowerCase()) >= 0
      })
    },
    filteredColors: function() {
      if (!this.series || this.series == ""){
        return [""]
      }
      return this.data[this.manufacturer][this.series].filter((option) => {
        return option
            .toString()
            .toLowerCase()
            .indexOf(this.color_input.toLowerCase()) >= 0
      })
    },
    fetchData: function() {
      apiservice()
          .get("series/")
          .then(response => (this.data = response.data.data));
    },

    updateValue() {
      this.value = [this.manufacturer? this.manufacturer : "", this.series ? this.series : "", this.color? this.color : ""];
      this.$emit('input', this.value)
    },

  },

  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*.highlighted {*/
  /*  font-weight: bold;*/
  /*  background: #8c67ef;*/
  /*  color: #fff;*/
  /*}*/
</style>