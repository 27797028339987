<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Bestandskorrektur</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">

<!--        <b-field position="is-centered">-->
<!--          <b-radio-button v-model="stock_entry_mode"-->
<!--                          native-value="in"-->
<!--                          type="is-success" expanded>-->
<!--            <b-icon icon="plus"></b-icon>-->
<!--            <span>Eingang</span>-->
<!--          </b-radio-button>-->

<!--          <b-radio-button v-model="stock_entry_mode"-->
<!--                          native-value="reloc"-->
<!--                          type="is-warning" expanded>-->
<!--            <b-icon icon="sync"></b-icon>-->
<!--            <span>Umbuchung</span>-->
<!--          </b-radio-button>-->

<!--          <b-radio-button v-model="stock_entry_mode"-->
<!--                          native-value="out"-->
<!--                          type="is-danger" expanded>-->
<!--            <b-icon icon="minus"></b-icon>-->
<!--            <span>Ausgang</span>-->
<!--          </b-radio-button>-->

<!--        </b-field>-->

        <b-field label="Artikel:">
<!--          <ArticlePicker v-model="article" @change="refreshCharges" required />-->
          {{ stock.article_charge.article.id }}<br />
          {{ stock.article_charge.article.name }}
        </b-field>


        <b-field label="Charge:">
          {{ stock.article_charge.name }}
        </b-field>

        <b-field label="Lagerplatz:">
          Halle {{ stock.stock_place.warehouse }} | Regal {{ stock.stock_place.rack }} | Fach {{ stock.stock_place.shelf }}
        </b-field>

        <b-field label="Bestand laut System:">
          {{ stock.amount }} {{ stock.article_charge.article.default_unit_symbol }}
        </b-field>


<!--        <b-field label="Lagerplatz (Ausgang):" v-if="['out', 'reloc'].includes(stock_entry_mode)">-->
<!--          <StockPlacePicker v-model="data.stock_place_out" :article_charge="article_charge" required />-->
<!--        </b-field>-->

<!--        <b-field label="Lagerplatz (Eingang):">-->
<!--          <StockPlacePicker v-model="data.stock_place_in" :article_charge="article_charge" :hide_nonstocked="stock_entry_mode == -1" required />-->
<!--        </b-field>-->



<!--        <b-field label="Wert (€) je Basiseinheit">-->
<!--          <b-numberinput controls-position="compact" controls-rounded v-model="data.stock_value" step="0.01"></b-numberinput>-->
<!--        </b-field>-->

        <b-field grouped label="Menge:">
          <b-field>
            <b-numberinput

                v-model="amount_input"
                placeholder="Veränderung"
                min="0.01"
                step="1"
                min-step="0.01"
                controls-position="compact"
                controls-rounded
                required>
            </b-numberinput>
          </b-field>
          <b-field>
            <ArticleUnitPicker v-model="unit_input" :article_id="stock.article_charge.article ? stock.article_charge.article.id : null" />
          </b-field>
        </b-field>


        <b-field label="Entspricht:">
<!--           v-if="user_input && stock.article_charge.article.default_unit_symbol != unit_input.unit.symbol" >-->
          <span class="is-size-4">{{ amount }} {{stock.article_charge.article.default_unit_symbol}}</span>
        </b-field>

        <b-field label="Zusätzlicher Kommentar (optional):">
          <b-input
              type="text"
              v-model="data.comment"
              placeholder="Kommentar"
              >
          </b-input>
        </b-field>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Abbrechen"
            @click="$emit('close')" />
        <b-button
            label="Buchen"
            type="is-primary"
            @click="save"
            :disabled=" false && (!amount_input || amount_input<=0 || !amount || inProgress)"
        />
      </footer>
    </div>
  </form>
</template>

<script>

import StockPlacePicker from "@/components/StockPlacePicker";
import apiservice from "@/services/apiservice";
import ArticleUnitPicker from "@/components/ArticleUnitPicker";
import ArticlePicker from "@/components/ArticlePicker";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'StockBookIn',
  // eslint-disable-next-line vue/no-unused-components
  components: {ArticleUnitPicker, StockPlacePicker, ArticlePicker},
  props: ['stock'],
  data() {
    return {
      inProgress: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      data: {},
      amount_input: 1,
      unit_input: null,
      article: null,
      isLoadingCharges: false,
      charges: null
    }
  },
  methods: {
    save: function (){
      this.inProgress = true
      let payload = {
        stock_place_id: this.stock.stock_place_id,
        stock_value_id: this.stock.stock_value_id,
        article_charge_id: this.stock.article_charge_id,
        amount: this.amount,
        comment: this.comment
      }
      let t = this;
      apiservice().post('stock/stocktaking/correction', payload)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {
            t.fetchData()
            t.$emit('close')

          })
          .catch(function (error) {
            t.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.data,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.inProgress = false
            console.log(error);
          });
    },
    fetchData: function() {
      apiservice()
          .get('articles/' + this.article_id)
          .then(response => {
            this.article = response.data.data
            this.article_id = this.article.id
            console.log("fetchData: " + this.article_id)
            this.refreshCharges()
          });
    },
    refreshCharges() {
      // this.data.isLoadingCharges = true
      console.log("refreshCharges")
      let self = this
      apiservice()
          .get('articles/' + this.article.id+ "/charges")
          .then(response => {
            self.charges = response.data.data
            if (self.charge_id) {
              for (let i = 0; i < self.charges.length; i++) {
                if (self.charges[i].id == self.charge_id) {
                  self.article_charge = self.charges[i]
                  break
                }
                self.unit_input = self.article.default_unit
              }
            }
          });
    }
  },
  computed: {
    amount: function() {
      if (!this.unit_input)
        return this.amount_input
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100)
    },
    article_charge_id: function () {
      if (this.article_charge)
        return this.article_charge.id
      else
        return null
    }
  },
  beforeMount(){
    if (this.article_id != null) {this.fetchData()}
    this.amount_input = this.stock.amount
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
