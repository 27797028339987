<template>
  <div class="login">
    <section class="hero is-primary is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-3-widescreen">
              <form action="" class="box">
                <div class="field">
                  <label for="" class="label">Benutzername</label>
                  <div class="control has-icons-left">
                    <input type="text" placeholder="Benutzername" class="input" v-model="username" v-bind:disabled="inProgress" required>
                    <span class="icon is-small is-left">
                  <i class="fa fa-user"></i>
                </span>
                  </div>
                </div>
                <div class="field">
                  <label for="" class="label">Passwort</label>
                  <div class="control has-icons-left">
                    <input type="password" placeholder="*******" class="input" v-model="password" v-bind:disabled="inProgress" required>
                    <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
                  </div>
                </div>
<!--                <div class="field">-->
<!--                  <label for="" class="checkbox">-->
<!--                    <input type="checkbox">-->
<!--                    Eingeloggt bleiben-->
<!--                  </label>-->
<!--                </div>-->
                <div class="field">
                  <button class="button is-success" @click="login" v-bind:disabled="inProgress" v-bind:class="{ 'is-loading': inProgress }">
                    Login
                  </button>
                  {{ dbg }}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

//const API_URL = 'api/auth/';


import apiservice from "@/services/apiservice";
import { DialogProgrammatic as Dialog } from 'buefy'

export default {
  name: 'Login',
  props: {
    msg: String
  },
  data() {
    return {
      inProgress: false,
      username: "",
      password: ""
    }
  },
  methods: {
    login: function(){
      this.inProgress = true
      return apiservice()
          //.post(API_URL + 'signin', {
          .post('/auth/login', {
            username: this.username,
            password: this.password
          })
          .then(response => {
            if (response.data.status == "success") {
              localStorage.setItem('user', JSON.stringify(response.data.data));
            }
            this.$router.push("/");
            return response.data;
          })
          .catch(error => {
            Dialog.alert({
              title: 'Fehlgeschlagen',
              message: 'Der Login war nicht erfolgreich. Sind die Logindaten korrekt?',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            });
            console.log(error)
            this.inProgress = false

          });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
