<template>
  <div class="supplier">
    <section>
      <b-field group-multiline grouped>
<!--        <div class="control">-->
<!--          <router-link class="" to="/paymentconditions/new">-->
<!--            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neue Zahlungskondition</b-button> &nbsp;-->
<!--          </router-link>-->
<!--        </div>-->

<!--        <div class="control">-->

<!--          <b-button type="is-success" @click="openNewEntry(+1)"><i class="fa fa-plus"></i>&nbsp;Eingang buchen</b-button>-->

<!--        </div>-->

<!--        <div class="control">-->

<!--          <b-button type="is-danger" @click="openNewEntry(-1)"><i class="fa fa-minus"></i>&nbsp;Ausgang buchen</b-button>-->

<!--        </div>-->

        <div class="control">

          <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>
        <!--<b-select v-model="defaultSortDirection">
            <option value="asc">Default sort direction: ASC</option>
            <option value="desc">Default sort direction: DESC</option>
        </b-select>-->
        <b-select v-model="perPage" :disabled="!isPaginated">
          <option value="10">10 je Seite</option>
          <option value="20">20 je Seite</option>
          <option value="50">50 je Seite</option>
        </b-select>

      </b-field>

      <b-table
          :current-page.sync="currentPage"
          :data="data"
          :default-sort-direction="defaultSortDirection"
          :paginated="isPaginated"
          :pagination-position="paginationPosition"
          :pagination-simple="isPaginationSimple"
          :per-page="perPage"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="name">

        <template>
<!--          <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">-->
<!--            {{ props.row.id }}-->
<!--          </b-table-column>-->

          <b-table-column field="date" label="Datum" v-slot="props">
            {{ new Date(props.row.date).toLocaleString() }}
          </b-table-column>

          <b-table-column field="article.id" label="Art.-Nr." v-slot="props" width="20" searchable>
            {{ props.row.article.id }}
          </b-table-column>

          <b-table-column field="article.name" label="Artikel" v-slot="props" searchable>
            {{ props.row.article.name }}
          </b-table-column>

          <b-table-column field="article.manufacturer" label="Hersteller" v-slot="props" width="40" searchable>
            {{ props.row.article.manufacturer }}
          </b-table-column>

          <b-table-column field="article_charge.name" label="Charge" v-slot="props" width="20" searchable>
            {{ props.row.article_charge.name }}
          </b-table-column>

          <b-table-column field="amount" label="Anzahl" width="20" v-slot="props" numeric searchable >
            <b-tag type="is-success" size="is-medium" v-if="props.row.amount >= 0">{{ props.row.amount }} {{ props.row.article.default_unit ? props.row.article.default_unit.symbol : "" }}</b-tag>
            <b-tag type="is-danger" size="is-medium" v-if="props.row.amount < 0">{{ props.row.amount }} {{ props.row.article.default_unit ? props.row.article.default_unit.symbol : "" }}</b-tag>
          </b-table-column>

          <b-table-column field="stock_place.id" label="Lagerplatz" searchable :custom-search="searchStockPlace" width="120" v-slot="props">
            {{ props.row.stock_place.warehouse }} | {{ props.row.stock_place.rack }} | {{ props.row.stock_place.shelf }}
          </b-table-column>

          <b-table-column field="comment" label="Kommentar" width="150" v-slot="props" searchable>
            {{ props.row.comment }}
          </b-table-column>

          <b-table-column label="Aktionen"  v-slot="props">

          <b-button type="is-primary" @click="openArticle(props.row.article.id)"><i class="fa fa-eye"></i>&nbsp;Artikel</b-button>

          </b-table-column>
        </template>
      </b-table>
      <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
    </section>
  </div>
</template>

<script>

import apiservice from "@/services/apiservice";
import NewStockEntry from "@/components/NewStockEntry";



export default {
  name: 'StockRecentEntriesOld',
  props: {
    msg: String
  },
  components: {

  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      isLoading: false
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let self = this
      apiservice()
          .get('stockold/stock_entries')
          .then(response => {
            this.data = response.data.data
            self.isLoading = false
          });
    },
    openNewEntry: function(mode) {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: NewStockEntry,
        props: {'charge_id': null, 'charge_name': null, 'article_id': null, 'stock_entry_mode': mode},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
      modal.$on('close', () => this.fetchData())
    },
    // eslint-disable-next-line no-unused-vars
    openArticle: function(id){
      this.$router.push({ path: `/articles/${id}` })
    },
    searchStockPlace: function(d, input) {
      let s = d.stock_place.warehouse + "|" + d.stock_place.rack + "|" + d.stock_place.shelf;
      return s.toLowerCase().includes(input.toLowerCase())
    }
  },
  beforeMount(){
    this.fetchData()
  },
  dateFormatter: function(date) {
    let d = new Date(date)
    // return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
    return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`
    // return d.toISOString()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
