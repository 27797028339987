<template>
  <div class="supplier">
    <section>
      <b-field group-multiline grouped>
        <div class="control">
          <router-link class="" to="/labels/new">
            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neues Label</b-button> &nbsp;
          </router-link>
        </div>

        <div class="control">

          <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>

        <b-select v-model="perPage" :disabled="!isPaginated">
          <option value="10">10 je Seite</option>
          <option value="20">20 je Seite</option>
          <option value="50">50 je Seite</option>
        </b-select>

        <div class="control">

          <b-field grouped>

            <b-button :type="printMode ? 'is-success' : 'is-primary'" :loading="printerLoading" @click="initPrinting"><i class="fa fa-print"></i>&nbsp;Druckmodus</b-button>

            <b-select placeholder="Drucker auswählen" v-if="labelService && labelService.printers" v-model="selectedPrinter">
              <option
                  v-for="option in labelService.printers"
                  :value="option.name"
                  :key="option.name"
                  selected="true">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>

        </div>

        <!--<b-select v-model="defaultSortDirection">
            <option value="asc">Default sort direction: ASC</option>
            <option value="desc">Default sort direction: DESC</option>
        </b-select>-->



      </b-field>


      <b-field group-multiline grouped type="is-primary" position="is-right">
        <div class="control">

          <b-field>

              <b-icon icon="filter" size="is-medium"></b-icon>
            <SeriesPicker v-model="seriesFilterValue" @input="filterSelected"></SeriesPicker>
            <b-switch v-model="series_filtered"
                      :true-value=true
                      :false-value=false></b-switch>
          </b-field>



        </div>

      </b-field>


      <b-table
          :current-page.sync="currentPage"
          :data="data"
          :default-sort-direction="defaultSortDirection"
          :paginated="isPaginated"
          :pagination-position="paginationPosition"
          :pagination-simple="isPaginationSimple"
          :per-page="perPage"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="name">

        <template>
          <b-table-column field="id" label="ID" searchable sortable width="40" v-slot="props">
            <b-tooltip :label="props.row.id">...{{ props.row.id.slice(-4) }}</b-tooltip>
          </b-table-column>

          <b-table-column field="title" label="Titel" searchable sortable v-slot="props">
            {{ props.row.title }}
          </b-table-column>

          <b-table-column field="location1" label="Loc.1" searchable sortable width="110" v-slot="props">
            {{ props.row.location1 }}
          </b-table-column>

          <b-table-column field="location2" label="Loc.2" searchable sortable width="110" v-slot="props">
            {{ props.row.location2 }}
          </b-table-column>

          <b-table-column field="location3" label="Loc.3" searchable sortable width="110" v-slot="props">
            {{ props.row.location3 }}
          </b-table-column>

          <b-table-column label="Aktionen" v-slot="props">

            <b-button type="is-primary" @click="openItem(props.row.id)"><i class="fa fa-eye"></i>&nbsp;Bearbeiten</b-button>
            <b-button v-if="printMode" type="is-primary is-light" @click="printLabel(props.row)"><i class="fa fa-print"></i></b-button>

          </b-table-column>
        </template>
        <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
      </b-table>
    </section>
<!--    printers {{labelService ? labelService.printers:"nix"}}<br/><br/>-->
<!--    response: {{printLabelResponse}}<br/><br/>-->
<!--    debug: {{debugOut}}-->

  </div>
</template>

<script>

import apiservice from "@/services/apiservice";
// import initFramework, { LabelSetBuilder, settings } from 'dymo-js-sdk';
import 'dymo-js-sdk'
import SeriesPicker from "@/components/SeriesPicker";
// import labelXml from "@/assets/label.txt"
import LabelService from "@/services/labelservice";


export default {
  name: 'LabelOverview',
  props: {
    msg: String
  },
  components: {
    SeriesPicker
  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      series_filtered: false,
      isLoading: false,

      seriesFilterValue: ['', '', ''],

      // labelFramework: null,
      // labelSet: '',
      // printers: null,
      // printLabelResponse: '',
      // debugOut: "d",

      labelService: null,
      selectedPrinter: null,
      printerLoading: false,
      printMode: false,

    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let url = "labels"
      if (this.series_filtered) {
        url += "/filterseries?manufacturer=" + this.seriesFilterValue[0] + "&series=" + this.seriesFilterValue[1] + "&color=" + this.seriesFilterValue[2]
      }
      apiservice()
          .get(url)
          // .then(response => (this.data = response.data.data));
          .then(response => {
              this.data = response.data.data;
              this.isLoading = false;
            });
    },
    filterSelected: function (){
      if (this.series_filtered){
        this.fetchData();
      }
    },
    // eslint-disable-next-line no-unused-vars
    openItem: function(id){
      // this.$router.push({ path: `/labels/${id}` })
      let route = this.$router.resolve({ path: `/labels/${id}` });
      window.open(route.href, '_blank');
    },
    // eslint-disable-next-line no-unused-vars
    print: function(){

    },
    // async loadPrinters () {
    //   this.printers = await this.labelFramework.getPrinters()
    // },
    // async printLabel () {
    //
    //
    //   // eslint-disable-next-line no-unused-vars
    //   const printerToUse = 'DYMO LabelWriter 450'
    //   console.log(printerToUse)
    //   // eslint-disable-next-line no-unused-vars
    //   const labelXmlFromFile = "".replace(/\{\{\{\{\{\{\{\{\{\{QRCONTENT\}\}\}\}\}\}\}\}\}\}/g, 'https://www.fliesen-benker.de/')
    //   const labelSet = new LabelSetBuilder()
    //   const record = labelSet.addRecord()
    //   record.setText('ITextObject1', 'Koje mit Fischgrätverlegung')
    //   // record.setObjectText('IBarcodeObject0', )
    //   console.log(labelSet)
    //   // this.printLabelResponse = labelSet + "aa"
    //   this.debugOut = labelSet
    //   this.printLabelResponse = this.labelFramework.printLabel(printerToUse, '', labelXmlFromFile, labelSet + '')
    // },
    // async initTheThing(){
    //   this.debugOut = "start"
    //   this.initializing = true;
    //   this.labelFramework = await initFramework(); //await createFramework(undefined, true)
    //   await this.loadPrinters();
    //   this.initializing = false;
    //   console.log({settings});
    // },
    async initPrinting(){
      this.printerLoading = true;
      this.labelService = new LabelService()
      await this.labelService.init()
      await this.labelService.loadPrinters()
      if (this.labelService.printers && this.labelService.printers.length > 0){
        this.selectedPrinter = this.labelService.printers[0].name
      }
      this.debugOut = this.labelService.printers;
      this.printMode = true;
      this.printerLoading = false;
    },
    printLabel: function(label) {
      this.labelService.printLabel(label, this.selectedPrinter)
    }
  },
  watch: {

    series_filtered: function() {
      console.log("filter change triggered")
      this.fetchData()
    }
  },
  beforeMount(){
    this.fetchData()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
