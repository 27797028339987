<template>
  <b-select placeholder="USt." v-model="value" v-bind:class="{ 'is-loading': items == [] }" @input="updateValue">
    <option
        v-for="option in items"
        :value="option"
        :key="option.id">
      {{ option.rate }} %
    </option>
  </b-select>
</template>

<script>
//Todo default wert wird nicht gesetzt
import apiservice from "@/services/apiservice";

export default {
  name: 'VatTypePicker',
  data() {
    return {
      items: [],
      test: 'a'
    }
  },
  props: ['value'],
  methods: {
    fetchData: function() {
      apiservice()
          .get("basic/vat_types")
          .then(response => {
            this.items = response.data.data;
            if (!this.value) {
              for (let i = 0; i<this.items.length; i++) {
                if (this.items[i].id == 1) {
                    this.value = this.items[i];
                }
              }
              this.updateValue();
            }
          });
    },
    updateValue() {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  },
  computed: {
  },
  beforeMount(){
    this.fetchData()
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>