<template>
  <div class="article">

    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Dienstleistung</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">


        <div class="tile is-ancestor">
          <div class="tile is-vertical is-16">
            <div class="tile">
              <div class="tile is-parent is-vertical is-4">
                <article class="tile is-child notification box">
<!--                  <b-field label="ID" :hidden="isNew">-->
<!--                    <b-input v-model="data.id" disabled="disabled"></b-input>-->
<!--                  </b-field>-->

                  <b-field label="Basis-Einheit">
                    {{ data.default_unit? data.default_unit.symbol : ''}}
                  </b-field>

                  <b-field label="Standard-USt.">
                    {{ data.default_vat ? data.default_vat.rate : '-' }}%
                  </b-field>

                  <b-field label="Preis / VK (€)">
                    {{ data.sales_price }}€
                  </b-field>

                  <b-field label="Kalkulatorische Kosten / EK (€)">
                    {{ data.cost_price }}€
                  </b-field>


                </article>

              </div>

              <div class="tile is-parent is-16">
                <article class="tile is-child notification box">


                  <b-field label="Bezeichnung">
                    {{ data.name }}
                  </b-field>

                  <b-field label="Beschreibung">
                    {{ data.description }}
                  </b-field>

                  <b-field label="Interne Notiz">
                    {{ data.internal_note }}
                  </b-field>

                </article>
              </div>
            </div>
          </div>

        </div>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>

  </div>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'ServiceSummary',
  props: ['service_id'],
  data() {
            return {
              data: {sales_price: 1, cost_price:0},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name + " (" + this.data.service_id + ")"
      }
    },
    price_delta() {
      return (this.data.sales_price - this.data.cost_price).toPrecision(2)
    }
  },
  components: {
    // ArticleCategoryPicker, SupplierPicker, UnitPicker, , VatTypePicker

  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('services/' + this.service_id)
          .then(response => (this.data = response.data.data));
    }
  },
  beforeMount() {
      this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
