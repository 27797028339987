<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Artikel auswählen</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-field grouped>
          <div class="control" v-if="!filter_supplier">

            <b-field>
              <b-checkbox-button v-model="includeArchived"
                                 :native-value=true
                                 type="is-success"
                                 @input="fetchData">
                <b-icon v-if="!includeArchived" icon="plus"></b-icon>
                <b-icon v-if="includeArchived" icon="check"></b-icon>
                <span>Archiv</span>
              </b-checkbox-button>
            </b-field>

          </div>
        </b-field>
        <b-field grouped>

          <div class="control">
            <b-field>
              <b-checkbox-button v-model="columnsVisible"
                                 native-value="id"
                                 type="is-primary is-light">
                <span>Artikelnr.</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="name"
                                 type="is-primary is-light">
                <span>Bezeichnung</span>
              </b-checkbox-button>
              <b-checkbox-button v-model="columnsVisible"
                                 native-value="manufacturer"
                                 type="is-primary is-light">
                <span>Hersteller</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="series"
                                 type="is-primary is-light">
                <span>Serie</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="color"
                                 type="is-primary is-light">
                <span>Farbe</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="format_length"
                                 type="is-primary is-light">
                <span>Länge</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="format_width"
                                 type="is-primary is-light">
                <span>Breite</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="format_depth"
                                 type="is-primary is-light">
                <span>Tiefe</span>
              </b-checkbox-button>

              <b-checkbox-button v-model="columnsVisible"
                                 native-value="anti_slip_property"
                                 type="is-primary is-light">
                <span>Rutschhemmung</span>
              </b-checkbox-button>


            </b-field>
          </div>
        </b-field>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="true"
            :pagination-position="bottom"
            :pagination-simple="true"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
            :selected.sync="value"
            focusable
        >

          <template>
            <b-table-column field="id" :visible="columnsVisible.includes('id')" label="Artikelnr." searchable :custom-search="idsearch" sortable width="70" v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <!--          <b-table-column field="article_nr" label="Artikelnummer" searchable sortable width="45" v-slot="props">-->
            <!--            {{ props.row.article_nr }}-->
            <!--          </b-table-column>-->

            <b-table-column field="name" :visible="columnsVisible.includes('name')" label="Bezeichnung"  searchable sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <!--          <b-table-column field="name" label="Beschreibung" searchable sortable width="200">-->
            <!--            {{ (props.row.description).substring(0, 20) }}...-->
            <!--          </b-table-column>-->

            <!--          <b-table-column field="marketing_name" label="Kundenbezeichnung" searchable sortable width="500" v-slot="props">-->
            <!--            {{ (props.row.marketing_name) }}-->
            <!--          </b-table-column>-->

            <b-table-column field="manufacturer" :visible="columnsVisible.includes('manufacturer')" label="Hersteller" searchable sortable width="150" v-slot="props">
              {{ props.row.manufacturer }}
            </b-table-column>

            <b-table-column field="series" :visible="columnsVisible.includes('series')" label="Serie" searchable sortable width="150" v-slot="props">
              {{ (props.row.series) }}
            </b-table-column>

            <b-table-column field="color" :visible="columnsVisible.includes('color')" label="Farbe" searchable sortable width="150" v-slot="props">
              {{ (props.row.color) }}
            </b-table-column>

            <b-table-column field="format_length" :visible="columnsVisible.includes('format_length')" label="Länge" searchable sortable numeric width="80" v-slot="props">
              <span v-if="props.row.format_length">{{ parseFloat(props.row.format_length) || "" }} {{ props.row.format_unit_symbol }}</span>
            </b-table-column>

            <b-table-column field="format_width" :visible="columnsVisible.includes('format_width')" label="Breite" searchable sortable numeric width="80" v-slot="props">
              <span v-if="props.row.format_width">{{ parseFloat(props.row.format_width) || "" }} {{ props.row.format_unit_symbol }}</span>
            </b-table-column>

            <b-table-column field="format_depth" :visible="columnsVisible.includes('format_depth')" label="Tiefe" searchable sortable numeric width="80" v-slot="props">
              <span v-if="props.row.format_depth">{{ parseFloat(props.row.format_depth) || "" }} mm</span>
            </b-table-column>

            <b-table-column field="anti_slip_property" :visible="columnsVisible.includes('anti_slip_property')" label="R" searchable sortable width="80" v-slot="props">
              {{props.row.anti_slip_property}}
            </b-table-column>

            <b-table-column field="action" label="Aktionen" v-slot="props">
              <b-button type="is-primary is-light" @click="openArticleSummary(props.row.id)"><i class="fa fa-eye"></i></b-button>
            </b-table-column>

          </template>
        </b-table>
        <b-loading :active="isFetching" :is-full-page="false" :can-cancel="false" />
      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="OK"
            type="is-primary"
            :disabled="value==null"
            @click="confirm" />
        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";
import ArticleSummary from "@/components/ArticleSummary";

export default {
  name: 'StockPlacePicker',
  data() {
    return {
      data: [],
      isFetching: true,
      includeArchived: false,
      columnsVisible: ['id', 'name', 'manufacturer', 'series', 'format_length', 'format_width', 'color']
    }
  },
  props: {
    value: Object,
    filter_supplier: Number,
  },
  methods: {
    fetchData: function() {
      this.isFetching = true;
      let url = "articles"
      if (this.filter_supplier) {
        url += "/bysupplier/" + this.filter_supplier
      } else {
        if (this.includeArchived) {
          url += "/includearchived"
        }
      }
      apiservice()
          .get(url)
          .then(response => {
            (this.data = response.data.data)
            this.isFetching = false;
          });

    },
    openArticleSummary: function(article_id) {
      this.$buefy.modal.open({
        component: ArticleSummary,
        parent: this,
        props: {
          'article_id': article_id
        },
        onCancel: function () {
          console.log()
        },
        events: {
        }
      })
    },
    confirm() {
      apiservice()
          .get("articles/" + this.value.id)
          .then(response => {
            this.$emit('confirm', response.data.data)
            this.$emit('close')
          })
      }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>