<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Menge</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">

<!--        <b-field label="Artikel:">-->
<!--          <ArticlePicker v-model="article" @change="refreshCharges" disabled required />-->
<!--        </b-field>-->


<!--        <b-field>-->
<!--          CHARGE-->
<!--        </b-field>-->

        <!--        <b-field label="Charge:">-->
        <!--          <b-input-->
        <!--              type="text"-->
        <!--              :value="charge_name"-->
        <!--              disabled>-->
        <!--          </b-input>-->
        <!--        </b-field>-->

<!--        <b-field label="Von Lagerplatz:">-->
<!--&lt;!&ndash;          <StockPlacePicker v-model="data.stock_place" :article_charge="article_charge" :hide_nonstocked="stock_entry_mode == -1" required />&ndash;&gt;-->
<!--          STOCK PLACE-->
<!--        </b-field>-->


        <b-field grouped label="Menge:">
          <b-field>
            <b-numberinput
                type="number"
                v-model="amount_input"
                placeholder="1"
                min="0"
                step="1"
                min-step="0.01"
                controls-position="compact"
                controls-rounded
                required>
            </b-numberinput>
          </b-field>
          <b-field>
            <ArticleUnitPicker v-model="unit_input" :article_id="picking_order.article.id" />
          </b-field>
        </b-field>

        <b-field label="Buchung:" v-if="amount_input && unit_input && unit_input.unit.id != picking_order.article.default_unit.id">
          <span class="is-size-4">{{ amount }} {{picking_order.article.default_unit.symbol}}</span>
        </b-field>

        <!--        <b-field label="Buchung:" v-if="amount && unit_input && article">-->
        <!--          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>-->
        <!--        </b-field>-->

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="Buchen"
            type="is-primary"
            @click="save"
            :disabled="!amount_input || !amount"
        />
      </footer>
    </div>
  </form>
</template>

<script>

import StockPlacePicker from "@/components/StockPlacePicker";
import apiservice from "@/services/apiservice";
import ArticleUnitPicker from "@/components/ArticleUnitPicker";
import ArticlePicker from "@/components/ArticlePicker";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'ShippingAmountPrompt',
  // eslint-disable-next-line vue/no-unused-components
  components: {ArticleUnitPicker, StockPlacePicker, ArticlePicker},
  props: ['article_id', 'article_charge_id', 'picking_order_id', 'source_stock_place_id', 'amount_needed'],
  data() {
    return {
      inProgress: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      data: {},
      amount_input: 1,
      unit_input: null,
      picking_order: null,
      isLoadingCharges: false,
      charges: null
    }
  },
  methods: {
    save: function (){
      this.data.article_charge_id = this.article_charge_id
      this.data.picking_order_id = this.picking_order_id
      this.data.source_stock_place_id = this.source_stock_place_id
      this.data.amount = this.amount
      console.log(this.data)
      let t = this;
      apiservice().post('stock/picking/', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {

            t.$emit('close')

          })
          .catch(function (error) {
            t.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.data,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    fetchData: function() {
      apiservice()
          .get('stock/picking/' + this.picking_order_id)
          .then(response => {
            this.picking_order = response.data.data
            // this.refreshCharges()
          });
    },
    refreshCharges() {

    }
  },
  computed: {
    amount: function() {
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100)
    },

  },
  beforeMount(){
    this.fetchData()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
