<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Alternativ-Einheiten</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
          <div class="control">
            <b-button class="button is-primary" @click="addArticleUnit()" expanded icon-pack="fa" icon-left="link">Neue Alternativ-Einheit</b-button>
          </div>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="true"
            :pagination-position="bottom"
            :pagination-simple="true"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
        >

          <template>
<!--            <b-table-column field="id" label="Dienstl.-Nr." searchable sortable width="40" v-slot="props">-->
<!--              {{ props.row.id }}-->
<!--            </b-table-column>-->

            <!--            <b-table-column field="article_nr" label="Artikelnummer" searchable sortable width="45" v-slot="props">-->
            <!--              {{ props.row.service_nr }}-->
            <!--            </b-table-column>-->

            <b-table-column field="unit.symbol" label="Einheit" searchable sortable v-slot="props">
              {{ props.row.unit.symbol }}
            </b-table-column>

            <!--          <b-table-column field="name" label="Beschreibung" searchable sortable width="200">-->
            <!--            {{ (props.row.description).substring(0, 20) }}...-->
            <!--          </b-table-column>-->

            <!--          <b-table-column field="marketing_name" label="Kundenbezeichnung" searchable sortable width="500" v-slot="props">-->
            <!--            {{ (props.row.marketing_name) }}-->
            <!--          </b-table-column>-->



            <b-table-column field="factor" label="A/B" searchable sortable v-slot="props">
              {{  (props.row.factor/1000000).toFixed(2) }} {{ default_unit.symbol }} je {{ props.row.unit.symbol }}
              <!--                  {{ data.factor/100 }} {{ data.unit ? data.unit.symbol : '' }} je {{ article.default_unit.symbol }}-->
            </b-table-column>

            <b-table-column field="factor" label="B/A" searchable sortable v-slot="props">
              {{ (1/(props.row.factor/1000000)).toFixed(2) }} {{ props.row.unit.symbol }} je {{ default_unit.symbol }}
            </b-table-column>

            <b-table-column label="Aktionen"  v-slot="props">
              <b-button type="is-danger is-light" @click="deleteArticleUnit(props.row.id)"><i class="fa fa-trash"></i></b-button>
            </b-table-column>
          </template>
        </b-table>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Schließen"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";
import ArticleUnitPrompt from "@/components/ArticleUnitPrompt";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";
// import ServiceSummary from "@/components/ServiceSummary";

export default {
  name: 'ArticleServices',
  data() {
    return {
      data: [],
      isFetching: true
    }
  },
  props: ['article', 'default_unit'],
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("articles/" + this.article.id + "/units")
          .then(response => (this.data = response.data.data));
      this.isFetching = false;
    },
    confirm() {
      this.$emit('confirm', this.value)
      this.$emit('close')
    },
    deleteArticleUnit: function(articleunit_id) {
      let self = this;

      this.$buefy.dialog.confirm({
        title: 'Einheit löschen?',
        message: 'Sind Sie sicher, dass Sie die Alternativeinheit löschen möchten?',
        confirmText: 'Ja, löschen',
        cancelText: 'Nein',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          apiservice().delete("articles/units/" + articleunit_id)
              .then(function (response) {
                self.$buefy.toast.open({
                  message: 'Alternativeinhet gelöscht!',
                  position: 'is-bottom',
                  type: 'is-danger'
                })
                console.log(response);
                self.fetchData();
              })
              .catch(function (error) {
                self.$buefy.dialog.alert({
                  title: 'Fehler',
                  message: 'Ein Fehler ist aufgetreten: ' + error.message,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                console.log(error);
              });
        }
      })
    },
    addArticleUnit: function() {
      this.$buefy.modal.open({
        component: ArticleUnitPrompt,
        parent: this,
        props: {
          // 'default_unit': this.default_unit,
          'article': this.article
        },

        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {

            // let post_data = {'id':value.id}
            let self = this;

            apiservice().post("articles/" + this.article.id + "/units", value)
                .then(function (response) {
                  self.$buefy.toast.open({
                    message: 'Einheit mit Artikel verknüpft',
                    position: 'is-bottom',
                    type: 'is-success'
                  })
                  console.log(response);
                  self.fetchData();
                })
                .catch(function (error) {
                  self.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                  console.log(error);
                });



            this.fetchData();
          }

        }
      })
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>