<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Mit Artikel verknüpfte Dienstleistungen</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
          <div class="control">
            <b-button class="button is-primary" @click="addService()" expanded icon-pack="fa" icon-left="link">Weitere Dienstleistung mit Artikel verknüpfen</b-button>
          </div>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="true"
            :pagination-position="bottom"
            :pagination-simple="true"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
        >

          <template>
            <b-table-column field="id" label="Dienstl.-Nr." searchable sortable width="40" v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <!--            <b-table-column field="article_nr" label="Artikelnummer" searchable sortable width="45" v-slot="props">-->
            <!--              {{ props.row.service_nr }}-->
            <!--            </b-table-column>-->

            <b-table-column field="name" label="Bezeichnung" searchable sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <!--          <b-table-column field="name" label="Beschreibung" searchable sortable width="200">-->
            <!--            {{ (props.row.description).substring(0, 20) }}...-->
            <!--          </b-table-column>-->

            <!--          <b-table-column field="marketing_name" label="Kundenbezeichnung" searchable sortable width="500" v-slot="props">-->
            <!--            {{ (props.row.marketing_name) }}-->
            <!--          </b-table-column>-->

            <b-table-column field="description" label="Beschreibung" searchable sortable v-slot="props">
              <div v-if="props.row.description.length > 44">
                <b-tooltip :label="props.row.description" multilined>
                  {{ (props.row.description).substring(0, 40) }}<span>...</span>
                </b-tooltip>
              </div>
              <div v-if="props.row.description.length <= 44">
                {{ props.row.description }}
              </div>
            </b-table-column>
          </template>
        </b-table>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Schließen"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";
import ServicePickerPromt from "@/components/ServicePickerPromt";
// import ServiceSummary from "@/components/ServiceSummary";

export default {
  name: 'ArticleServices',
  data() {
    return {
      data: [],
      isFetching: true
    }
  },
  props: ['article_id'],
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("articles/" + this.article_id + "/services")
          .then(response => (this.data = response.data.data));
      this.isFetching = false;
    },
    confirm() {
      this.$emit('confirm', this.value)
      this.$emit('close')
    },
    addService: function() {
      this.$buefy.modal.open({
        component: ServicePickerPromt,
        parent: this,

        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {

            // let post_data = {'id':value.id}
            let self = this;

            apiservice().post("articles/" + this.article_id + "/services", value)
                .then(function (response) {
                  self.$buefy.toast.open({
                    message: 'Dienstleistung mit Artikel verknüpft',
                    position: 'is-bottom',
                    type: 'is-success'
                  })
                  console.log(response);
                  self.fetchData();
                })
                .catch(function (error) {
                  self.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                  console.log(error);
                });



            this.fetchData();
          }

        }
      })
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>