<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Ausgang buchen</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">

<!--        <b-field position="is-centered">-->
<!--          <b-radio-button v-model="stock_entry_mode"-->
<!--                          native-value="in"-->
<!--                          type="is-success" expanded>-->
<!--            <b-icon icon="plus"></b-icon>-->
<!--            <span>Eingang</span>-->
<!--          </b-radio-button>-->

<!--          <b-radio-button v-model="stock_entry_mode"-->
<!--                          native-value="reloc"-->
<!--                          type="is-warning" expanded>-->
<!--            <b-icon icon="sync"></b-icon>-->
<!--            <span>Umbuchung</span>-->
<!--          </b-radio-button>-->

<!--          <b-radio-button v-model="stock_entry_mode"-->
<!--                          native-value="out"-->
<!--                          type="is-danger" expanded>-->
<!--            <b-icon icon="minus"></b-icon>-->
<!--            <span>Ausgang</span>-->
<!--          </b-radio-button>-->

<!--        </b-field>-->

        <b-field label="Typ:">
          <b-select placeholder="Typ" v-model="stock_booking_type" expanded>
            <option value="out">
              Warenausgang
            </option>

            <option value="consumption">
              Eigenverbrauch
            </option>

            <option value="break">
              Bruch
            </option>
          </b-select>
        </b-field>

        <b-field label="Artikel:">
          <ArticlePicker v-model="stock.article_charge.article" @change="refreshCharges" required disabled />
        </b-field>




<!--        <b-field>-->
<!--          <b-select placeholder="Charge" v-model="article_charge" :loading="isLoadingCharges" @change="refreshCharges" expanded>-->
<!--            <option-->
<!--                v-for="charge in charges"-->
<!--                :value="charge"-->
<!--                :key="charge.id">-->
<!--              {{ charge.name }}-->
<!--            </option>-->
<!--          </b-select>-->
<!--        </b-field>-->

<!--        <b-field label="Charge:">-->
<!--          <b-input-->
<!--              type="text"-->
<!--              :value="charge_name"-->
<!--              disabled>-->
<!--          </b-input>-->
<!--        </b-field>-->




        <b-field grouped>
          <b-field label="Charge:">
            <div>{{stock.article_charge.name}}</div>
          </b-field>

          <b-field label="Lagerbestands-Gruppe:">
            <b-tooltip :label="stock.stock_value.cost_price + ' €/' + stock.article_charge.article.default_unit_symbol">{{ stock.stock_value_id }}</b-tooltip>
          </b-field>

          <b-field label="Bestand:">
            <div>{{ stock.amount }} {{stock.article_charge.article.default_unit_symbol}}</div>
          </b-field>
        </b-field>

        <b-field label="Lagerplatz (Ausgang):">
          Halle {{ stock.stock_place.warehouse }} | Regal {{ stock.stock_place.rack }} | Fach {{ stock.stock_place.shelf }}
        </b-field>

<!--        <b-field label="Lagerplatz (Eingang):">-->
<!--          <StockPlacePicker v-model="data.stock_place_in" :article_charge="article_charge" :hide_nonstocked="stock_entry_mode == -1" required />-->
<!--        </b-field>-->

        <b-field label="Kommentar:">
          <b-input
              type="text"
              v-model="data.comment"
              placeholder="Kommentar zur Buchung"
              required>
          </b-input>
        </b-field>

<!--        <b-field label="Wert (€) je Basiseinheit">-->
<!--          <b-numberinput controls-position="compact" controls-rounded v-model="data.stock_value" step="0.01"></b-numberinput>-->
<!--        </b-field>-->

        <b-field grouped label="Menge:">
          <b-field>
            <b-numberinput

                v-model="amount_input"
                placeholder="Menge"
                required>

            </b-numberinput>
          </b-field>
          <b-field>
            <ArticleUnitPicker v-model="unit_input" :article_id="stock.article_charge.article ? stock.article_charge.article_id : null" />
<!--            {{stock.article_charge}}ab-->
          </b-field>
        </b-field>


        <b-field label="Buchung:">
<!--          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>-->
          <span class="is-size-4">{{ amount }} {{stock.article_charge.article.default_unit_symbol}}</span>
        </b-field>

        <b-field label="Vebleibend:">
          <b-progress v-if="stock.amount - amount > 0"
              type="is-primary"
              :max="stock.amount"
              :value="stock.amount - amount"
              :show-value="true"
          >
<!--            {{ stock.amount - amount }} {{stock.article_charge.article.default_unit_symbol}}-->
          </b-progress>
          <b-progress v-if="stock.amount - amount < 0"
              :value="100"
              type="is-danger"
              show-value
          >
            Überschreitet Bestand um {{(stock.amount - amount) * -1}} {{stock.article_charge.article.default_unit_symbol}}
          </b-progress>

          <b-progress v-if="stock.amount - amount == 0"
                      :value="100"
                      type=""
                      show-value
          >
            Kein verbleibender Bestand
          </b-progress>
        </b-field>
<!--        <b-field label="Buchung:" v-if="amount && unit_input && article">-->
<!--          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>-->
<!--        </b-field>-->

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Abbrechen"
            @click="$emit('close')" />
        <b-button
            label="Buchen"
            type="is-primary"
            @click="save"
            :disabled="!stock.stock_place || !data.comment || !amount_input || amount_input<=0 || inProgress"
        />

      </footer>
    </div>
  </form>
</template>

<script>

import StockPlacePicker from "@/components/StockPlacePicker";
import apiservice from "@/services/apiservice";
import ArticleUnitPicker from "@/components/ArticleUnitPicker";
import ArticlePicker from "@/components/ArticlePicker";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'StockBookOut',
  // eslint-disable-next-line vue/no-unused-components
  components: {ArticleUnitPicker, StockPlacePicker, ArticlePicker},
  props: ['stock'],
  data() {
    return {
      inProgress: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      data: {},
      amount_input: 1,
      unit_input: null,
      article: null,
      isLoadingCharges: false,
      charges: null,

      stock_booking_type: 'out',
    }
  },
  methods: {
    save: function (){
      this.inProgress = true

      this.data.stock_place_id = this.stock.stock_place_id
      this.data.stock_value_id = this.stock.stock_value_id
      this.data.article_charge_id = this.stock.article_charge_id
      this.data.amount = this.amount
      this.data.comment
      this.data.type = this.stock_booking_type
      // this.data.article_charge = this.article_charge.id

      let t = this;
      apiservice().post('stock/booking/out', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {

            t.$emit('close')
            t.$buefy.toast.open({
              message: 'Ausgang gebucht',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
          })
          .catch(function (error) {
            t.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.data,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.inProgress = false
            console.log(error);
          });
    },
    fetchData: function() {
      apiservice()
          .get('articles/' + this.article_id)
          .then(response => {
            this.article = response.data.data
            this.article_id = this.article.id
            console.log("fetchData: " + this.article_id)
            this.refreshCharges()
          });
    },
    refreshCharges() {
      // this.data.isLoadingCharges = true
      console.log("refreshCharges")
      let self = this
      apiservice()
          .get('articles/' + this.article.id+ "/charges")
          .then(response => {
            self.charges = response.data.data
            if (self.charge_id) {
              for (let i = 0; i < self.charges.length; i++) {
                if (self.charges[i].id == self.charge_id) {
                  self.article_charge = self.charges[i]
                  break
                }
                self.unit_input = self.article.default_unit
              }
            }
          });
    }
  },
  computed: {
    amount: function() {
      if (!this.unit_input)
        return this.amount_input
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100)
    },
    article_charge_id: function () {
      if (this.article_charge)
        return this.article_charge.id
      else
        return null
    }
  },
  beforeMount(){
    if (this.article_id != null) {this.fetchData()}
    this.amount_input = null
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
