<template>
  <div>
    abc
    <div id="qr-code-full-region"></div>
    def
  </div>
</template>

<script>
import {Html5QrcodeScanner} from "html5-qrcode"

export default {
  name: 'QRScanner',
  data() {
    return {

    }
  },
  props: {
    qrbox: {
      type: Number,
      default: 250
    },
    fps: {
      type: Number,
      default: 10
    }
  },
  methods: {
    onScanSuccess (decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);
    },

    onScanFailure: function (error) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    }
  },
  computed: {
  },
  watch: {

  }
  ,
  mounted(){
    const config = {
      fps: this.fps,
      qrbox: this.qrbox,
    };
    const html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config);
    html5QrcodeScanner.render(this.onScanSuccess);
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>