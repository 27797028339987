<template>
  <div class="customer">

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent">
            <article class="tile is-child notification is-primary">
              <p class="title">Kunde</p>
              <p class="subtitle">{{ title }}</p>
            </article>
          </div>
        </div>
      </div>
    </div>

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification box">
              <b-field label="ID" :hidden="isNew">
                <b-input v-model="data.id" disabled="disabled"></b-input>
              </b-field>

              <b-field label="Anrede">
<!--                <b-input v-model="data.salutation"></b-input>-->
                <SalutationPicker v-model="data.salutation" />
              </b-field>

              <b-field label="Name">
                <b-input v-model="data.name"></b-input>
              </b-field>

              <b-field label="Anschrift">
                <b-input v-model="data.street"></b-input>
              </b-field>

              <div class="columns">
                <div class="column is-one-quarter">
                  <b-field label="PLZ">
                    <b-input v-model="data.zip"></b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="Ort">
                    <b-input v-model="data.place"></b-input>
                  </b-field>
                </div>
              </div>

              <b-field label="Land">
                <b-input v-model="data.country"></b-input>
              </b-field>

              <b-field label="Telefon">
                <b-input v-model="data.phone"></b-input>
              </b-field>

              <b-field label="Mobil">
                <b-input v-model="data.mobile"></b-input>
              </b-field>

              <b-field label="E-Mail">
                <b-input v-model="data.email"></b-input>
              </b-field>

              <b-field label="USt-IdNr.">
                <b-input v-model="data.vat_id"></b-input>
              </b-field>
            </article>

          </div>

          <div class="tile is-parent is-4">
            <article class="tile is-child notification box">
<!--              <div class="title">Standards</div>-->
              <b-field>
                <b-button icon-left="user" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="openContactPersons" :disabled="isNew">
                  Ansprechpartner
                </b-button>
              </b-field>
<!--              <b-field label="Zahlungsziel">-->
<!--                <b-input type="textarea" v-model="data.payment_due"></b-input>-->
<!--              </b-field>-->

<!--              <b-field>-->
<!--                <b-checkbox v-model="data.reverse_charge" :indeterminate="false">-->
<!--                  Umkehrung der Steuerschuldnerschaft-->
<!--                </b-checkbox>-->
<!--              </b-field>-->

            </article>
          </div>
        </div>
      </div>

    </div>


    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile is-parent">
          <article class="tile is-child">
            <div class="field is-grouped">
              <div class="control">
                <router-link class="" to="/customers">
                  <button class="button is-secondary">Zurück</button>
                </router-link>
              </div>
              <div class="control" :hidden="isNew">
                <button class="button is-link" @click="update">Speichern</button>
              </div>
              <div class="control" :hidden="!isNew">
                <button class="button is-link" @click="create">Erstellen</button>
              </div>
            </div>
          </article>
        </div>


      </div>
    </div>
  </div>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import apiservice from "@/services/apiservice";
import ContactPersons from "@/components/ContactPersons";
import SalutationPicker from "@/components/SalutationPicker";


export default {
  name: 'Customer',
  props: {
  },
  data() {
            return {
              data: {},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name
      }
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SalutationPicker

  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('customers/' + this.$route.params.id)
          .then(response => (this.data = response.data.data))
          .catch(function (error) {
            Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    update: function() {
      let self = this
      apiservice()
          .post('customers/', this.data)
          .then(function (response) {
              Toast.open({
                message: 'Lieferant erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              console.log(response);
            self.$router.push("/customers");
            })
            .catch(function (error) {
              Dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
            });
    },
    create: function() {
      this.data.id = "new"
      let self = this

      apiservice().post('customers/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Kunden erfolgreich hinzugefügt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/customers/"+response.data.data.id);
          })
          .catch(function (error) {
            Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
            console.log(error);
          });
    },
    openContactPersons: function() {
      this.$buefy.modal.open({
        component: ContactPersons,
        parent: this,
        props: {
          'relation_type': 'customer',
          'relation_id': this.data.id
        },
        onCancel: function () {
          console.log()
        },
        events: {
        }
      })
    }
  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
