<template>
  <div class="articlecategory">

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent">
            <article class="tile is-child notification is-primary">
              <p class="title">Artikelgruppe</p>
              <p class="subtitle">{{ title }}</p>
            </article>
          </div>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child">
            <b-field label="ID" :hidden="isNew">
              <b-input v-model="data.id" disabled="disabled"></b-input>
            </b-field>

            <b-field label="Name">
              <b-input v-model="data.name"></b-input>
            </b-field>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child">
            <b-field label="Beschreibung">
              <b-input type="textarea" v-model="data.description"></b-input>
            </b-field>
          </article>
        </div>



        <div class="tile is-parent">
          <article class="tile is-child">
            <div class="field is-grouped">
              <div class="control">
                <router-link class="" to="/articlecategories">
                  <button class="button is-secondary">Zurück</button>
                </router-link>
              </div>
              <div class="control" :hidden="isNew">
                <button class="button is-link" @click="update">Speichern</button>
              </div>
              <div class="control" :hidden="!isNew">
                <button class="button is-link" @click="create">Erstellen</button>
              </div>
            </div>
          </article>
        </div>


      </div>
    </div>



  </div>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import apiservice from "@/services/apiservice";


export default {
  name: 'ArticleCategory',
  props: {
  },
  data() {
            return {
              data: {},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name
      }
    }
  },
  components: {

  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('articles/categories/' + this.$route.params.id)
          .then(response => (this.data = response.data.data));
          // .then(function (response) {
          //   if (response.data.status == "success") {
          //     this.data = response.data.data;
          //   } else {
          //     this.$buefy.dialog.alert({
          //       title: 'Fehler',
          //       message: 'Ein Fehler ist aufgetreten.',
          //       type: 'is-danger',
          //       hasIcon: true,
          //       icon: 'times-circle',
          //       iconPack: 'fa',
          //       ariaRole: 'alertdialog',
          //       ariaModal: true
          //     })
          //   }
          // })
    },
    update: function() {
      let self = this
      apiservice()
          .post('articles/categories/', this.data)
          .then(function (response) {
              Toast.open({
                message: 'Artikelgruppe erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              console.log(response);
            self.$router.push("/articlecategories");
            })
            .catch(function (error) {
              Dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
            });
    },
    create: function() {
      this.data.id = "new"
      let self = this

      apiservice().post('articles/categories/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Artikelgruppe erfolgreich hinzugefügt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/articlecategories");
          })
          .catch(function (error) {
            Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
            console.log(error);
          });
    }
  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
