<template>
  <div class="stock">
<!--    <b-loading :is-full-page="false" active="true" :can-cancel="false"></b-loading>-->

    <p class="title" v-if="article"><b-button @click="openArticle(article.id)"><i class="fa fa-arrow-left"></i></b-button> Lager für Artikel {{article.id}}: {{ article.name }}</p>
<!--    <p class="subtitle">abc</p>-->
    <div class="stockoverview">
      <section>
        <b-field group-multiline grouped>
          <!--        <div class="control">-->
          <!--          <router-link class="" to="/paymentconditions/new">-->
          <!--            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neue Zahlungskondition</b-button> &nbsp;-->
          <!--          </router-link>-->
          <!--        </div>-->

          <div class="control">

            <b-button type="is-success" @click="openStockBookingIn()"><i class="fa fa-plus"></i>&nbsp;Eingang buchen</b-button>

          </div>

          <!--        <div class="control">-->

          <!--          <p-button label="Submit" />-->

          <!--        </div>-->

<!--          <div class="control">-->

<!--            <b-button type="is-warning" @click="openNewEntry(-1)"><i class="fa fa-sync"></i>&nbsp;Umbuchen</b-button>-->

<!--          </div>-->

<!--          <div class="control">-->

<!--            <b-button type="is-danger" @click="openNewEntry(-1)"><i class="fa fa-minus"></i>&nbsp;Ausgang buchen</b-button>-->

<!--          </div>-->

          <div class="control">

            <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

          </div>
          <!--<b-select v-model="defaultSortDirection">
              <option value="asc">Default sort direction: ASC</option>
              <option value="desc">Default sort direction: DESC</option>
          </b-select>-->
          <b-select v-model="perPage" :disabled="!isPaginated">
            <option value="10">10 je Seite</option>
            <option value="20">20 je Seite</option>
            <option value="50">50 je Seite</option>
          </b-select>

        </b-field>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="isPaginated"
            :pagination-position="paginationPosition"
            :pagination-simple="isPaginationSimple"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"

            :opened-detailed="defaultOpenedDetails"
            detailed
            :detail-key="(stock_place, article_charge, stock_value)"
            :detail-transition="transitionName"
            @details-open="(row) => $buefy.toast.open(`Expanded ${row.user.first_name}`)"
            :show-detail-icon="false"
        >

<!--          <template>-->
            <!--          <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">-->
            <!--            {{ props.row.id }}-->
            <!--          </b-table-column>-->

            <b-table-column field="stock_place.warehouse" label="Halle" v-slot="props" width="20" searchable>
              {{ props.row.stock_place.warehouse }}
<!--              {{// props.row.index}}-->
            </b-table-column>

            <b-table-column field="stock_place.rack" label="Regal" v-slot="props" width="20" searchable>
              {{ props.row.stock_place.rack }}
            </b-table-column>

            <b-table-column field="stock_place.shelf" label="Fach" v-slot="props" width="20" searchable :custom-search="searchShelf">
              {{ props.row.stock_place.shelf }}
            </b-table-column>

            <b-table-column field="article_charge.article.id" label="Art.-Nr." v-slot="props" width="100" v-if="!article" searchable>
              {{ props.row.article_charge.article_id }}
            </b-table-column>


            <b-table-column field="article_charge.name" label="Charge" v-slot="props" width="200" v-if="article" searchable>
              {{ props.row.article_charge.name }}
            </b-table-column>

            <b-table-column field="article_charge.article.name" label="Artikel (Charge)" v-slot="props" v-if="!article" searchable>
              <router-link :to="'/articles/' + props.row.article_charge.article_id" target='_blank'>
                {{ props.row.article_charge.article.name }}
              </router-link><br />
              <span v-if="props.row.article_charge.article.orderout_use_series">
                <b-tag type="is-primary">
                  {{props.row.article_charge.article.manufacturer}}
                  {{props.row.article_charge.article.series}}
                  {{props.row.article_charge.article.color}}
                  {{props.row.article_charge.article.format_width}} {{props.row.article_charge.article.format_width_unit_symbol}}
                  {{props.row.article_charge.article.format_length}} {{props.row.article_charge.article.format_length_unit_symbol}}
                  <span :hidden="!props.row.article_charge.article.format_depth">
                    {{props.row.article_charge.article.format_depth}} {{props.row.article_charge.article.format_depth_unit_symbol}}
                  </span>
                  {{props.row.article_charge.article.anti_slip_property}}
                  {{props.row.article_charge.article.rectified ? "rekt." : ""}}
                </b-tag>
              </span>
              <span v-if="props.row.article_charge.name && (props.row.article_charge.name.length)>0">
            <b-tag>{{ props.row.article_charge.name }}</b-tag></span>
            </b-table-column>

            <!--          <b-table-column field="article_charge.name" label="Charge" v-slot="props" width="20" searchable>-->

            <!--          </b-table-column>-->

          <b-table-column field="stock_value_id" label="LG" width="20" v-slot="props" numeric searchable >
            <b-tooltip :label="props.row.stock_value.cost_price + ' €/' + props.row.article_charge.article.default_unit_symbol">{{ props.row.stock_value_id }}</b-tooltip>
          </b-table-column>

            <b-table-column field="amount" label="Anzahl" width="100" v-slot="props" numeric searchable >
              <!--            <b-tag type="is-success" size="is-medium" v-if="props.row.amount >= 0">{{ props.row.amount }} {{ props.row.article_charge.article.default_unit ? props.row.article_charge.article.default_unit.symbol : "" }}</b-tag>-->
              <!--            <b-tag type="is-danger" size="is-medium" v-if="props.row.amount < 0">{{ props.row.amount }} {{ props.row.article.default_unit ? props.row.article.default_unit.symbol : "" }}</b-tag>-->
              {{ props.row.amount }} {{ props.row.article_charge.article.default_unit_symbol ? props.row.article_charge.article.default_unit_symbol : "" }}
            </b-table-column>



<!--            <b-table-column field="comment" label="Kommentar" width="300" v-slot="props" searchable>-->
<!--              {{ props.row.comment }}-->
<!--            </b-table-column>-->

                      <b-table-column label="Aktionen" width="230" v-slot="props">

<!--                        <b-button type="is-primary" @click="openArticle(props.row.article.id)"><i class="fa fa-eye"></i>&nbsp;Artikel</b-button>-->

<!--                        <b-tooltip label="Eingang">-->
<!--                          <b-button type="is-success is-light" @click="openStockBookingOutStock(props.row)"><i class="fa fa-plus"></i></b-button>-->
<!--                        </b-tooltip>-->

                        <b-tooltip label="Umbuchung (Lagerplatz)">
                          <b-button disabled="" type="is-warning is-light" @click="openStockBookingOutStock(props.row)"><i class="fa fa-sync"></i></b-button>
                        </b-tooltip>

                        <b-tooltip label="Ausgang">
                          <b-button type="is-danger is-light" @click="openStockBookingOutStock(props.row)"><i class="fa fa-minus"></i></b-button>
                        </b-tooltip>


                      </b-table-column>

            <template #detail="props">
              <div class="columns">
                <div class="column">
                  <b-button type="is-success is-light" @click="stocktakingCorrection(props)"><i class="fa fa-equals"></i>&nbsp;Korrektur</b-button>
                </div>
                <div class="column">
                  Second column
                </div>
                <div class="column">
                  Third column
                </div>
                <div class="column">
                  Fourth column
                </div>
              </div>
            </template>
<!--          </template>-->


          <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
        </b-table>

      </section>
    </div>







<!--    <div class="container is-widescreen" style="margin-top: 10px" >-->
<!--      <div class="notification is-primary">-->
<!--        <p class="title">Ausstellung Vreden</p>-->

<!--        <div class="container">-->
<!--          <div class="notification is-secondary is-light">-->
<!--            <p class="title">Regal 1</p>-->

<!--            <div class="columns">-->
<!--              <div class="column">-->
<!--                <article class="panel is-info">-->
<!--                  <p class="panel-heading">-->
<!--                    Fach 1-->
<!--                  </p>-->

<!--                  <a class="panel-block has-background-white has-text-black">-->
<!--            <span class="panel-icon is-info">-->
<!--              <i class="fas fa-dolly" aria-hidden="true"></i>-->
<!--            </span>-->
<!--                    Fliese "abc"-->
<!--                  </a>-->

<!--                </article>-->
<!--              </div>-->
<!--              <div class="column">-->
<!--                <article class="panel is-info">-->
<!--                  <p class="panel-heading">-->
<!--                    Fach 2-->
<!--                  </p>-->
<!--                  <a class="panel-block">-->
<!--            <span class="panel-icon">-->
<!--              <i class="fas fa-dolly" aria-hidden="true"></i>-->
<!--            </span>-->
<!--                    Fliese "abc"-->
<!--                  </a>-->

<!--                </article>-->
<!--              </div>-->
<!--              <div class="column">-->
<!--                <article class="panel is-info">-->
<!--                  <p class="panel-heading">-->
<!--                    Fach 2-->
<!--                  </p>-->


<!--                </article>-->
<!--              </div>-->
<!--              <div class="column">-->

<!--              </div>-->
<!--            </div>-->





<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->





  </div>
</template>

<script>

// import apiservice from "@/services/apiservice";



import apiservice from "@/services/apiservice";
// import NewStockEntry from "@/components/NewStockEntry";
import StockBookingIn from "@/components/StockBookingIn";
import StockBookingOut from "@/components/StockBookingOut";
import ArticlePickerPromt from "@/components/ArticlePickerPromt";



export default {
  name: 'StockOverview',
  props: {
    msg: String
  },
  components: {

  },
  data() {
    return {
      data: [],
      article: null,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      isLoading: false
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let self = this

      let url = "stock"
      if (this.article) {
        url += "/" + this.article.id
      }
      apiservice()
          .get(url)
          .then(response => {
            this.data = response.data.data
            self.isLoading = false
          });
    },
    openStockBookingIn: function() {
      // let self = this;
      if (this.article) {
        this.openStockBookingInDo(this.article.id)
      } else {
        this.$buefy.modal.open({
          component: ArticlePickerPromt,
          parent: this,
          props: {
            // "question": question
          },
          onCancel: function () {
            console.log();
          },
          events: {
            'confirm': newValue => {
              this.openStockBookingInDo(newValue.id)
            }
          }
        })
      }



    },

    openStockBookingInDo: function(article_id) {
      let self = this;
      let modal = this.$buefy.modal.open({
        parent: this,
        component: StockBookingIn,
        props: {'article_id': article_id},
        hasModalCard: true,
        customClass: '',
        width: 1000,
        trapFocus: true
      })
      modal.$on('close', () => self.fetchData())



    },

    openStockBookingOutStock: function(stock) {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: StockBookingOut,
        props: {'stock': stock},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
      modal.$on('close', () => this.fetchData())
    },
    // eslint-disable-next-line no-unused-vars
    openArticle: function(id){
      this.$router.push({ path: `/articles/${id}` })
    },
    searchStockPlace: function(d, input) {
      let s = d.stock_place.warehouse + "|" + d.stock_place.rack + "|" + d.stock_place.shelf;
      return s.toLowerCase().includes(input.toLowerCase())
    },

    searchShelf: function(d, input) {
      let s = d.stock_place.shelf
      return s.toLowerCase() == input.toLowerCase()
    }
  },
  beforeMount(){
    // this.fetchData()

    if (this.$route.params.article_id){
      apiservice()
          .get('articles/'+this.$route.params.article_id)
          .then(response => {
            this.article = response.data.data
            this.fetchData()
            // self.isLoading = false
          });
    } else {
      this.fetchData()
    }
  },
  dateFormatter: function(date) {
    let d = new Date(date)
    // return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
    return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`
    // return d.toISOString()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
