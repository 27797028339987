<template>
  <div class="stockvalue">

    <b-table
        v-if="true"
        :data="data"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        default-sort="name">

      <template>
        <b-table-column field="warehouse" label="Lager / Halle" numeric sortable width="40" v-slot="props">
          {{ props.row.warehouse }}
        </b-table-column>

        <b-table-column field="value" label="Wert" sortable width="100" v-slot="props">
          {{ formatEuro(props.row.value) }}
        </b-table-column>


<!--        <b-table-column label="Aktionen"  v-slot="props">-->

<!--          <b-button type="is-primary" @click="openItem(props.row.id)"><i class="fa fa-eye"></i>&nbsp;Bearbeiten</b-button>-->

<!--        </b-table-column>-->

      </template>
    </b-table>


<!--    <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false"></b-loading>-->

<!--    <b-message-->
<!--        title="Bestandswert"-->
<!--        type="is-info"-->
<!--        has-icon>-->
<!--      Hier kann der Lagerbestandswert berechnet werden. Mit Klick auf den Button wird die Berechnung gestartet.<br /><b>Diese  kann einige Zeit in Anspruch nehmen.</b> Haben Sie also etwas Geduld und schließen Sie das Browserfenster nicht. Der Wert wird dann anschließend hier angezeigt.-->
<!--    </b-message>-->
<!--    <b-button size="is-large is-primary" v-if="!items.length>0" expanded @click="fetchData" v-bind:class="{ 'is-loading': isLoading }">Berechnung starten</b-button>-->





<!--    <div class="container is-widescreen" v-for="w in items" :key="w.name" style="margin-bottom: 10px">-->
<!--      <div class="notification is-primary">-->
<!--        <p class="title">{{w.name}} <b-tag size="is-large" type="is-success">{{formatEuro(w.value)}}</b-tag></p>-->

<!--        <div class="container"  v-for="r in w.items" :key="r.name" style="margin-bottom: 5px">-->
<!--          <div class="notification is-secondary is-light">-->
<!--            <p class="title">{{ r.name }} <b-tag size="is-large" type="is-success">{{formatEuro(r.value)}}</b-tag></p>-->




<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->

















  </div>
</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";

export default {
  name: 'StockValue',
  props: {
    msg: String
  },
  components: {
    // ArticleCategory
  },
  data() {
    return {
      data: [],
      items: [],
      isLoading: false
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let self = this;

      apiservice()
          .get('stock/value')
          .then(response => {
            this.data = response.data.data
            console.log("CCCCCC")
            this.isLoading = false
          })
          .catch(function (error) {
            self.isLoading = false
            if (error.response.status == 403) {
              self.$buefy.dialog.alert({
                title: 'Fehler',
                message: 'Du hast keine Berechtigung für diese Aktion.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            } else {
              self.$buefy.dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten. Möglicherweise hast du keine Berechtigung für diese Aktion.',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
            }
            console.log(error);
          });



      // apiservice()
      //     .get('stock/value')
      //     .then(response => {
      //       this.data = response.data.data
      //       this.items = this.stockTable(response.data.data)
      //       this.isLoading = false
      //       console.log("updated")
      //     });
    },

    stockTable: function(rawdata) {
      let stockTable = []

      for (const [wkey, wvalue] of Object.entries(rawdata)) {



        let wsum = 0;
        let ritems = [];
        for (const [rkey, rvalue] of Object.entries(wvalue)) {

          let rsum = 0
          let sitems = []
          for (const [skey, svalue] of Object.entries(rvalue)) {
            sitems.push({name:"Fach " + skey, value:svalue})
            if (svalue) {
              rsum += svalue
            }
          }
          ritems.push({name:"Regal " + rkey, value:rsum, items: sitems})
          wsum += rsum


        }
        stockTable.push({name:"Halle " + wkey, value:wsum, items:ritems})


      }
      return stockTable;
    },
    finRound: function(amount){
      return (Math.round(amount * 100) / 100).toFixed(2);
    },
    formatEuro: function(amount) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount);
     }

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
