<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Bilder hochladen</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <file-pond
            name="file"
            ref="pond"
            label-idle="Dateien hier ablegen oder <span class='filepond--label-action'>Dateien auswählen</span>."
            v-bind:allow-multiple="true"
            v-bind:allowRemove="false"
            v-bind:allowRevert="false"
            :credits="false"
            labelFileLoading="Laden"
            labelFileProcessing="Hochladen"
            labelFileProcessingComplete="Fertig hochgeladen"
            labelTapToCancel="Tappen, um abzubrechen"
            labelTapToRetry="Tappen, um nochmal zu versuchen"
            accepted-file-types="image/jpeg, image/png, image/gif, image/tiff"
            v-bind:server=api_url
            v-bind:files="myFiles"
            v-on:init="handleFilePondInit"
        />

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Fertig"

            @click="$emit('close')"
            type="is-primary"/>
      </footer>
    </div>
  </form>




</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)
export default {
  name: 'MediaUploadPrompt',
  props: {object_type:String, object_id:String},
  data: function() {
    return { myFiles: [] }
  },
  components: {
    FilePond
  },
  methods: {
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // FilePond instance methods are available on `this.$refs.pond`
    },
  },
  computed: {
    api_url: function () {
      return "/api/media/image/" + this.object_type + "/" + this.object_id;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>