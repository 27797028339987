import { render, staticRenderFns } from "./ContactPerson.vue?vue&type=template&id=223fbf51&scoped=true&"
import script from "./ContactPerson.vue?vue&type=script&lang=js&"
export * from "./ContactPerson.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "223fbf51",
  null
  
)

export default component.exports