<template>
  <div class="article">

    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Projekt</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">


        <div class="tile is-ancestor">
          <div class="tile is-vertical is-16">
            <div class="tile">
              <div class="tile is-parent is-vertical is-8">
                <article class="tile is-child notification box">

                  <b-field label="Name">
                    {{data.name}}
                  </b-field>

                  <b-field label="Projektanschrift">
                    {{data.street}} <br />
                    {{data.zip}} {{data.place}} <br />
                    {{data.country}}
                  </b-field>

                </article>

              </div>

              <div class="tile is-parent is-16">
                <article class="tile is-child notification box">
                  <b-collapse
                      class="card"
                      animation="slide"
                      v-for="(person, index) of contact_persons"
                      :key="index"
                      :open="contact_persons_isOpen == index"
                      @open="contact_persons_isOpen = index"
                      :aria-id="'contentIdForA11y5-' + index">
                    <template #trigger="props">
                      <div
                          class="card-header"
                          role="button"
                          :aria-controls="'contentIdForA11y5-' + index"
                          :aria-expanded="props.open"
                      >
                        <p class="card-header-title">
                          <b-icon icon="user" pack="fa"></b-icon> {{ person.name }}
                        </p>
                        <a class="card-header-icon">
                          <b-icon
                              :icon="props.open ? 'menu-down' : 'menu-up'">
                          </b-icon>
                        </a>
                      </div>
                    </template>
                    <div class="card-content">
                      <div class="content">
                        <b-field label="Funktion">
                          {{ person.function }}
                        </b-field>

                        <b-field label="Anrede">
                          {{ person.salutation }}
                        </b-field>

                        <b-field label="Standort">
                          {{ person.location }}
                        </b-field>

                        <b-field label="Telefon">
                          {{ person.phone }}
                        </b-field>

                        <b-field label="Mobil">
                          {{ person.mobile }}
                        </b-field>

                        <b-field label="E-Mail">
                          {{ person.email }}
                        </b-field>
                      </div>
                    </div>
                  </b-collapse>

                </article>
              </div>
            </div>
          </div>

        </div>

<!--        <div class="tile is-ancestor">-->
<!--          <div class="tile is-vertical is-16">-->
<!--            <div class="tile">-->
<!--              <div class="tile is-parent is-vertical is-4">-->
<!--                <article class="tile is-child notification box">-->
<!--                  -->

<!--                </article>-->

<!--              </div>-->


<!--              <div class="tile is-parent is-4">-->
<!--                <article class="tile is-child notification box">-->
<!--                  -->
<!--                </article>-->
<!--              </div>-->


<!--              <div class="tile is-parent is-4">-->
<!--                <article class="tile is-child notification box">-->
<!--                  -->
<!--                  -->
<!--                </article>-->
<!--              </div>-->

<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />

      </footer>
    </div>

  </div>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'ProjectSummary',
  props: ['project_id'],
  data() {
    return {
      data: {},
      contact_persons: {},
      contact_persons_isOpen: 0
    }
  },
  components: {

  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('projects/' + this.project_id)
          .then(response => (this.data = response.data.data));
      apiservice()
          .get("contactpersons/project/" + this.project_id)
          .then(response => (this.contact_persons = response.data.data));
    }
  },
  beforeMount() {
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
