<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Ansprechpartner</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">

        <b-field label="Funktion" horizontal>
          <b-input v-model="data.function" placeholder="z.B. Buchhaltung, Verkauf, Geschäftsführung, ..."></b-input>
        </b-field>

        <b-field label="Anrede" horizontal>
<!--          <b-input v-model="data.salutation"></b-input>-->
          <SalutationPicker v-model="data.salutation"></SalutationPicker>
        </b-field>

        <b-field label="Name" horizontal>
          <b-input v-model="data.name"></b-input>
        </b-field>

        <b-field label="Standort" horizontal>
          <b-input v-model="data.location"></b-input>
        </b-field>

        <b-field label="Telefon" horizontal>
          <b-input v-model="data.phone"></b-input>
        </b-field>

        <b-field label="Mobil" horizontal>
          <b-input v-model="data.mobile"></b-input>
        </b-field>

        <b-field label="E-Mail" horizontal>
          <b-input v-model="data.email"></b-input>
        </b-field>




      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="OK"
            type="is-primary"
            @click="create" />
      </footer>
    </div>
  </form>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import apiservice from "@/services/apiservice";
import SalutationPicker from "@/components/SalutationPicker";


export default {
  name: 'ContactPerson',
  props: ['id', 'relation_type', 'relation_id'],
  data() {
            return {
              data: {},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name
      }
    },
    relation_id_comp() {
      if (this.relation_type == 'customer')
        return this.data.customer_id
      if (this.relation_type == 'supplier')
        return this.data.supplier_id
      if (this.relation_type == 'project')
        return this.data.project_id
      return null
    }
  },
  components: {
    SalutationPicker

  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('contactpersons/' + this.id)
          .then(response => (this.data = response.data.data))
          .catch(function (error) {
            Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    // update: function() {
    //   // let self = this
    //   apiservice()
    //       .post("contactpersons/", this.data)
    //       .then(function (response) {
    //           Toast.open({
    //             message: 'Ansprechpartner erfolgreich geändert.',
    //             position: 'is-bottom',
    //             type: 'is-success'
    //           })
    //           console.log(response);
    //         // self.$router.push("/customers");
    //         })
    //         .catch(function (error) {
    //           Dialog.alert({
    //             title: 'Fehler',
    //             message: 'Ein Fehler ist aufgetreten: ' + error,
    //             type: 'is-danger',
    //             hasIcon: true,
    //             icon: 'times-circle',
    //             iconPack: 'fa',
    //             ariaRole: 'alertdialog',
    //             ariaModal: true
    //           })
    //           console.log(error);
    //         });
    // },
    create: function() {
      if (!this.data.id) {
        this.data.id = "new"
      }
      let self = this;

      apiservice().post("contactpersons/", this.data)
          .then(function (response) {
            Toast.open({
              message: 'Erfolgreich',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$emit('confirm')
            self.$emit('close')
          })
          .catch(function (error) {
            Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
            console.log(error);
          });
    }
  },
  beforeMount() {
    if (this.id == "new") {
      this.isNew = true

      if (this.relation_type == 'customer')
          this.data.customer_id = this.relation_id
      if (this.relation_type == 'supplier')
        this.data.supplier_id = this.relation_id
      if (this.relation_type == 'project')
        this.data.project_id = this.relation_id
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
