<template>
  <div>
    <span v-if="items.includes(value)">
      <b-select placeholder="Anrede" v-model="value" @input="updateValue" @select="updateValue" @change="updateValue" expanded>
        <option
            v-for="option in items"
            :value="option"
            :key="option">
          {{ option }}
        </option>
        <option value="">
          Anderes...
        </option>
      </b-select>
    </span>

    <span v-if="!items.includes(value)">
      <b-input placeholder="Anrede"
               v-model="value"
               icon-right="close-circle"
               icon-right-clickable
               @icon-right-click="value=items[0]; updateValue()"
               @change="updateValue"
               @input="updateValue"
               ref="textinput"
      >
      </b-input>
    </span>
  </div>
</template>

<script>

// import apiservice from "@/services/apiservice";


export default {
  name: 'SalutationPicker',
  data() {
    return {
      items: ['Herrn', 'Frau', 'Familie', 'Ehepaar', 'Firma']
    }
  },
  props: {
    value: String
  },
  methods: {
    fetchData: function() {
      // let path = "units"
      // if (this.group != null && this.group != '') {
      //   path += "/group/"+this.group
      // }
      // apiservice()
      //     .get(path)
      //     .then(response => (this.items = response.data.data));
    },
    updateValue() {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  },
  computed: {
  },
  mounted(){
    if (!this.value) {
      this.value = this.items[0];
    }
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>