import axios from 'axios';
import routes from '@/routes';

export default () => {
    let headers = {
        'cache-control': 'no-cache'
    };
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token !== '') {
        headers.Authorization = "Bearer " + user.access_token;
    }
    const instance = axios.create({
        baseURL: '/api/',
        headers: headers
    });

    instance.interceptors.response.use((response) => {
        //successful request
        return response;
    }, (error) => {
        if(error.response.status === 401) {
            //add your code
            routes.push({ path: `/login` })
        }
        if (error.response && error.response.data) {

            return Promise.reject(error.response.data);
        }
        return Promise.reject(error.message);
    });

    return instance;
}