import { render, staticRenderFns } from "./LabelOverview.vue?vue&type=template&id=69bc11bf&scoped=true&"
import script from "./LabelOverview.vue?vue&type=script&lang=js&"
export * from "./LabelOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bc11bf",
  null
  
)

export default component.exports