<template>
  <div class="stock">
<!--    <b-loading :is-full-page="false" active="true" :can-cancel="false"></b-loading>-->


    <div class="stockoverview">
      <section>
        <b-field group-multiline grouped>
          <!--        <div class="control">-->
          <!--          <router-link class="" to="/paymentconditions/new">-->
          <!--            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neue Zahlungskondition</b-button> &nbsp;-->
          <!--          </router-link>-->
          <!--        </div>-->




<!--          <div class="control">-->

<!--            <b-button type="is-warning" @click="openNewEntry(-1)"><i class="fa fa-sync"></i>&nbsp;Umbuchen</b-button>-->

<!--          </div>-->

<!--          <div class="control">-->

<!--            <b-button type="is-danger" @click="openNewEntry(-1)"><i class="fa fa-minus"></i>&nbsp;Ausgang buchen</b-button>-->

<!--          </div>-->

          <div class="control">

            <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

          </div>
          <!--<b-select v-model="defaultSortDirection">
              <option value="asc">Default sort direction: ASC</option>
              <option value="desc">Default sort direction: DESC</option>
          </b-select>-->
          <b-select v-model="perPage" :disabled="!isPaginated">
            <option value="10">10 je Seite</option>
            <option value="20">20 je Seite</option>
            <option value="50">50 je Seite</option>
            <option value="100">100 je Seite</option>
            <option value="200">200 je Seite</option>
<!--            <option value="500">500 je Seite</option>-->
<!--            <option value="1000">1000 je Seite</option>-->
          </b-select>


            <b-switch v-model="onlyFilled">
              Leere Lagerplätze ausblenden
            </b-switch>


        </b-field>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="isPaginated"
            :pagination-position="paginationPosition"
            :pagination-simple="isPaginationSimple"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"

            detailed
            :detail-key="id"
            :show-detail-icon="true"

        >

<!--          <template>-->
            <!--          <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">-->
            <!--            {{ props.row.id }}-->
            <!--          </b-table-column>-->

            <b-table-column field="warehouse" label="Halle" v-slot="props" width="20" searchable>
              {{ props.row.warehouse }}
<!--              {{// props.row.index}}-->
            </b-table-column>

            <b-table-column field="rack" label="Regal" v-slot="props" width="20" searchable>
              {{ props.row.rack }}
            </b-table-column>

            <b-table-column field="shelf" label="Fach" v-slot="props" width="20" searchable>
              {{ props.row.shelf }}
            </b-table-column>

<!--            <b-table-column field="article.id" label="Art.-Nr." v-slot="props" width="100" searchable>-->
<!--              {{ props.row.article_charge.article_id }}-->
<!--            </b-table-column>-->

            <b-table-column field="article.name" label="Artikel (Charge)" v-slot="props" width="800" searchable>
              <b-taglist>
                <b-tag type="is-primary is-light" v-for="item in (props.row.stocks || []).slice(0,2)" v-bind:key="item.id">
                  <router-link :to="'/articles/' + item.article_charge.article_id" target='_blank'>
                    <span v-if="!item.article_charge.article.orderout_use_series">
                      {{ item.article_charge.article.name }}
                    </span>
                    <span v-if="item.article_charge.article.orderout_use_series">

                      {{item.article_charge.article.manufacturer}}
                      {{item.article_charge.article.series}}
                      {{item.article_charge.article.color}}
                      {{item.article_charge.article.format_width}} {{item.article_charge.article.format_width_unit_symbol}}
                      {{item.article_charge.article.format_length}} {{item.article_charge.article.format_length_unit_symbol}}
                      <span :hidden="!item.article_charge.article.format_depth">
                        {{item.article_charge.article.format_depth}} {{item.article_charge.article.format_depth_unit_symbol}}
                      </span>
                      {{item.article_charge.article.anti_slip_property}}
                      {{item.article_charge.article.rectified ? "rekt." : ""}}
                  </span>
                  </router-link>
                </b-tag>
<!--                <ArticleTags-->
<!--                    :id="item.article_charge.article_id"-->
<!--                    :name="item.article_charge.article.name"-->
<!--                    :orderout_use_series="item.article_charge.article.orderout_use_series"-->
<!--                    :manufacturer="item.article_charge.article.manufacturer"-->
<!--                    :series="item.article_charge.article.series"-->
<!--                    :color="item.article_charge.article.color"-->
<!--                    :format_width="item.article_charge.article.format_width"-->
<!--                    :format_width_unit_symbol="item.article_charge.article.format_width"-->
<!--                    :format_lenght="item.article_charge.article.format_length"-->
<!--                    :format_length_unit_symbol="item.article_charge.article.format_length_unit_symbol"-->
<!--                    :format_depth="item.article_charge.article.format_depth"-->
<!--                    :format_depth_unit_symbol="item.article_charge.article.format_depth_unit_symbol"-->
<!--                    :anti_slip_propery="item.article_charge.article.anti_slip_property"-->
<!--                    :rectified="item.article_charge.article.rectified"-->
<!--                    v-for="item in (props.row.stocks || []).slice(0,2)" v-bind:key="item.id"-->
<!--                ></ArticleTags>-->

                <b-tag type="is-primary is-light" v-if="props.row.stocks.length > 2">
                  <a @click="props.toggleDetails(props.row)">...</a>
                </b-tag>

<!--                <span v-if="item.article_charge.article.orderout_use_series">-->
<!--                <b-tag type="is-primary">-->
<!--                  {{item.article_charge.article.manufacturer}}-->
<!--                  {{item.article_charge.article.series}}-->
<!--                  {{item.article_charge.article.color}}-->
<!--                  {{item.article_charge.article.format_width}} {{item.article_charge.article.format_width_unit_symbol}}-->
<!--                  {{item.article_charge.article.format_length}} {{item.article_charge.article.format_length_unit_symbol}}-->
<!--                  <span :hidden="!props.row.article_charge.article.format_depth">-->
<!--                    {{item.article_charge.article.format_depth}} {{item.article_charge.article.format_depth_unit_symbol}}-->
<!--                  </span>-->
<!--                  {{item.article_charge.article.anti_slip_property}}-->
<!--                  {{item.article_charge.article.rectified ? "rekt." : ""}}-->
<!--                </b-tag>-->
<!--              </span>-->
<!--                <span v-if="item.article_charge.name && (item.article_charge.name.length)>0">-->
<!--            <b-tag>{{ item.article_charge.name }}</b-tag></span>-->



              </b-taglist>


            </b-table-column>

            <!--          <b-table-column field="article_charge.name" label="Charge" v-slot="props" width="20" searchable>-->

            <!--          </b-table-column>-->


<!--            <b-table-column field="amount" label="Anzahl" width="190" v-slot="props" numeric searchable >-->
<!--              {{ props.row.amount }} {{ props.row.article_charge.article.default_unit_symbol ? props.row.article_charge.article.default_unit_symbol : "" }}-->
<!--            </b-table-column>-->



<!--            <b-table-column field="comment" label="Kommentar" width="300" v-slot="props" searchable>-->
<!--              {{ props.row.comment }}-->
<!--            </b-table-column>-->

          <b-table-column field="rack" label="Letzte Prüfung" v-slot="props" width="50" searchable>
            <b-tag v-if="!props.row.last_stocktaking" type="is-danger is-medium">Nie</b-tag>
            <b-tag v-if="props.row.last_stocktaking" type="is-success is-medium">{{ props.row.last_stocktaking }}</b-tag>
          </b-table-column>


            <b-table-column label="Aktionen"  v-slot="props">

              <b-button type="is-success is-light" @click="updateLastSocktaking(props.row.id)"><i class="fa fa-check"></i>&nbsp;Geprüft</b-button>

            </b-table-column>

            <template #detail="props">

              <b-table>
                <tr>
                  <th>Artikel</th>
                  <th>Charge</th>
                  <th>Menge</th>
                  <th>Aktionen</th>
                </tr>
                <tr v-for="s in (props.row.stocks)" v-bind:key="s.id">
                  <td>
                    <ArticleTags
                      :id="s.article_charge.article_id"
                      :orderout_use_series="s.article_charge.article.orderout_use_series"
                      :name="s.article_charge.article.name"
                      :manufacturer="s.article_charge.article.manufacturer"
                      :series="s.article_charge.article.series"
                      :color="s.article_charge.article.color"
                      :format_width="s.article_charge.article.format_width"
                      :format_width_unit_symbol="s.article_charge.article.format_width"
                      :format_lenght="s.article_charge.article.format_length"
                      :format_length_unit_symbol="s.article_charge.article.format_length_unit_symbol"
                      :format_depth="s.article_charge.article.format_depth"
                      :format_depth_unit_symbol="s.article_charge.article.format_depth_unit_symbol"
                      :anti_slip_propery="s.article_charge.article.anti_slip_property"
                      :rectified="s.article_charge.article.rectified"
                      :charge="s.article_charge.name"
                    ></ArticleTags></td>
                  <td>{{s.article_charge.name}}</td>
                  <td>{{s.amount}}</td>
                  <td>
<!--                    <b-button type="is-warning is-light" @click="updateLastSocktaking(props.row.id)"><i class="fa fa-sync"></i>&nbsp;Umbuchen</b-button>-->
                    <b-button type="is-success is-light" @click="stocktakingCorrection(s)"><i class="fa fa-equals"></i>&nbsp;Korrektur</b-button>
<!--                    <b-button type="is-danger is-light" @click="updateLastSocktaking(props.row.id)"><i class="fa fa-minus"></i>&nbsp;Korrektur</b-button>-->


                  </td>
                </tr>
              </b-table>
<!--              <nav class="level">-->
<!--                <div class="level-item">-->
<!--                  <ArticleTags :id="props.row.stocks[0].id"></ArticleTags>-->
<!--                </div>-->
<!--                <div class="level-item has-text-centered">-->
<!--                  <div>-->
<!--                    <p class="heading">Following</p>-->
<!--                    <p class="title">123</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="level-item has-text-centered">-->
<!--                  <div>-->
<!--                    <p class="heading">Followers</p>-->
<!--                    <p class="title">456K</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="level-item has-text-centered">-->
<!--                  <div>-->
<!--                    <p class="heading">Likes</p>-->
<!--                    <p class="title">789</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </nav>-->
<!--              <div class="columns">-->
<!--                <div class="column">-->
<!--                  {{ props.row.article_charge_id }}-->
<!--                </div>-->
<!--                <div class="column">-->
<!--                  Second column-->
<!--                </div>-->
<!--                <div class="column">-->
<!--                  Third column-->
<!--                </div>-->
<!--                <div class="column">-->
<!--                  Fourth column-->
<!--                </div>-->
<!--              </div>-->
            </template>
<!--          </template>-->



        </b-table>
        <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
      </section>
    </div>







<!--    <div class="container is-widescreen" style="margin-top: 10px" >-->
<!--      <div class="notification is-primary">-->
<!--        <p class="title">Ausstellung Vreden</p>-->

<!--        <div class="container">-->
<!--          <div class="notification is-secondary is-light">-->
<!--            <p class="title">Regal 1</p>-->

<!--            <div class="columns">-->
<!--              <div class="column">-->
<!--                <article class="panel is-info">-->
<!--                  <p class="panel-heading">-->
<!--                    Fach 1-->
<!--                  </p>-->

<!--                  <a class="panel-block has-background-white has-text-black">-->
<!--            <span class="panel-icon is-info">-->
<!--              <i class="fas fa-dolly" aria-hidden="true"></i>-->
<!--            </span>-->
<!--                    Fliese "abc"-->
<!--                  </a>-->

<!--                </article>-->
<!--              </div>-->
<!--              <div class="column">-->
<!--                <article class="panel is-info">-->
<!--                  <p class="panel-heading">-->
<!--                    Fach 2-->
<!--                  </p>-->
<!--                  <a class="panel-block">-->
<!--            <span class="panel-icon">-->
<!--              <i class="fas fa-dolly" aria-hidden="true"></i>-->
<!--            </span>-->
<!--                    Fliese "abc"-->
<!--                  </a>-->

<!--                </article>-->
<!--              </div>-->
<!--              <div class="column">-->
<!--                <article class="panel is-info">-->
<!--                  <p class="panel-heading">-->
<!--                    Fach 2-->
<!--                  </p>-->


<!--                </article>-->
<!--              </div>-->
<!--              <div class="column">-->

<!--              </div>-->
<!--            </div>-->





<!--          </div>-->
<!--        </div>-->

<!--      </div>-->
<!--    </div>-->





  </div>
</template>

<script>

// import apiservice from "@/services/apiservice";



import apiservice from "@/services/apiservice";
// import NewStockEntry from "@/components/NewStockEntry";
import StockBookingIn from "@/components/StockBookingIn";
import ArticleTags from "@/components/ArticleTags";
import StocktakingCorrection from "@/components/StocktakingCorrection";



export default {
  name: 'StockOverview',
  props: {
    msg: String
  },
  components: {
    ArticleTags
  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 100,
      isLoading: false,
      onlyFilled: true
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let self = this
      let url = "stock/by_stockplace"
      if (this.onlyFilled){
        url = "stock/by_stockplace/only_filled"
      }

      apiservice()
          .get(url)
          .then(response => {
            this.data = response.data.data
            self.isLoading = false
          });
    },

    updateLastSocktaking: function(stockplace_id) {

      let payload = {
        "stockplace_id": stockplace_id,
      }
      this.isLoading=true;
      let self = this;
      apiservice()
          .post('stock/update_last_stocktaking', payload)
          .then(function (response) {
            self.$buefy.toast.open({
              message: 'Prüfung vermerkt.',
              position: 'is-bottom',
              type: 'is-success'
            })
            self.isLoading=false;
            console.log(response);
            self.fetchData()
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);

            //this.data.sent = false;
          });
    },


    stocktakingCorrection: function(s) {

      let modal = this.$buefy.modal.open({
        parent: this,
        component: StocktakingCorrection,
        props: {stock: s},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
      modal.$on('close', () => this.fetchData())
    },


    openStockBookingIn: function(mode) {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: StockBookingIn,
        props: {'charge_id': null, 'charge_name': null, 'article_id': null, 'stock_entry_mode': mode},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
      modal.$on('close', () => this.fetchData())
    },
    // eslint-disable-next-line no-unused-vars
    openArticle: function(id){
      this.$router.push({ path: `/articles/${id}` })
    },
    searchStockPlace: function(d, input) {
      let s = d.stock_place.warehouse + "|" + d.stock_place.rack + "|" + d.stock_place.shelf;
      return s.toLowerCase().includes(input.toLowerCase())
    }
  },
  beforeMount(){
    this.fetchData()
  },
  dateFormatter: function(date) {
    let d = new Date(date)
    // return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
    return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`
    // return d.toISOString()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
