<template>
  <section>
    <b-field grouped group-multiline v-if="!this.$attrs.disabled">
<!--      <div class="control">-->
<!--        <b-switch v-model="showDetailIcon">Show detail icon</b-switch>-->
<!--      </div>-->
<!--      <div class="control">-->
<!--        <b-switch v-model="useTransition">Use transition (fade) when toggling details</b-switch>-->
<!--      </div>-->
      <div class="control">
        <button class="button is-primary" @click="addBlankLine()"><i class="fa fa-plus"></i>&nbsp; Freier Eintrag</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addArticleLine()"><i class="fa fa-plus"></i>&nbsp; Artikel</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addServiceLine()"><i class="fa fa-plus"></i>&nbsp; Dienstleistung</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addTextLine()"><i class="fa fa-plus"></i>&nbsp; Text</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addTextLine2()"><i class="fa fa-plus"></i>&nbsp; Untertext</button>
      </div>
      <div class="control">
        <button class="button is-secondary" @click="refresh()"><i class="fa fa-sort-amount-down"></i> Reihenfolge aktualisieren</button> &nbsp;
      </div>
    </b-field>

    <b-table
        :data="value"
        ref="table"
        :opened-detailed="defaultOpenedDetails"
        @details-open="(row) => $buefy.toast.open(`Expanded ${row.article.name}`)"
        :show-detail-icon="false"
        :row-class="(row, index) => row.line_type === 'text' && 'is-textline'"
        default-sort="line_nr"
        default-sort-direction="asc"
        striped>

      <b-table-column field="action" label="" width="10" v-slot="props" v-if="!disabled || true">
        <b-tooltip label="Kommissionierung" v-if="allowPickingOrders && props.row.article">
          <b-button icon-left="truck" icon-pack="fa" v-bind:class="{ 'is-primary': props.row.picking_order }" @click="!props.row.picking_order ? picking_order(props.row.id) : {}"></b-button><br />
        </b-tooltip>

        <a v-if="!disabled" @click="removeLine(props.row)" style="text-decoration: none; font-size: 0.8em;" class="line-remove"><i class="fa fa-times"></i></a><br /><br />

        <b-tooltip label="Artikel anzeigen">
          <a @click="openArticle(props.row.article_id)" v-if="props.row.line_type.includes('article')" class="line-linked"><i class="fa fa-dolly"></i></a>
        </b-tooltip>

        <b-tooltip label="Artikel ändern">
          <a @click="changeArticle(props.row.id)" v-if="props.row.line_type.includes('article') && !disabled" class="line-linked"><i class="fa fa-retweet"></i></a>
        </b-tooltip>

        <b-tooltip label="Dienstleistung anzeigen">
          <a @click="openService(props.row.service_id)" v-if="props.row.line_type.includes('service')" class="line-linked"><i class="fa fa-people-carry"></i></a><br />
        </b-tooltip>

        <b-tooltip label="skontierbar?" v-if="document_data.cash_discount && !props.row.line_type.includes('text')">
          <b-checkbox v-model="props.row.cash_discountable" :disabled="disabled" />
        </b-tooltip>
      </b-table-column>


      <b-table-column field="line_nr" label="Pos." width="70" numeric sortable v-slot="props">
        <b-input v-model="props.row.line_nr" v-if="!disabled"></b-input>
        <div v-if="disabled">{{ props.row.line_nr }}</div>
        <br />
        <div v-if="props.row.line_type == 'article' && props.row.article.has_services && !disabled">
          <b-tooltip label="Mit passender Dienstleistung zusammenführen" position="is-top">
            <b-button type="is-secondary" @click="mergeArticleService(props.row)"><i class="fa fa-people-carry"></i></b-button>
          </b-tooltip>
        </div>
<!--        <div :hidden="props.row.line_type != 'article'">-->
<!--          <b-button type="is-secondary" @click="openArticle(props.row.article_id)"><i class="fa fa-dolly"></i></b-button>-->
<!--        </div>-->
<!--        <div :hidden="props.row.line_type != 'service' && false">-->
<!--          <b-button type="is-secondary" @click="openService(props.row.service_id)"><i class="fa fa-people-carry"></i></b-button>-->
<!--        </div>-->
      </b-table-column>

      <b-table-column field="article_nr" label="Art.-Nr." numeric width="100" v-slot="props">
        <div :hidden="props.row.line_type.includes('text')">
          <b-input v-if="!disabled" v-model="props.row.article_nr" type="text"></b-input>
          <span v-if="disabled">{{props.row.article_nr}}</span>
        </div>
      </b-table-column>

      <b-table-column field="title" label="Text" width="350" v-slot="props">
        <b-input v-if="!disabled" v-model="props.row.title" style="font-weight: bold !important;"></b-input>
        <span v-if="disabled"><b>{{props.row.title}}</b></span>
<!--        <div :hidden="props.row.line_type == 'text'">-->
        <div>
          <b-input v-if="!disabled" v-model="props.row.description" type="textarea" rows="2"></b-input>
          <span v-if="disabled" style="white-space: pre-line;">{{props.row.description}}</span>
        </div>
      </b-table-column>

      <b-table-column field="amount" label="Anzahl" numeric width="100" v-slot="props">
        <div :hidden="props.row.line_type.includes('text')">
          <b-input v-if="!disabled" v-model="props.row.amount" type="number" step=".01"></b-input>
          <span v-if="disabled">{{props.row.amount}}</span>
        </div>
      </b-table-column>

      <b-table-column field="unit" label="Einheit" width="50" v-slot="props">
        <div :hidden="props.row.line_type.includes('text')">
          <unit-picker v-if="!disabled" v-model="props.row.unit"></unit-picker>
          <span v-if="disabled">{{ props.row.unit ? props.row.unit.symbol : "-" }}</span>
        </div>
      </b-table-column>

      <b-table-column field="single_price" label="Einzelpreis" width="100" numeric v-slot="props">
        <div :hidden="props.row.line_type.includes('text')">
          <b-input v-if="!disabled" v-model="props.row.single_price" type="number" step=".01"></b-input>
          <span v-if="disabled">{{props.row.single_price}} €</span>
        </div>
      </b-table-column>

      <b-table-column field="vat" label="USt." numeric width="50" v-slot="props">
        <div :hidden="props.row.line_type.includes('text')">
          <vat-type-picker v-if="!disabled" v-model="props.row.vat"></vat-type-picker>
          <span v-if="disabled">{{ props.row.vat ? props.row.vat.rate + " %" : "-"}}</span>
        </div>

        <b-switch size="is-small" :disabled="disabled" v-if="document_type=='invoice'" v-model="props.row.show_gross">Brutto zeigen</b-switch>
      </b-table-column>

      <b-table-column field="total" label="Summe" width="100" numeric v-slot="props">
        <div :hidden="props.row.line_type.includes('text')">
          {{ formatCurrency(lineTotal(props.row.amount, props.row.single_price)) }}<br />
          <b>{{ formatCurrency(lineTotal(props.row.amount, props.row.single_price) * (100+props.row.vat.rate)/100) }}</b><br />
        </div>
      </b-table-column>

    </b-table>

    <b-field grouped group-multiline v-if="!this.$attrs.disabled" :hidden="value.length<1" class="pt-4">
      <div class="control">
        <button class="button is-primary" @click="addBlankLine()"><i class="fa fa-plus"></i>&nbsp; Freier Eintrag</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addArticleLine()"><i class="fa fa-plus"></i>&nbsp; Artikel</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addServiceLine()"><i class="fa fa-plus"></i>&nbsp; Dienstleistung</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addTextLine()"><i class="fa fa-plus"></i>&nbsp; Text</button>
      </div>
      <div class="control">
        <button class="button is-primary" @click="addTextLine2()"><i class="fa fa-plus"></i>&nbsp; Untertext</button>
      </div>
      <div class="control">
        <button class="button is-secondary" @click="refresh()"><i class="fa fa-sort-amount-down"></i> Reihenfolge aktualisieren</button> &nbsp;
      </div>
    </b-field>

  </section>
</template>

<script>
//const data = require('/data/customers.json')

import UnitPicker from "@/components/UnitPicker";
import VatTypePicker from "@/components/VatTypePicker";
import ArticlePickerPromt from "@/components/ArticlePickerPromt";
import ArticleSummary from "@/components/ArticleSummary";
import ServicePickerPromt from "@/components/ServicePickerPromt";
import ServiceSummary from "@/components/ServiceSummary";
import apiservice from "@/services/apiservice";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {VatTypePicker, UnitPicker},
  data() {
    return {
      //data,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      useTransition: true
    }
  },
  props: ['value', 'document_data', 'document_type'],
  computed: {
    transitionName() {
      if (this.useTransition) {
        return 'fade';
      }
      return '';
    },
    disabled() {
      return this.$attrs.disabled;
    },
    allowPickingOrders() {
      return (this.document_type == "order" && this.document_data.finalized)
    }
  },
  mounted: function () {
    this.refresh();
  },
  methods: {
    lineTotal: function(amount, single_price){
      return (Math.round(amount * single_price * 100) / 100).toFixed(2);
    },
    nextLine: function(nextTen = true){
      let highest = 0;
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i].line_nr > highest) {
          highest = this.value[i].line_nr;
        }
      }
      if (nextTen) {
        return (Math.floor(highest / 10) + 1) * 10;
      }
      return ++highest;
    },
    addBlankLine: function () {

      let entry = {
        "amount": 1,
        "description": "",
        "document_invoice_id": 1,
        "line_nr": this.nextLine(),
        "single_price": 0.00,
        "title": "",
        "unit_id": 1,
        "vat_id": 1,
        "line_type": 'blank',
        "cash_discountable": true
      }
      this.value.push(entry);
      this.refresh();
    },
    addArticleLine: function() {
      this.$buefy.modal.open({
        component: ArticlePickerPromt,
        parent: this,
        props: {
          // "question": question
        },
        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {
            let format_suffix = ""

            if (value.format_width && value.format_length) {
              if (value.description && value.description.length > 0) {
                format_suffix += "\n";
              }
              format_suffix += "Format: " + parseFloat(value.format_length) + "x" + parseFloat(value.format_width) + (value.format_unit ? value.format_unit.symbol : "")
            }
            if (value.anti_slip_property) {
              format_suffix += "\nRutschhemmung: " +value.anti_slip_property
            }
            if (value.rectified) {
              format_suffix += "\nrektifiziert"
            }

            let entry = {
              "amount": 1,
              "description": value.description ? value.description + format_suffix : format_suffix,
              "document_invoice_id": 1,
              "line_nr": this.nextLine(),
              "single_price": value.sales_price ? value.sales_price : 0,
              "title": value.name,
              "unit_id": value.default_unit_id,
              "unit": value.default_unit,
              "vat_id": value.default_vat_id,
              "vat": value.default_vat,
              "article_id": value.id,
              "article_nr": "A"+value.id,
              "article": value,
              "line_type": 'article',
              "cash_discountable": value.cash_discountable,
              "show_gross": false
            }
            this.value.push(entry);
            this.refresh();
          }

        }
      })
    },
    addServiceLine: function() {
      this.$buefy.modal.open({
        component: ServicePickerPromt,
        parent: this,
        props: {
          // "question": question
        },
        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {
            let entry = {
              "amount": 1,
              "description": value.description,
              "line_nr": this.nextLine(),
              "single_price": value.sales_price ? value.sales_price : 0,
              "title": value.name,
              "unit_id": value.default_unit_id,
              "unit": value.default_unit,
              "vat_id": value.default_vat_id,
              "vat": value.default_vat,
              "service_id": value.id,
              "article_nr": "D"+value.id,
              "service": value,
              "line_type": 'service',
              "cash_discountable": value.cash_discountable,
              "show_gross": false
            }
            this.value.push(entry);
            this.refresh();
          }

        }
      })
    },
    mergeArticleService: function(row) {
      this.$buefy.modal.open({
        component: ServicePickerPromt,
        parent: this,
        props: {
          "filter_article_id": row.article.id
        },
        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {
            row.service = value
            row.service_id = value.id
            if (row.description.length > 0)
              row.description += " "
            row.description += value.article_service_description
            console.log(row.single_price)
            console.log(value.sales_price)
            console.log(typeof (row.single_price))
            console.log(typeof (value.sales_price))
            if (value.sales_price)
              row.single_price = (parseFloat(row.single_price) + parseFloat(value.sales_price))
            row.line_type = "article_service"
            this.refresh();
          }

        }
      })
    },
    addTextLine: function () {

      let entry = {
        "description": "",
        "line_nr": this.nextLine(false),
        "title": "Gruppe",
        "line_type": 'text'
      }
      this.value.push(entry);
      this.refresh();
    },
    addTextLine2: function () {

      let entry = {
        "description": "",
        "line_nr": this.nextLine(false),
        "title": "Untergruppe",
        "line_type": 'text2'
      }
      this.value.push(entry);
      this.refresh();
    },
    openArticle: function(article_id) {
      this.$buefy.modal.open({
        component: ArticleSummary,
        parent: this,
        props: {
          'article_id': article_id
        },
        onCancel: function () {
          console.log()
        },
        events: {
        }
      })
    },
    openService: function(service_id) {
      this.$buefy.modal.open({
        component: ServiceSummary,
        parent: this,
        props: {
          'service_id': service_id
        },
        onCancel: function () {
          console.log()
        },
        events: {
        }
      })
    },
    picking_order: function(document_line_id) {
      let self = this
      apiservice()
          .post("stock/picking_order/", {document_line_id: document_line_id})
          .then(response => {
            console.log(response)
            this.$emit('requestupdate')
          })
          .catch(function (error) {
            self.isLoading = false
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    removeLine: function(row) {

      this.$buefy.dialog.confirm({
        title: 'Position löschen?',
        message: 'Sind Sie sicher, dass Position <b>' + row.line_nr + " (" + row.title + ")</b> gelöscht werden soll?",
        confirmText: 'Ja, löschen',
        cancelText: 'Nein',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          for (let i = 0; i<this.value.length; i++) {
            if (this.value[i] == row) {
              this.value.splice(i,1)
            }
          }
        }
      })
    },
    changeArticle: function(row) {

      let self = this
      let r = row
      this.$buefy.dialog.confirm({
        title: 'Artikel ändern?',
        message: "Sind Sie sicher, dass der verknüpfte Artikel ersetzt werden soll?<br /><br /><i>Achtung: Der Titel, die Beschreibung und die Artikelnummer der Zeile werden überschrieben, andere Daten (z.B. Preis) bleiben unberührt! Eventuell verknüpfte (zusammengeführte) Dienstleistungen werden entfernt!</i>",
        confirmText: 'Ja, ersetzen',
        cancelText: 'Nein',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          this.$buefy.modal.open({
            component: ArticlePickerPromt,
            parent: this,
            props: {
              // "question": question
            },
            onCancel: function () {
              console.log()
            },
            events: {
              'confirm': article_value => {
                let format_suffix = ""

                if (article_value.format_width && article_value.format_length) {
                  if (article_value.description && article_value.description.length > 0) {
                    format_suffix += "\n";
                  }
                  format_suffix += "Format: " + parseFloat(article_value.format_length) + "x" + parseFloat(article_value.format_width) + (article_value.format_unit ? article_value.format_unit.symbol : "")
                }

                console.log(article_value)


                for (let i = 0; i<self.value.length; i++){
                  console.log(self.value[i].id)
                  console.log(r)
                  if (self.value[i].id == r) {
                    console.log("gefunden!")
                    console.log(self.value[i])
                    self.value[i].article_id = article_value.id
                    self.value[i].title = article_value.name
                    self.value[i].description = article_value.description ? article_value.description + format_suffix : format_suffix
                    self.value[i].article_nr =  "A"+article_value.id
                    self.value[i].line_type = 'article'
                    self.value[i].service_id = null
                    self.value[i].service = null

                  }
                }


                // let entry = {
                //   "amount": 1,
                //   "description": value.description ? value.description + format_suffix : format_suffix,
                //   "document_invoice_id": 1,
                //   "line_nr": this.nextLine(),
                //   "single_price": value.sales_price ? value.sales_price : 0,
                //   "title": value.name,
                //   "unit_id": value.default_unit_id,
                //   "unit": value.default_unit,
                //   "vat_id": value.default_vat_id,
                //   "vat": value.default_vat,
                //   "article_id": value.id,
                //   "article_nr": "A"+value.id,
                //   "article": value,
                //   "line_type": 'article',
                //   "cash_discountable": value.cash_discountable
                // }
                this.refresh();
              }

            }
          })
        }
      })
    },
    refresh: function() {
      // this.$buefy.toast.open("Table refreshed");
      this.$refs.table.initSort();

      this.$emit('change', this.value)
    },
    finRound: function(amount){
      return (Math.round(amount * 100) / 100).toFixed(2);
    },
    formatCurrency: function (value) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
    }
  }
}
</script>

<style>
  tr.is-textline {
    background: #e3daf5 !important;
  }
  tr.is-textline2 {
    background: #f0ebfa !important;
  }
  .line-linked:hover {
    color: #7957d5 !important;
  }
  .line-remove:hover {
    color: red !important;
  }
</style>