<template>
  <b-select placeholder="Einheit" v-model="value" v-bind:class="{ 'is-loading': items == [] }" @input="updateValue">
    <option
        v-for="(option, index) in items"
        :value="option"
        :key="index"
        :selected="index == 0">
      {{ option.unit.symbol }} <!--({{ option.name }})-->
    </option>
  </b-select>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'ArticleUnitPicker',
  data() {
    return {
      items: []
    }
  },
  props: {
    value: Object, article_id: Number
  },
  watch: {
    article_id: function() {
      this.fetchData()
    }
  },
  methods: {
    fetchData: function() {
      let path = "articles/" + this.article_id + "/units/all"
      apiservice()
          .get(path)
          .then(response => {
            this.items = response.data.data
            if (!this.value)
              this.value = this.items[0]
            this.updateValue()
          });
    },
    updateValue() {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  },
  computed: {
  },
  beforeMount(){
    this.fetchData()
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>