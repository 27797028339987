<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Lagerbuchung</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">

        <b-field position="is-centered">
          <b-radio-button v-model="stock_entry_mode"
                          :native-value="1"
                          type="is-success" expanded>
            <b-icon icon="plus"></b-icon>
            <span>Eingang</span>
          </b-radio-button>

          <b-radio-button v-model="stock_entry_mode"
                          :native-value="-1"
                          type="is-danger" expanded>
            <b-icon icon="minus"></b-icon>
            <span>Ausgang</span>
          </b-radio-button>
        </b-field>

        <b-field label="Artikel:">
          <ArticlePicker v-model="article" @change="refreshCharges" required />
        </b-field>


        <b-field>
          <b-select placeholder="Charge" v-model="article_charge" :loading="isLoadingCharges" @change="refreshCharges" expanded>
            <option
                v-for="charge in charges"
                :value="charge"
                :key="charge.id">
              {{ charge.name }}
            </option>
          </b-select>
        </b-field>

<!--        <b-field label="Charge:">-->
<!--          <b-input-->
<!--              type="text"-->
<!--              :value="charge_name"-->
<!--              disabled>-->
<!--          </b-input>-->
<!--        </b-field>-->

        <b-field label="Lagerplatz:">
          <StockPlacePicker v-model="data.stock_place" :article_charge="article_charge" :hide_nonstocked="stock_entry_mode == -1" required />
        </b-field>

        <b-field label="Kommentar:">
          <b-input
              type="text"
              v-model="data.comment"
              placeholder="Kommentar zur Buchung"
              required>
          </b-input>
        </b-field>


        <b-field grouped label="Menge:">
          <b-field>
            <b-numberinput
                type="number"
                v-model="amount_input"
                placeholder="Veränderung"
                min="0"
                step="1"
                min-step="0.01"
                controls-position="compact"
                controls-rounded
                required>
            </b-numberinput>
          </b-field>
          <b-field>
            <ArticleUnitPicker v-model="unit_input" :article_id="article ? article.id : null" />
          </b-field>
        </b-field>

        <b-field label="Buchung:" v-if="amount_input && unit_input && article && unit_input.unit.id != article.default_unit.id">
          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>
        </b-field>

<!--        <b-field label="Buchung:" v-if="amount && unit_input && article">-->
<!--          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>-->
<!--        </b-field>-->

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="Buchen"
            type="is-primary"
            @click="save"
            :disabled="!data.stock_place || !data.comment || !amount_input || !amount || inProgress"
        />
      </footer>
    </div>
  </form>
</template>

<script>

import StockPlacePicker from "@/components/StockPlacePicker";
import apiservice from "@/services/apiservice";
import ArticleUnitPicker from "@/components/ArticleUnitPicker";
import ArticlePicker from "@/components/ArticlePicker";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'NewStockEntry',
  // eslint-disable-next-line vue/no-unused-components
  components: {ArticleUnitPicker, StockPlacePicker, ArticlePicker},
  props: ['charge_id', 'charge_name', 'article_id', 'stock_entry_mode'],
  data() {
    return {
      inProgress: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      data: {},
      amount_input: 1,
      unit_input: null,
      article: null,
      isLoadingCharges: false,
      charges: null
    }
  },
  methods: {
    save: function (){
      this.inProgress = true
      this.data.article_charge = this.article_charge.id
      this.data.amount = this.amount
      let t = this;
      apiservice().post('stock/stock_entry/', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {

            t.$emit('close')
            // Toast.open({
            //   message: 'Bestandänderung gebucht',
            //   position: 'is-bottom',
            //   type: 'is-success'
            // })
            // console.log(response);
          })
          .catch(function (error) {
            t.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.data,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.inProgress = false
            console.log(error);
          });
    },
    fetchData: function() {
      apiservice()
          .get('articles/' + this.article_id)
          .then(response => {
            this.article = response.data.data
            this.article_id = this.article.id
            console.log("fetchData: " + this.article_id)
            this.refreshCharges()
          });
    },
    refreshCharges() {
      // this.data.isLoadingCharges = true
      console.log("refreshCharges")
      let self = this
      apiservice()
          .get('articles/' + this.article.id+ "/charges")
          .then(response => {
            self.charges = response.data.data
            if (self.charge_id) {
              for (let i = 0; i < self.charges.length; i++) {
                if (self.charges[i].id == self.charge_id) {
                  self.article_charge = self.charges[i]
                  break
                }
                self.unit_input = self.article.default_unit
              }
            }
          });
    }
  },
  computed: {
    amount: function() {
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100) * this.stock_entry_mode
    },
    article_charge_id: function () {
      if (this.article_charge)
        return this.article_charge.id
      else
        return null
    }
  },
  beforeMount(){
    if (this.article_id != null) {this.fetchData()}
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
