<template>
  <div class="supplier">
    <section>
      <b-field group-multiline grouped>
<!--        <div class="control">-->
<!--          <router-link class="" to="/paymentconditions/new">-->
<!--            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neue Zahlungskondition</b-button> &nbsp;-->
<!--          </router-link>-->
<!--        </div>-->

<!--        <div class="control">-->

<!--          <b-button type="is-success" @click="openNewEntryx(+1)"><i class="fa fa-plus"></i>&nbsp;Eingang buchen</b-button>-->

<!--        </div>-->


<!--        <div class="control">-->

<!--          <b-button type="is-warning" @click="openNewEntryx(-1)"><i class="fa fa-sync"></i>&nbsp;Umbuchen</b-button>-->

<!--        </div>-->

<!--        <div class="control">-->

<!--          <b-button type="is-danger" @click="openNewEntryx(-1)"><i class="fa fa-minus"></i>&nbsp;Ausgang buchen</b-button>-->

<!--        </div>-->

        <div class="control">

          <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>
        <!--<b-select v-model="defaultSortDirection">
            <option value="asc">Default sort direction: ASC</option>
            <option value="desc">Default sort direction: DESC</option>
        </b-select>-->
        <b-select v-model="perPage" :disabled="!isPaginated">
          <option value="10">10 je Seite</option>
          <option value="20">20 je Seite</option>
          <option value="50">50 je Seite</option>
        </b-select>

      </b-field>

      <b-table
          :current-page.sync="currentPage"
          :data="data"
          :default-sort-direction="defaultSortDirection"
          :paginated="isPaginated"
          :pagination-position="paginationPosition"
          :pagination-simple="isPaginationSimple"
          :per-page="perPage"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="name">

        <template>
<!--          <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">-->
<!--            {{ props.row.id }}-->
<!--          </b-table-column>-->

          <b-table-column field="date" label="Datum" v-slot="props">
              {{ new Date(props.row.date).toLocaleString() }}
          </b-table-column>

          <b-table-column field="type" label="Typ" width="20" searchable :custom-search="searchType">
            <template #searchable="props">
                <b-select placeholder="Typ" v-model="props.filters['type']" size="is-small">
                  <option value="">-</option>
<!--                  <optgroup label="Eingang">-->
                    <option value="in">Wareneingang</option>
                    <option value="re" disabled>Retour</option>
<!--                  </optgroup>-->

<!--                  <optgroup label="Ausgang">-->
                    <option value="out">Warenausgang</option>
                    <option value="break">Bruch</option>
                    <option value="consumption">Eigenverbrauch</option>>
<!--                  </optgroup>-->

<!--                  <optgroup label="Weiteres">-->
                    <option value="stocktaking">Inventurkorrektur</option>
                    <option value="reloc" disabled>Umbuchung</option>
<!--                  </optgroup>-->
                </b-select>

<!--              <b-input-->
<!--                  v-model="props.filters['type']"-->
<!--                  placeholder="Search..."-->
<!--                  icon="magnify"-->
<!--                  size="is-small" />-->
            </template>
            <template v-slot="props">

            <b-tag v-if="props.row.type=='out'" type="is-danger is-light">Warenausgang</b-tag>
            <b-tag v-if="props.row.type=='break'" type="is-dark">Bruch</b-tag>
            <b-tag v-if="props.row.type=='consumption'" type="is-secondary is-light">Eigenverbrauch</b-tag>
            <b-tag v-if="props.row.type=='stocktaking'" type="is-warning">Inventurkorrektur</b-tag>
            <b-tag v-if="props.row.type=='reloc'" type="is-warning is-light">Umlagerung</b-tag>
            <b-tag v-if="props.row.type=='in'" type="is-success">Wareneingang</b-tag>
            <b-tag v-if="props.row.type=='re'" type="is-success">Retur</b-tag></template>
<!--            {{ props.row.type }}-->
          </b-table-column>

          <b-table-column field="article_charge.article_id" label="Art.-Nr." v-slot="props" width="110" searchable :custom-search="searchId">
            {{ props.row.article_charge.article_id }}
          </b-table-column>

          <b-table-column field="article_charge.article.name" label="Artikel (Charge)" v-slot="props" searchable>
            <router-link :to="'/articles/' + props.row.article_charge.article_id" target='_blank'>
              {{ props.row.article_charge.article.name }}

            </router-link><br />
            <span v-if="props.row.article_charge.article.orderout_use_series">
                <b-tag type="is-primary">
                  {{props.row.article_charge.article.manufacturer}}
                  {{props.row.article_charge.article.series}}
                  {{props.row.article_charge.article.color}}
                  {{props.row.article_charge.article.format_width}} {{props.row.article_charge.article.format_width_unit_symbol}}
                  {{props.row.article_charge.article.format_length}} {{props.row.article_charge.article.format_length_unit_symbol}}
                  <span :hidden="!props.row.article_charge.article.format_depth">
                    {{props.row.article_charge.article.format_depth}} {{props.row.article_charge.article.format_depth_unit_symbol}}
                  </span>
                  {{props.row.article_charge.article.anti_slip_property}}
                  {{props.row.article_charge.article.rectified ? "rekt." : ""}}
                </b-tag>
              </span>
<!--            <span v-if="props.row.article_charge.name && (props.row.article_charge.name.length)>0">-->
<!--            <b-tag>{{ props.row.article_charge.name }}</b-tag></span>-->
          </b-table-column>

          <b-table-column field="article_charge.name" label="Charge" width="40" v-slot="props" searchable>
            {{ props.row.article_charge.name }}
          </b-table-column>

          <b-table-column field="article_charge.article.manufacturer" label="Hersteller" width="40" v-slot="props" searchable>
            {{ props.row.article_charge.article.manufacturer }}
          </b-table-column>

<!--          <b-table-column field="article_charge.name" label="Charge" v-slot="props" width="20" searchable>-->

<!--          </b-table-column>-->

          <b-table-column field="stock_place_in_id" label="Lagerplatz" :custom-search="searchStockPlace" width="140" v-slot="props">
              <span v-if="props.row.stock_place_in && !props.row.stock_place_out">
                <b-tag type="is-primary"><i class="fa fa-plus"></i></b-tag>
                <b-tag type="is-success">{{ props.row.stock_place_in.warehouse }} | {{ props.row.stock_place_in.rack }} | {{ props.row.stock_place_in.shelf }}</b-tag>
              </span>

              <span v-if="props.row.stock_place_out && !props.row.stock_place_in">
                <b-tag type="is-primary"><i class="fa fa-minus"></i></b-tag>
                <b-tag type="is-danger">{{ props.row.stock_place_out.warehouse }} | {{ props.row.stock_place_out.rack }} | {{ props.row.stock_place_out.shelf }}</b-tag>
              </span>

            <span v-if="props.row.stock_place_in && props.row.stock_place_out">
              <b-tag type="is-primary"><i class="fa fa-plus"></i></b-tag>
              <b-tag type="is-warning">{{ props.row.stock_place_in.warehouse }} | {{ props.row.stock_place_in.rack }} | {{ props.row.stock_place_in.shelf }}</b-tag>
              <br />
              <b-tag type="is-primary"><i class="fa fa-minus"></i></b-tag>
              <b-tag type="is-warning">{{ props.row.stock_place_in.warehouse }} | {{ props.row.stock_place_in.rack }} | {{ props.row.stock_place_in.shelf }}</b-tag>
            </span>

          </b-table-column>


          <b-table-column field="amount" label="Anzahl" width="20" v-slot="props" numeric searchable >
<!--            <b-tag type="is-success" size="is-medium" v-if="props.row.amount >= 0">{{ props.row.amount }} {{ props.row.article_charge.article.default_unit ? props.row.article_charge.article.default_unit.symbol : "" }}</b-tag>-->
<!--            <b-tag type="is-danger" size="is-medium" v-if="props.row.amount < 0">{{ props.row.amount }} {{ props.row.article.default_unit ? props.row.article.default_unit.symbol : "" }}</b-tag>-->
            {{ props.row.amount }} {{ props.row.article_charge.article.default_unit ? props.row.article_charge.article.default_unit.symbol : "" }}
          </b-table-column>



          <b-table-column field="comment" label="Kommentar" width="300" v-slot="props" searchable>
            {{ props.row.comment }}
          </b-table-column>

<!--          <b-table-column label="Aktionen"  v-slot="props">-->

<!--            <b-button type="is-primary" @click="openArticle(props.row.article.id)"><i class="fa fa-eye"></i>&nbsp;Artikel</b-button>-->

<!--          </b-table-column>-->
        </template>
      </b-table>
      <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
    </section>
  </div>
</template>

<script>

import apiservice from "@/services/apiservice";
import NewStockEntry from "@/components/NewStockEntry";



export default {
  name: 'StockLog',
  props: {
    msg: String
  },
  components: {

  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      isLoading: false
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let self = this
      apiservice()
          .get('stock/stocklog')
          .then(response => {
            this.data = response.data.data
            self.isLoading = false
          });
    },
    openNewEntry: function(mode) {
      let modal = this.$buefy.modal.open({
        parent: this,
        component: NewStockEntry,
        props: {'charge_id': null, 'charge_name': null, 'article_id': null, 'stock_entry_mode': mode},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
      modal.$on('close', () => this.fetchData())
    },
    // eslint-disable-next-line no-unused-vars
    openArticle: function(id){
      this.$router.push({ path: `/articles/${id}` })
    },
    searchStockPlace: function(d, input) {
      let s = d.stock_place.warehouse + "|" + d.stock_place.rack + "|" + d.stock_place.shelf;
      return s.toLowerCase().includes(input.toLowerCase())
    },

    searchId: function(d, input) {
      let s = d.article_charge.article_id.toString()
      return s.toLowerCase().includes(input.toLowerCase())
    },
    searchType: function(d, input) {
      let s = d.type
      return s.toLowerCase() == input.toLowerCase()
    }
  },
  beforeMount(){
    this.fetchData()
  },
  dateFormatter: function(date) {
    let d = new Date(date)
    // return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
    return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`
    // return d.toISOString()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
