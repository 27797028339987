<template>
  <div class="articles">
    <section>
      <b-field group-multiline grouped>
        <div class="control">
          <router-link class="" to="/services/new">
            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neue Dienstleistung</b-button> &nbsp;
          </router-link>
        </div>

        <div class="control">

          <b-button type="is-primary" @click="fetchData" v-bind:class="{ 'is-loading': isLoading }"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>

        <b-select v-model="perPage" :disabled="!isPaginated">
          <option value="10">10 je Seite</option>
          <option value="20">20 je Seite</option>
          <option value="50">50 je Seite</option>
        </b-select>
      </b-field>

      <b-table
          :current-page.sync="currentPage"
          :data="data"
          :default-sort-direction="defaultSortDirection"
          :paginated="isPaginated"
          :pagination-position="paginationPosition"
          :pagination-simple="isPaginationSimple"
          :per-page="perPage"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          hoverable
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="name">

        <template>
          <b-table-column field="id" label="Dienstl.-Nr." searchable sortable width="40" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

<!--          <b-table-column field="service_nr" label="D.-Nr." searchable sortable width="45" v-slot="props">-->
<!--            {{ props.row.service_nr }}-->
<!--          </b-table-column>-->

          <b-table-column field="name" label="Bezeichnung" searchable sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column field="description" label="Beschreibung" searchable sortable v-slot="props">
            <div v-if="props.row.description.length > 44">
              <b-tooltip :label="props.row.description" multilined>
                {{ (props.row.description).substring(0, 40) }}<span>...</span>
              </b-tooltip>
            </div>
            <div v-if="props.row.description.length <= 44">
                {{ props.row.description }}
            </div>
          </b-table-column>

          <b-table-column label="Aktionen" width="40" v-slot="props">
<!--            <b-button type="is-light">Stornieren</b-button>-->
<!--            <router-link class="" to="/invoice">-->
              <b-button type="is-primary" @click="openItem(props.row.id)"><i class="fa fa-eye"></i>&nbsp;Bearbeiten</b-button>
<!--            </router-link>-->


          </b-table-column>
        </template>
      </b-table>
    </section>
    <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
  </div>
</template>

<script>

import apiservice from "@/services/apiservice";

export default {
  name: 'ServiceOverview',
  props: {
    msg: String
  },
  components: {
    // ArticleCategory
  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10,
      isLoading: false
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      apiservice()
          .get("services")
          .then(response => {
            this.data = response.data.data;
            this.isLoading = false;
          });

    },
    openItem: function(id){
      this.$router.push({ path: `/services/${id}` })
    }
  },
  beforeMount(){
    this.fetchData()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
