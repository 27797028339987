<template>
  <form action="">
    <div class="modal-card" style="width: 600px">
      <header class="modal-card-head">
        <p class="modal-card-title">Eingang buchen</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-loading :active="inProgress" :is-full-page="false" :can-cancel="false" />


        <b-steps
            v-model="activeStep"
            :animated="true"
            :rounded="true"
            :has-navigation="false"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode"
        >


          <b-step-item step="1" label="Charge" :clickable="false">
<!--            <h1 class="title has-text-centered">Artikel</h1>-->
<!--            <b-field label="Typ:">-->
<!--              <b-select placeholder="Typ" v-model="stock_booking_type" expanded>-->
<!--                <option value="in">-->
<!--                  Wareneingang-->
<!--                </option>-->
<!--              </b-select>-->
<!--            </b-field>-->


<!--            <b-field label="Artikel:">-->
<!--              <ArticlePicker v-model="article" @change="refreshCharges" required />-->
<!--            </b-field>-->
            {{article_id}}
            {{article ? article.name : "nix"}}


<!--            <b-field>-->
<!--              <b-select placeholder="Charge" v-model="article_charge" :loading="isLoadingCharges" @change="refreshCharges" expanded>-->
<!--                <option-->
<!--                    v-for="charge in charges"-->
<!--                    :value="charge"-->
<!--                    :key="charge.id">-->
<!--                  {{ charge.name }}-->
<!--                </option>-->
<!--              </b-select>-->
<!--            </b-field>-->

            <div v-if="!article">Artikel wählen, um fortzufahren.</div>

            <div class="notification is-danger" v-if="article && charges.length == 0">
              Dieser Artikel hat keine hinterlegten Chargen. Artikel benötigt Charge, um Buchungen vorzunehmen.
            </div>

            <div v-if="charges">
              <b-button
                  v-for="charge in charges"
                  :value="charge"
                  :key="charge.id"
                  @click="selectCharge(charge.id)"
                  expanded
                  >
                <span v-if="['', null].includes(charge.name)">(leer)</span>
                <span v-if="!['', null].includes(charge.name)">{{ charge.name }}</span>
              </b-button>
            </div>

          </b-step-item>

          <b-step-item step="2" label="Lager"  :clickable="false">
            <div>
              <StockPlacePicker v-model="stock_place_picker" />
              <b-button @click="selectPlace(stock_place_picker)" expanded :disabled="!stock_place_picker"><i class="fa fa-arrow-right"></i></b-button>
            </div>


            <div v-if="stock_places" style="margin-top:20px">
              <h5 class="title is-5">Oder bisherigen Lagerplatz für diese Charge wählen:</h5>



              <b-table
                  :data="stock_places"
              >

                <b-table-column field="warehouse" label="Halle" v-slot="props" width="20" >
                  {{ props.row.warehouse }}
                </b-table-column>

                <b-table-column field="rack" label="Regal" v-slot="props" width="20" >
                  {{ props.row.rack }}
                </b-table-column>

                <b-table-column field="shelf" label="Fach" v-slot="props" width="20" >
                  {{ props.row.shelf }}
                </b-table-column>

                <b-table-column field="amount" label="Menge" v-slot="props" width="20" >
                  {{ props.row.amount }}
                </b-table-column>

                <b-table-column field="id" label=" " v-slot="props" width="20" >
                  <b-button @click="selectPlace(props.row.id)"><i class="fa fa-arrow-right"></i></b-button>
                  <!--              {{// props.row.index}}-->
                </b-table-column>
              </b-table>

            </div>
          </b-step-item>

          <b-step-item step="2" label="Wert"  :clickable="false">
            <div>
              <b-field label="Wert (€) je Basiseinheit">
                <b-numberinput controls-position="compact" controls-rounded v-model="manual_value" step="0.01"></b-numberinput>
              </b-field>
              <b-button @click="selectValue(manual_value)" expanded :disabled="!manual_value"><i class="fa fa-arrow-right"></i></b-button>
            </div>


            <div v-if="suppliers" style="margin-top:20px">
              <h5 class="title is-5">Oder Listenpreis von Lieferanten übernehmen:</h5>

              <b-table
                  :data="suppliers"
              >

                <b-table-column field="supplier.name" label="Lieferant"  v-slot="props">
                  {{ props.row.supplier.name }}
                </b-table-column>

                <b-table-column field="default_supplier" label="Standard" v-slot="props">
                  {{ props.row.default_supplier ? "Ja" : "Nein" }}
                </b-table-column>

                <b-table-column field="list_price" label="Listenpreis"  v-slot="props">
                  {{ props.row.list_price }} €
                </b-table-column>

<!--                <b-table-column field="id" label="Aktionen" v-slot="props">-->
<!--                  <b-button class="button is-primary" @click="addArticleSupplier(props.row.id)" expanded icon-pack="fa" icon-left="edit"></b-button>-->
<!--                </b-table-column>-->

                <b-table-column field="id" label=" " v-slot="props" width="20" >
                  <b-button @click="selectValue(props.row.list_price)" :disabled="!props.row.list_price"><i class="fa fa-arrow-right"></i></b-button>
                  <!--              {{// props.row.index}}-->
                </b-table-column>
              </b-table>

            </div>
          </b-step-item>

          <b-step-item step="3" label="Details" :clickable="false">
<!--            <h1 class="title has-text-centered">Social</h1>-->
            <b-field label="Kommentar:">
              <b-input
                  type="text"
                  v-model="data.comment"
                  placeholder="Kommentar zur Buchung"
                  required>
              </b-input>
            </b-field>


            <b-field grouped label="Menge:">
              <b-field>
                <b-numberinput

                    v-model="amount_input"
                    placeholder="Veränderung"

                    required>
                </b-numberinput>
              </b-field>
              <b-field>
                <ArticleUnitPicker v-model="unit_input" :article_id="article ? article.id : null" />
              </b-field>
            </b-field>


            <b-field label="Buchung in Basiseinheit:" v-if="amount_input && unit_input">
              <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>
            </b-field>

            <b-button
                label="Eingang buchen"
                type="is-primary"
                @click="save"
                :disabled="!selected_stock_place_id || !selected_value || !selected_charge_id || amount_input<=0 ||
                !amount || !data.comment|| inProgress"
                expanded
            />
          </b-step-item>

<!--          <b-step-item step="4" label="Zusammenfassung"  disabled>-->
<!--            <h1 class="title has-text-centered">Finish</h1>-->
<!--            Lorem ipsum dolor sit amet.-->
<!--          </b-step-item>-->

          <template
              v-if="customNavigation"
              #navigation="{previous, next}">
            <b-button
                outlined
                type="is-danger"
                icon-pack="fas"
                icon-left="backward"
                :disabled="previous.disabled"
                @click.prevent="previous.action">
              Previous
            </b-button>
            <b-button
                outlined
                type="is-success"
                icon-pack="fas"
                icon-right="forward"
                :disabled="next.disabled"
                @click.prevent="next.action">
              Next
            </b-button>
          </template>
        </b-steps>




<!--        <b-field label="Charge:">-->
<!--          <b-input-->
<!--              type="text"-->
<!--              :value="charge_name"-->
<!--              disabled>-->
<!--          </b-input>-->
<!--        </b-field>-->





<!--        <b-field label="Buchung:" v-if="amount && unit_input && article">-->
<!--          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>-->
<!--        </b-field>-->

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Abbrechen"
            @click="$emit('close')" />

      </footer>
    </div>
  </form>
</template>

<script>

import StockPlacePicker from "@/components/StockPlacePicker";
import apiservice from "@/services/apiservice";
import ArticleUnitPicker from "@/components/ArticleUnitPicker";
import ArticlePicker from "@/components/ArticlePicker";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'StockBookIn',
  // eslint-disable-next-line vue/no-unused-components
  components: {ArticleUnitPicker, StockPlacePicker, ArticlePicker},
  props: ['article_charge_id', 'article_id', 'type'],
  data() {
    return {
      inProgress: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      data: {},
      amount_input: 1,
      unit_input: null,
      article: null,
      isLoadingCharges: false,

      activeStep: 0,

      charges: null,
      stock_places: null,
      suppliers: null,

      selected_charge_id: null,
      selected_stock_place_id: null,
      selected_value: null,


      stock_place_picker: null,
      manual_value: null,

      stock_booking_type: 'in',
    }
  },
  methods: {
    save: function (){
      this.inProgress = true
      // this.data.article_charge = this.article_charge.id
      this.data.amount = this.amount
      this.data.value = this.selected_value
      this.data.stock_place_id = this.selected_stock_place_id
      this.data.article_charge_id = this.selected_charge_id

      let t = this;
      apiservice().post('stock/booking/in', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {

            t.$emit('close')
            t.$buefy.toast.open({
              message: 'Eingang gebucht',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
          })
          .catch(function (error) {
            t.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.data,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            this.inProgress = false
            console.log(error);
          });
    },
    fetchData: function() {
      this.inProgress = true
      apiservice()
          .get('articles/' + this.article_id)
          .then(response => {
            this.article = response.data.data
            this.article_id = this.article.id
            console.log("fetchData: " + this.article_id)
            this.refreshCharges()
          });
    },
    refreshCharges() {
      // this.data.isLoadingCharges = true
      console.log("refreshCharges")
      let self = this
      apiservice()
          .get('articles/' + this.article.id+ "/charges")
          .then(response => {
            self.charges = response.data.data
            // if (self.charges.length == 1){
            //   self.selectCharge(self.charges[0])
            // }
            self.inProgress = false
            // if (self.charge_id) {
            //   for (let i = 0; i < self.charges.length; i++) {
            //     if (self.charges[i].id == self.charge_id) {
            //       self.article_charge = self.charges[i]
            //       break
            //     }
            //     self.unit_input = self.article.default_unit
            //   }
            // }

          });
    },
    selectCharge(charge_id) {
      this.selected_charge_id = charge_id
      apiservice()
          .get("stock/charge/" + charge_id + "/stock_places/")
          .then(response => {
            this.stock_places = response.data.data;
            this.activeStep = 1
          });

    },
    selectPlace(place_id) {
      this.selected_stock_place_id = place_id;

      apiservice()
          .get("articles/" + this.article_id + "/suppliers")
          .then(response => {
            this.suppliers = response.data.data;
            this.activeStep = 2
          });

    },

    selectValue(value) {
      this.selected_value = value;

      this.activeStep = 3

      // apiservice()
      //     .get("articles/" + this.article_id + "/suppliers")
      //     .then(response => {
      //       this.suppliers = response.data.data;
      //       this.activeStep = 2
      //     });

    }

  },
  computed: {
    amount: function() {
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100)
    },
    // article_charge_id: function () {
    //   if (this.article_charge)
    //     return this.article_charge.id
    //   else
    //     return null
    // }
  },
  beforeMount(){
    if (this.article_id != null) {this.fetchData()}
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
