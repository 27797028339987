<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Textbausteine</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
<!--          <div class="control">-->
<!--            <b-button class="button is-primary" @click="addContactPerson()" expanded icon-pack="fa" icon-left="link">Neuer Ansprechpartner</b-button>-->
<!--          </div>-->

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="true"
            :pagination-position="bottom"
            :pagination-simple="true"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
        >

          <template>

            <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="short" label="Kurzbezeichnung" searchable sortable width="100" v-slot="props">
              {{ props.row.short }}
            </b-table-column>

            <b-table-column field="text" label="Text" searchable sortable v-slot="props">
              {{ props.row.text }}
            </b-table-column>

            <b-table-column label="Aktionen"  v-slot="props">

              <b-button type="is-primary" @click="$emit('addBoilerplate', props.row.text)"><i class="fa fa-plus"></i>&nbsp;Hinzufügen</b-button>

            </b-table-column>
          </template>
        </b-table>

<!--        <b-input type="textarea" v-model="composition"></b-input>-->

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Schließen"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";
import ContactPerson from "@/components/ContactPerson";

export default {
  name: 'ContactPersons',
  data() {
    return {
      data: ['composition'],
      isFetching: true
    }
  },
  props: ['relation_id', 'relation_type'],
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("boilerplates")
          .then(response => (this.data = response.data.data));
      this.isFetching = false;
    },
    confirm() {
      this.$emit('confirm', this.value)
      this.$emit('close')
    },
    addContactPerson: function() {
      this.$buefy.modal.open({
        component: ContactPerson,
        parent: this,
        props: {
          // 'default_unit': this.default_unit,
          'id': 'new',
          'relation_type': this.relation_type,
          'relation_id': this.relation_id
        },

        onCancel: function () {
          this.fetchData()
          console.log()
        },
        onConfirm: function () {
          this.fetchData()
          console.log()
        },
        events: {
          'close': this.fetchData(),
          'confirm': this.fetchData()
        }
      })
      this.fetchData()
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>