<template>
  <div class="article">

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent">
            <article class="tile is-child notification is-primary">
              <p class="title">Dienstleistung</p>
              <p class="subtitle">{{ title }}</p>
            </article>
          </div>
        </div>
      </div>
    </div>



    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child notification box">
              <b-field label="ID" :hidden="isNew">
                <b-input v-model="data.id" disabled="disabled"></b-input>
              </b-field>

<!--              <b-field label="Artikelnummer">-->
<!--                <b-input v-model="data.article_nr"></b-input>-->
<!--              </b-field>-->

              <b-field label="Basis-Einheit">
                <UnitPicker v-model="data.default_unit" @change="update_ids" />
              </b-field>

              <b-field label="Standard-USt.">
                <VatTypePicker v-model="data.default_vat" @change="update_ids" />
              </b-field>

              <b-field label="Preis / VK (€)">
                <b-numberinput controls-position="compact" controls-rounded v-model="data.sales_price" step="0.01"></b-numberinput>
              </b-field>

              <b-field label="Kalkulatorische Kosten / EK (€)">
                <b-numberinput controls-position="compact" controls-rounded v-model="data.cost_price" step="0.01"></b-numberinput>
              </b-field>

              <b-field label="Skonto">
                <b-checkbox v-model="data.cash_discountable">
                  skontierfähig
                </b-checkbox>
              </b-field>

            </article>

          </div>

          <div class="tile is-parent is-16">
            <article class="tile is-child notification box">

              <b-field label="Bezeichnung">
                <b-input v-model="data.name" required></b-input>
              </b-field>

              <b-field label="Beschreibung">
                <b-input type="textarea" v-model="data.description"></b-input>
              </b-field>

              <b-field label="Beschreibung bei Zusammenführung mit Artikel">
                <b-input type="textarea" v-model="data.article_service_description"></b-input>
              </b-field>

              <b-field label="Interne Notiz">
                <b-input type="textarea" v-model="data.internal_note"></b-input>
              </b-field>

            </article>
          </div>
        </div>
      </div>

    </div>

    <div class="tile is-ancestor">


      <div class="tile is-parent">
        <article class="tile is-child notification box">
          <div class="field is-grouped">
            <div class="control">
              <router-link class="" to="/articles">
                <button class="button is-secondary">Zurück</button>
              </router-link>
            </div>
            <div class="control" :hidden="isNew">
              <button type="submit" class="button is-link" @click="update">Speichern</button>
            </div>
            <div class="control" :hidden="!isNew">
              <button type="submit" class="button is-link" @click="create">Erstellen</button>
            </div>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import UnitPicker from "@/components/UnitPicker";
import apiservice from "@/services/apiservice";
import VatTypePicker from "@/components/VatTypePicker";


export default {
  name: 'Service',
  props: {
  },
  data() {
            return {
              data: {sales_price: 1, cost_price:0},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name + " (" + this.data.id + ")"
      }
    },
    price_delta() {
      return (this.data.sales_price - this.data.cost_price).toPrecision(2)
    }
  },
  components: {
    UnitPicker,  VatTypePicker
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('services/' + this.$route.params.id)
          .then(response => (this.data = response.data.data));
    },
    update_ids: function () {
      this.data.default_unit_id = (this.data.default_unit ? this.data.default_unit.id : null);
      this.data.default_vat_id = (this.data.default_vat ? this.data.default_vat.id : null);
    },
    update: function() {
      let self = this

      this.update_ids();

      apiservice()
          .post('services/', this.data)
          .then(function (response) {
              Toast.open({
                message: 'Dienstleistung erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              console.log(response);
            self.$router.push("/services");
            })
            .catch(function (error) {
              Dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error.message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
            });
    },
    create: function() {
      this.data.id = "new"
      let self = this

      apiservice().post('services/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Dienstleistung erfolgreich hinzugefügt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/services/"+response.data.data.id);
          })
          .catch(function (error) {
            Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error.message,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
            console.log(error);
          });
    }
  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
