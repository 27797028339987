<template>


      <span>
        <router-link :to="'/articles/' + id" target='_blank'>
                {{ name }}
              </router-link><br />
              <span v-if="orderout_use_series">
                <b-tag type="is-primary">
                  {{manufacturer}}
                  {{series}}
                  {{color}}
                  {{format_width}} {{format_width_unit_symbol}}
                  {{format_length}} {{format_length_unit_symbol}}
                  <span v-if="format_depth">
                    {{format_depth}} {{format_depth_unit_symbol}}
                  </span>
                  {{anti_slip_property}}
                  {{rectified ? "rekt." : ""}}
                </b-tag>
              </span>
              <span v-if="charge && (charge.length)>0">
            <b-tag>{{ charge }}</b-tag></span>
      </span>


</template>

<script>


export default {
  name: 'ArticleTags',
  data() {
    return {

    }
  },
  props: ['id', 'name', 'orderout_use_series', 'manufacturer', 'series', 'color', 'format_width',
    'format_width_unit_symbol', 'format_length', 'format_length_unit_symbol', 'format_depth', 'format_depth_unit_symbol',
  'anti_slip_property', 'rectified', 'charge'],
  watch: {


  },
  computed: {

  },
  methods: {
  },

  beforeMount(){

  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  /*.highlighted {*/
  /*  font-weight: bold;*/
  /*  background: #8c67ef;*/
  /*  color: #fff;*/
  /*}*/
</style>