<template>
  <div class="document">

    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child notification is-primary">
          <p class="title">
            {{ document_type == 'quote' ? "Angebot" :
                document_type == 'order' ? "Auftrag" :
                    document_type == 'deliverynote' ? "Lieferschein" :
                        document_type == 'pickingnote' ? "Kommissionierschein" :
                            document_type == 'creditnote' ? "Gutschrift" :
                              document_type == 'invoice' ? "Rechnung" : document_type }}
            {{ data.document_nr ? data.document_nr : "" }}
            </p>
          <p class="subtitle">{{ data.customer.name }} {{ data.project ? " (" + data.project.name + ")" : "" }}</p>
          <div class="content">
            <!-- Content -->
          </div>
        </article>



      </div>
    </div>
















    <div class="tile is-vertical is-16" v-if="document_type == 'invoice' && this.data.finalized">
      <div class="tile">


        <div class="tile is-parent is-8">
          <article class="tile is-child notification">
            <p class="title">Zahlungen
              <b-tag v-if="open_amount_to_pay<=0" type="is-success">vollständig bezahlt</b-tag>
              <b-tag v-if="open_amount_to_pay>0 && total_paid >0" type="is-warning">{{formatCurrency(open_amount_to_pay)}} offen</b-tag>
              <b-tag v-if="total_paid == 0" type="is-danger">offen</b-tag>
            </p>
            <div class="content">

              <b-table
                  :data="data.payment_history"
                  ref="table"
                  default-sort="date"
                  default-sort-direction="asc"
                  striped>


                <b-table-column field="date" label="Datum" sortable width="200" v-slot="props">

                  {{ new Date(props.row.date).toLocaleDateString() }}

                </b-table-column>

                <b-table-column label="Betrag" field="amount" sortable width="200" v-slot="props">
                  {{ formatCurrency(props.row.amount) }}
                </b-table-column>

                <b-table-column label="Zahlungsmethode" field="method" sortable width="200" v-slot="props">
                  {{ props.row.method }}
                </b-table-column>


                <b-table-column field="note" label="Notiz"  v-slot="props">
                  {{ props.row.note }}
                </b-table-column>

              </b-table>


            </div>
          </article>
        </div>


        <div class="tile is-parent">
          <article class="tile is-child notification">
            <p class="title">Zahlungseingang</p>
            <div class="content">
              <b-field label="Datum:">
                <DatePicker v-model="newPayment.date"></DatePicker>
              </b-field>

              <b-field grouped>
                <b-field label="Betrag:">
                  <b-input v-model="newPayment.amount" type="number" step=".01"></b-input>
                </b-field>

                <b-field label="Methode:">
                  <b-select v-model="newPayment.method">
                    <option value="sepa_transfer" selected>Überweisung</option>
                    <option value="sepa_direct_debit">Lastschrift</option>
                    <option value="card">Karte</option>
                    <option value="cash">Bar</option>
                  </b-select>
                </b-field>
              </b-field>

              <b-field label="Notiz:">
                <b-input v-model="newPayment.note"></b-input>
              </b-field>

                <b-button type="is-primary is-wide" @click="addPayment">Buchen</b-button>


            </div>
          </article>
        </div>
      </div>
    </div>





    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification">
              <p class="title">Kunde</p>

              <CustomerPicker v-model="data.customer" :disabled="data.finalized"></CustomerPicker>

              <p class="subtitle">Anschrift</p>

              <div v-if="!data.finalized">
                <b-field>
<!--                  <b-input placeholder="Anrede" v-model="data.to_salutation" ></b-input>-->
                  <SalutationPicker v-model="data.to_salutation" :disabled="data.finalized" />
                </b-field>

                <b-field>
                  <b-input placeholder="Name" v-model="data.to_name" :disabled="data.finalized"></b-input>
                </b-field>

                <b-field>
                  <b-input placeholder="Anschrift" v-model="data.to_street" :disabled="data.finalized"></b-input>
                </b-field>

                <b-field>
                  <b-input placeholder="PLZ" v-model="data.to_zip" :disabled="data.finalized"></b-input>
                  <b-input placeholder="Ort" v-model="data.to_place" :disabled="data.finalized"></b-input>
                </b-field>

                <b-field>
                  <b-input placeholder="Land" v-model="data.to_country" :disabled="data.finalized"></b-input>
                </b-field>
              </div>
              <div v-if="data.finalized" class="card">
                <div class="card-content">
                  {{ data.to_salutation }}<br />
                  <b>{{ data.to_name }}</b><br />
                  {{ data.to_street }}<br />
                  {{ data.to_plz }} {{data.to_place}}<br /><br />
                  {{ data.to_country }}
                </div>
              </div>
            </article>

          </div>
          <div class="tile is-parent">
            <article class="tile is-child notification">
              <p class="title">Projekt</p>
              <ProjectPicker v-model="data.project" :disabled="data.finalized"></ProjectPicker>

              <div class="card" v-if="data.project">
                <div class="card-content">
                  <b>{{ data.project.name }}</b><br />
                  {{ data.project.street }}<br />
                  {{ data.project.zip }} {{data.project.place}}<br />
                  {{ data.project.country }}
                </div>
              </div>

              <b-field class="mt-4">
                <b-switch size="is-medium" v-model="data.use_delivery_address" :disabled="data.finalized">Lieferadresse</b-switch>
              </b-field>

              <div v-if="data.use_delivery_address">
                <div v-if="!data.finalized">
                  <b-field>
<!--                    <b-input placeholder="Anrede" v-model="data.delivery_salutation" ></b-input>-->
                    <SalutationPicker v-model="data.delivery_salutation" :disabled="data.finalized"/>
                  </b-field>

                  <b-field>
                    <b-input placeholder="Name" v-model="data.delivery_name" :disabled="data.finalized"></b-input>
                  </b-field>

                  <b-field>
                    <b-input placeholder="Anschrift" v-model="data.delivery_street" :disabled="data.finalized"></b-input>
                  </b-field>

                  <b-field>
                    <b-input placeholder="PLZ" v-model="data.delivery_zip" :disabled="data.finalized"></b-input>
                    <b-input placeholder="Ort" v-model="data.delivery_place" :disabled="data.finalized"></b-input>
                  </b-field>

                  <b-field>
                    <b-input placeholder="Land" v-model="data.delivery_country" :disabled="data.finalized"></b-input>
                  </b-field>
                </div>
                <div v-if="data.finalized" class="card">
                  <div class="card-content">
                    {{ data.delivery_salutation }}<br />
                    <b>{{ data.delivery_name }}</b><br />
                    {{ data.delivery_street }}<br />
                    {{ data.delivery_plz }} {{data.delivery_place}}<br /><br />
                    {{ data.delivery_country }}
                  </div>
                </div>
              </div>



            </article>
          </div>

          <div class="tile is-parent">
            <article class="tile is-child notification">
              <div class="content">
                <p class="title">Sonstiges</p>

                <b-field label="Datum:">

                  <DatePicker v-model="data.date" :disabled="data.finalized"></DatePicker>

                </b-field>

                <b-field label="Leistungsdatum/-zeitraum:" v-if="document_type == 'invoice'">
                  <b-input v-model="data.supply_time" v-if="!data.finalized"></b-input>
                  <span v-if="data.finalized">{{data.supply_time}}</span>
                </b-field>

                <div :hidden="document_type != 'invoice'">

                  <div :hidden="data.finalized">
                    <p class="subtitle">Rechnungstyp</p>
                    <!--                    <InvoiceTypePicker v-model="data.type" :hidden="data.finalized"></InvoiceTypePicker>-->
                    <b-field>
                      <b-radio-button v-model="data.invoice_type"
                                      native-value="single"
                                      :disabled="data.invoice_type=='final' && data.interim_invoices.length>0"
                                      type="is-primary is-light is-outlined">
                        <!--                        <b-icon icon="close"></b-icon>-->
                        <span>Einzel</span>
                      </b-radio-button>

                      <b-radio-button v-model="data.invoice_type"
                                      native-value="interim"
                                      :disabled="data.invoice_type=='final' && data.interim_invoices.length>0"
                                      type="is-warning is-light is-outlined">
                        <!--                        <b-icon icon="check"></b-icon>-->
                        <span>Abschlag</span>
                      </b-radio-button>

                      <b-radio-button v-model="data.invoice_type"
                                      native-value="final"
                                      type="is-success is-light is-outlined">
                        <!--                        <b-icon icon="check"></b-icon>-->
                        <span>Schluss</span>
                      </b-radio-button>

                    </b-field>
                  </div>
                  <div :hidden="!data.finalized">
                    <b-tag class="is-success is-large" v-if="data.invoice_type == 'final'">Schlussrechnung</b-tag>
                    <b-tag class="is-primary is-large" v-if="data.invoice_type == 'single'">Einzelrechnung</b-tag>
                    <b-tag class="is-warning is-large" v-if="data.invoice_type == 'interim'">Abschlagsrechnung</b-tag>
                    <div class="control" v-if="data.invoice_type == 'interim' && data.part_of_final_invoice_id">
                      <b-tooltip label="Schlussrechnung ansehen" position="is-right">
                        <b-button type="is-primary is-light is-small" @click="openInvoice(data.part_of_final_invoice_id)"><i class="fa fa-eye"></i> Schlussrechnung</b-button>
                      </b-tooltip>
                    </div>

                  </div>
                </div>

                <p class="subtitle">Sachbearbeiter</p>
                <b-field>
                  <UserPicker v-model="data.responsible_person" v-if="!data.finalized"></UserPicker>
                  <span v-if="data.finalized">{{data.responsible_person.full_name}}</span>
                </b-field>

                <b-collapse :open="false" position="is-bottom" aria-id="contentIdForA11y1">
                  <template #trigger="props">
                    <a aria-controls="contentIdForA11y1">
                      <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                      {{ !props.open ? 'Sachbearbeiter-Details...' : 'Details schließen...' }}
                    </a>
                  </template>

                  <div class="card-content">
                    <div class="content">


                      <b-field label="Name:">
                        <b-input v-model="data.responsible_person_name" v-if="!data.finalized"></b-input>
                        <span v-if="data.finalized">{{data.responsible_person_name}}</span>
                      </b-field>

                      <b-field label="Telefon:">
                        <b-input v-model="data.responsible_person_phone" v-if="!data.finalized"></b-input>
                        <span v-if="data.finalized">{{data.responsible_person_phone}}</span>
                      </b-field>

                      <b-field label="Mobil:">
                        <b-input v-model="data.responsible_person_mobile" v-if="!data.finalized"></b-input>
                        <span v-if="data.finalized">{{data.responsible_person_mobile}}</span>
                      </b-field>

                      <b-field label="Standort:">
                        <b-input v-model="data.responsible_person_location" v-if="!data.finalized"></b-input>
                        <span v-if="data.finalized">{{data.responsible_person_location}}</span>
                      </b-field>

                      <b-field label="E-Mail:">
                        <b-input v-model="data.responsible_person_email" v-if="!data.finalized"></b-input>
                        <span v-if="data.finalized">{{data.responsible_person_email}}</span>
                      </b-field>
                    </div>
                  </div>
                </b-collapse>

                <p class="subtitle">Zahlungsziel</p>

                <div class="content">
                  <PaymentConditionPicker v-model="data.payment_condition" v-on:change="data.payment_condition_text = data.payment_condition.text" :hidden="data.finalized"></PaymentConditionPicker>
                  <div :hidden="!data.finalized"><h6>{{data.payment_condition.short}}:</h6><p>{{data.payment_condition_text}}</p></div>
                  <b-field :hidden="data.finalized">
                    <b-input type="textarea" v-model="data.payment_condition_text" :disabled="data.finalized"></b-input>
                  </b-field>
                </div>

                <b-field label="Rabatt(%)" horizontal>
                  <b-numberinput controls-position="compact" v-model="data.sales_discount" step="0.1" :disabled="data.finalized"></b-numberinput>
                </b-field>

                <b-field label="Skonto(%)" horizontal v-if="document_type != 'creditnote'">
                  <b-numberinput controls-position="compact" v-model="data.cash_discount" step="0.1" :disabled="data.finalized"></b-numberinput>
                </b-field>


<!--                <p class="subtitle">Besonderheiten</p>-->
<!--                <b-field>-->
<!--                  <b-checkbox v-model="reverse_charge" disabled>-->
<!--                    Steuerschuldumkehr nach § 13b UStG-->
<!--                  </b-checkbox>-->
<!--                </b-field>-->

<!--                <div class="content">-->
<!--                  <div class="block">-->
<!--                    <div class="field">-->
<!--                      <b-radio v-model="ust"-->
<!--                               name="ust"-->
<!--                               native-value="1">-->
<!--                        Reguläre Umsatzsteuer-->
<!--                      </b-radio>-->
<!--                    </div>-->
<!--                    <div class="field">-->
<!--                      <b-radio v-model="ust"-->
<!--                               name="ust"-->
<!--                               native-value="2">-->
<!--                        Innergemeinschaftliche Leistung/Lieferung-->
<!--                      </b-radio>-->
<!--                    </div>-->
<!--                    <div class="field">-->
<!--                      <b-radio v-model="ust"-->
<!--                               name="ust"-->
<!--                               native-value="3">-->
<!--                        Steuerschuldumkehr nach § 13b UStG (Reverse Charge)-->
<!--                      </b-radio>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->

                <p class="subtitle">Rechnungssumme</p>

                <table class="table">
                  <tr>
                    <td><b>Summe netto</b></td>
                    <td style="text-align: right">{{ formatCurrency(total_net) }}</td>
                  </tr>
                  <tr style="" :hidden="!data.sales_discount">
                    <td><b>Rabatt ({{data.sales_discount}}%)</b></td>
                    <td style="text-align: right">-{{ formatCurrency(total_discount) }}</td>
                  </tr>
                  <tr style="border-bottom: solid 2px">
                    <td><b>Umsatzsteuer</b></td>
                    <td style="text-align: right">{{ formatCurrency(total_vat) }}</td>
                  </tr>
                  <tr style="border-bottom: double">
                    <td><b>Summe brutto</b></td>
                    <td style="text-align: right"><b>{{ formatCurrency(total_gross) }}</b></td>
                  </tr>
                  <tr :hidden="!data.cash_discount">
                    <td>Skonto ({{data.cash_discount}}%)</td>
                    <td style="text-align: right">{{ formatCurrency(total_cash_discount * -1)}}</td>
                  </tr>
                  <tr :hidden="!data.cash_discount">
                    <td>Summe abzgl. Skonto</td>
                    <td style="text-align: right">{{ formatCurrency(total_gross - total_cash_discount) }}</td>
                  </tr>
                </table>

              </div>
            </article>
          </div>
        </div>

        <div class="tile is-vertical is-16">
          <div class="tile">


              <div class="tile is-parent is-8">
                <article class="tile is-child notification">
<!--                  <p class="title">Positionen</p>-->
                  <div class="content">
                    <b-field>
                      <b-input type="textarea" v-model="data.free_text" :disabled="data.finalized"></b-input>
                      <template #label>
                        Freitext
                        <b-button size="is-small" icon-left="plus" class="ml-5" @click="openBoilerplateComposer" v-if="!data.finalized">Textbausteine</b-button>
                      </template>
                    </b-field>

                  </div>
                </article>
              </div>


            <div class="tile is-parent">
              <article class="tile is-child notification">
<!--                <p class="title">Positionen</p>-->
                <div class="content">
                  <b-field label="Interne Notiz">
                    <b-input type="textarea" v-model="data.internal_note"></b-input>

                  </b-field>

                </div>
              </article>
            </div>
          </div>
        </div>






        <div class="tile is-parent">
          <article class="tile is-child notification">

<!--            <div class="columns">-->
<!--              <div class="column">-->
                <p class="title">Positionen</p>
<!--              </div>-->
<!--              <div class="column">-->
<!--                <button class="button is-primary"><i class="fa fa-plus"></i>&nbsp; Artikel</button> &nbsp;-->
<!--                <button class="button is-primary"><i class="fa fa-plus"></i>&nbsp; Freier Eintrag</button> &nbsp;-->
<!--                <button class="button is-primary"><i class="fa fa-plus"></i>&nbsp; Gruppe</button> &nbsp;-->
<!--              </div>-->
<!--            </div>-->
            <!--<p class="subtitle">Aligned with the right tile</p>-->
            <div class="content">

              <DocumentLines v-model="data.document_lines" :document_data="data" :disabled="data.finalized" v-on:requestupdate="fetchData" :document_type="document_type"></DocumentLines>
<!--              <DocumentLine :line="data.document_lines[0]"></DocumentLine>-->
<!--              <InvoiceEntry v-bind:product="productData[0]" />-->
<!--              <InvoiceEntry v-bind:product="productData[2]" />-->
<!--              <InvoiceEntry v-bind:product="productData[1]" />-->


            </div>
          </article>
        </div>



        <div class="tile is-parent" v-if="document_type=='invoice' && data.invoice_type=='final'">
          <article class="tile is-child notification">

            <p class="title">Abschlagsrechnungen</p>

            <div class="content">

              <b-field grouped group-multiline v-if="!data.finalized">
                <div class="control">
                  <button class="button is-primary" @click="addInterimInvoice()"><i class="fa fa-plus"></i>&nbsp; Abschlagsrechnung verknüpfen</button>
                </div>
              </b-field>

              <b-table
                  :data="data.interim_invoices"
                  ref="table"
                  :show-detail-icon="false"
                  :row-class="(row, index) => row.line_type === 'text' && 'is-textline'"
                  default-sort="line_nr"
                  default-sort-direction="asc"
                  striped>

<!--                <b-table-column field="action" label="" width="10" v-slot="props" v-if="!disabled || true">-->

<!--                  <a v-if="!disabled" @click="removeLine(props.row)" style="text-decoration: none; font-size: 0.8em;" class="line-remove"><i class="fa fa-times"></i></a><br /><br />-->

<!--                </b-table-column>-->


<!--                <b-table-column field="line_nr" label="Pos." width="70" numeric sortable v-slot="props">-->
<!--                {{props.row}}-->
<!--                </b-table-column>-->

                <b-table-column field="invoice_nr" label="Rechnungs-Nr." numeric width="100" v-slot="props">
                  {{props.row.document_nr}}
                </b-table-column>


                <b-table-column field="date" label="Datum" numeric width="100" v-slot="props">
                  {{dateFormatter(props.row.date)}}
                </b-table-column>

                <b-table-column field="name" label="Kunde" numeric width="100" v-slot="props">
                  {{props.row.customer.name}}
                </b-table-column>

                <b-table-column field="project" label="Projekt" numeric width="100" v-slot="props">
                  {{props.row.project ? props.row.project.name : "-"}}
                </b-table-column>

                <b-table-column field="total_net" label="Summe" numeric width="100" v-slot="props">
                  {{formatCurrency(props.row.total)}}<br />
                  <b>{{formatCurrency(props.row.total)}}</b>
                </b-table-column>

                <b-table-column field="action" label="" numeric width="100" v-slot="props">
                  <b-field>
                    <div class="control">
                      <b-tooltip label="Rechnung öffnen" position="is-right">
                        <b-button type="is-primary is-light" @click="openInvoice(props.row.id)"><i class="fa fa-eye"></i></b-button>
                      </b-tooltip>
                    </div>
                    <div class="control" v-if="!data.finalized">
                      <b-tooltip label="Abschlagsrechnung entfernen" position="is-right">
                        <b-button type="is-danger is-light" @click="removeInterimInvoice(props.row.id)"><i class="fa fa-times"></i></b-button>
                      </b-tooltip>
                    </div>
                  </b-field>
                </b-table-column>


              </b-table>

            </div>
          </article>
        </div>


      </div>

    </div>

    <div class="tile is-parent">
      <article class="tile is-child notification box">
        <div class="field is-grouped">
          <div class="control">
            <router-link class="" :to="'/documents/'+document_type">
              <b-button class="button is-secondary">Zurück</b-button>
            </router-link>
          </div>
          <div class="control" :hidden="data.finalized">
            <b-button class="button is-secondary" @click="getPdf()"><i class="fa fa-print"></i> Drucken (Vorschau)</b-button>
          </div>
          <div class="control" :hidden="isNew || data.finalized">
            <b-button class="button is-link" @click="update"><i class="fa fa-save"></i> Speichern</b-button>
          </div>
<!--          <div class="control" :hidden="!isNew || data.finalized">-->
<!--            <button class="button is-link" @click="create"><i class="fa fa-plus"></i> Erstellen</button>-->
<!--          </div>-->
          <div class="control" :hidden="isNew || data.finalized">
            <b-button class="button is-success" @click="finalize"><i class="fa fa-lock"></i> Abschließen</b-button>
          </div>
          <div class="control" :hidden="!data.finalized">
            <b-button class="button is-danger" @click="notImplemented"><i class="fa fa-trash"></i> Stornieren</b-button>
          </div>
          <div class="control" :hidden="!data.finalized">
            <b-button class="button is-primary" @click="getPdf()"><i class="fa fa-print"></i> Drucken</b-button>
          </div>
          <div class="control" :hidden="!data.finalized">
            <b-button class="button is-primary is-light" @click="getPdf(template='noprice')"><i class="fa fa-print"></i> Drucken (ohne Preise)</b-button>
          </div>


        </div>
      </article>
    </div>

  <b-loading :active="isLoading" :is-full-page="true" :can-cancel="false" />
  </div>
</template>

<script>

// import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import apiservice from "@/services/apiservice";
import PaymentConditionPicker from "@/components/PaymentConditionPicker";
// import DocumentLine from "@/components/DocumentLine";
// import InvoiceTypePicker from "@/components/InvoiceTypePicker";
import DocumentLines from "@/components/DocumentLines";
import CustomerPicker from "@/components/CustomerPicker";
import ProjectPicker from "@/components/ProjectPicker";
import UserPicker from "@/components/UserPicker";
import DatePicker from "@/components/DatePicker";
import BoilerplateComposer from "@/components/BoilerplateComposer";
import SalutationPicker from "@/components/SalutationPicker";
import {isEqual} from "lodash";
import InterimInvoicePickerPrompt from "@/components/InterimInvoicePickerPrompt";

export default {
  name: 'Document',
  props: ['document_type'],
  data() {
            return {
              data: {},
              dataDump: null,
              isNew: null,
              isLoading: true,
              changed: false,
              newPayment: {date:Date(), amount:null, method:"sepa_transfer", invoice_id: this.$route.params.id}
            }
  },
  computed: {

    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.customer.name
      }
    },
    total_net() {
      let total = 0;
      for (let i = 0; i < this.data.document_lines.length; i++) {
        if (this.data.document_lines[i].line_type != 'text' && this.data.document_lines[i].line_type != 'text2') {
          total += this.data.document_lines[i].amount * this.data.document_lines[i].single_price
        }
      }
      return this.finRound(total);
    },
    total_net_discounted() {
      return this.finRound(this.total_net - this.total_discount);
    },
    total_discount() {
      return this.finRound(this.total_net*this.data.sales_discount/100);
    },
    total_cash_discount() {
      let sum = 0;
      for (let i = 0; i < this.data.document_lines.length; i++) {
        if (this.data.document_lines[i].line_type != 'text' && this.data.document_lines[i].line_type != 'text2' && this.data.document_lines[i].vat) {
          if (this.data.document_lines[i].cash_discountable) {
            sum += parseFloat(this.lineTotal(this.data.document_lines[i].amount, this.data.document_lines[i].single_price)) * (1+this.data.document_lines[i].vat.rate/100 || 1) * (1-this.data.sales_discount/100) * this.data.cash_discount/100
          }
        }
      }

      return sum;
    },

    //TODO vat und total gross mit rabatt berechnen
    total_vat() {
      // let total = 0;
      // for (let i = 0; i < this.data.document_lines.length; i++) {
      //   if (this.data.document_lines[i].line_type != 'text' && this.data.document_lines[i].vat) {
      //     console.log(i)
      //     total += this.lineTotal(this.data.document_lines[i].amount, this.data.document_lines[i].single_price) * this.data.document_lines[i].vat.rate / 100
      //   }
      // }
      // return total;

      let sums = [];
      for (let i = 0; i < this.data.document_lines.length; i++) {
        if (this.data.document_lines[i].line_type != 'text' && this.data.document_lines[i].line_type != 'text2' && this.data.document_lines[i].vat) {
          if (!sums[this.data.document_lines[i].vat.rate]) {
            sums[this.data.document_lines[i].vat.rate] = 0
          }
          sums[this.data.document_lines[i].vat.rate] += parseFloat(this.lineTotal(this.data.document_lines[i].amount, this.data.document_lines[i].single_price) * (1-this.data.sales_discount/100))
        }
      }

      let total = 0;
      for (let vat in sums) {
        total += (sums[vat] * vat / 100)
      }

      return total;
    },
    total_gross() {
      return (this.total_net_discounted*100 + this.total_vat*100)/100
    },
    open_amount_to_pay() {
      return (this.total_gross - this.total_paid).toFixed(2)
    },
    total_paid() {
      let totalSum = 0;
      if (this.data.payment_history) {
        for (let i = 0; i < this.data.payment_history.length; i++) {
          totalSum += this.data.payment_history[i].amount;
        }
      }
      return totalSum.toFixed(2)

    },
    documentTypeReadable() {
      return this.document_type == 'quote' ? "Angebot" :
          this.document_type == 'order' ? "Auftrag" :
              this.document_type == 'deliverynote' ? "Lieferschein" :
                  this.document_type == 'invoice' ? "Rechnung" : this.document_type;
    }

  },
  components: {
    SalutationPicker,
    // InvoiceTypePicker,
    // DocumentLine,
    DocumentLines,
    PaymentConditionPicker,
    CustomerPicker,
    ProjectPicker,
    UserPicker,
    DatePicker
  },
  methods: {
    isChanged: function() {
      //return (JSON.parse(JSON.stringify(this.data)).localCompare(this.dataDump))
      // return JSON.parse(JSON.stringify(this.data).localeCompare(this.dataDump))
      let r = !isEqual(this.data, this.dataDump)
      console.log(r)
      return r
    },
    lineTotal: function(amount, single_price){
      return (Math.round(amount * single_price * 100) / 100).toFixed(2);
    },
    finRound: function(amount){
      return (Math.round(amount * 100) / 100).toFixed(2);
    },
    fetchData: function() {
      let self = this
      this.isLoading = true
      apiservice()
          .get('documents/' + this.document_type + '/' + this.$route.params.id)
          .then(response => {
            this.data = response.data.data
            this.dataDump = JSON.parse(JSON.stringify(response.data.data))
            // this.data.date = new Date(this.data.date) todo check back data
            // self.data.date = this.dateISOFormatter(new Date(self.data.date))
            this.newPayment.amount = this.open_amount_to_pay
            self.isLoading = false
          })
          .catch(function (error) {
            self.isLoading = false
            this.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    update: function(finalize = false) {


      this.data.customer_id = this.data.customer ? this.data.customer.id : null;
      this.data.payment_condition_id = this.data.payment_condition ? this.data.payment_condition.id : null;
      this.data.project_id = this.data.project ? this.data.project.id : null;
      this.data.responsible_person_id = this.data.responsible_person ? this.data.responsible_person.id : null;

      for (let i=0; i<this.data.document_lines.length; i++) {
        if (this.data.document_lines[i].vat){
          this.data.document_lines[i].vat_id = this.data.document_lines[i].vat.id
        }

        if (this.data.document_lines[i].unit){
          this.data.document_lines[i].unit_id = this.data.document_lines[i].unit.id
        }
      }

      //const sendData = JSON.parse(JSON.stringify(this.data.data));
      //sendData.date = this.dateISOFormatter(this.data.date)

      this.isLoading = true;

      let self = this;
      apiservice()
          .post('documents/' + this.document_type + '/update', this.data)
          .then(function (response) {
              self.$buefy.toast.open({
                message: 'Dokument erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              self.data = response.data.data

              self.dataDump = JSON.parse(JSON.stringify(response.data.data))
              // self.data.date = new Date(self.data.date) todo check back date
              // self.data.date = this.dateISOFormatter(new Date(self.data.date))
              self.isLoading = false;
              console.log(response);
            })
            .catch(function (error) {
              self.isLoading = false;
              self.$buefy.dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
              if (finalize)
                this.data.finalized = false;
            });
    },
    addPayment: function() {
      let self = this;

      apiservice().post("documents/invoice/add_payment/", this.newPayment)
          .then(function (response) {
            self.$buefy.toast.open({
              message: 'Erfolgreich',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response)
            self.fetchData()
            self.newPayment.note = ""
          })
          .catch(function (error) {
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    finalize: function() {
      this.$buefy.dialog.confirm({
        title: 'Dokument abschließen?',
        message: '<b>Möchten Sie dieses Dokument abschließen?</b><br /><br />Es können danach keine Änderungen mehr an dem Dokument vorgenommen werden.',
        confirmText: 'Ja, abschließen',
        cancelText: 'Nein',
        type: 'is-primary',
        icon: 'lock',
        hasIcon: true,
        onConfirm: () => {
          this.data.finalized = true;
          this.update(true);

        }
      })
    },
    openInvoice: function(id){
      // this.$router.push({ path: `/documents/invoice/${id}` })
      let routeData = this.$router.resolve({ path: `/documents/invoice/${id}` })
      window.open(routeData.href, '_blank');
    },
    removeInterimInvoice: function(interim_id){

        let self = this;
        apiservice().post('documents/invoice/remove_interim/'+ this.data.id + '/' + interim_id)
            .then(function (response) {

              self.$buefy.toast.open({
                message: 'Abschlagsrechnung entfernt',
                position: 'is-bottom',
                type: 'is-success'
              });
              console.log(response);

              self.update()
            })
            .catch(function (error) {
              self.$buefy.dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error.message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
            });

    },
    addInterimInvoice: function(){

      // let s = this;
      this.$buefy.modal.open({
        component: InterimInvoicePickerPrompt,
        parent: this,
        props: {
          // "question": question
        },
        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {
            let self = this;
            apiservice().post('documents/invoice/add_interim/'+ this.data.id + '/' + value.id)
                .then(function (response) {

                  self.$buefy.toast.open({
                    message: 'Abschlagsrechnung mit dieser Rechnung verknüpft',
                    position: 'is-bottom',
                    type: 'is-success'
                  });
                  console.log(response);

                  self.update()
                })
                .catch(function (error) {
                  self.$buefy.dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error.message,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                  console.log(error);
                });
          }

        }
      })
    },
    dateFormatter: function(date) {
      let d = new Date(date)
      // return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}`
      return `${d.getDate()}.${d.getMonth()}.${d.getFullYear()}`
      // return d.toISOString()
    },
    dateISOFormatter: function (date) {
      if (!(date.getTime() === date.getTime()) || date == null)
        return null
      return date.toISOString().split("T")[0]
    },
    dateParser: function(date) {
      let d = new Date(date);
      return d
      // return `0${d.getMonth()}-0${d.getDate()}-${d.getFullYear()}`
    },
    dateCreator: function() {
      return new Date()
    },
    formatCurrency: function (value) {
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
    },
    notImplemented: function() {
      this.$buefy.toast.open({
        message: 'Aktuell nicht möglich.',
        position: 'is-bottom',
        type: 'is-danger'
      })

    }
    ,getPdf: function(template='default') {
      console.log(template)
      this.isLoading = true;
      let self = this;
      let filename = this.documentTypeReadable + "_" + (this.data.document_nr ? this.data.document_nr : "ENTWURF") + "_" + this.data.customer.name + ".pdf";
      apiservice()
          .get("documents/" + this.document_type + "/" + this.data.id + "/pdf/"+template, {responseType:'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            this.isLoading = false;
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })
    },
    openBoilerplateComposer: function() {
      let self = this
      this.$buefy.modal.open({
        component: BoilerplateComposer,
        parent: this,
        props: {

        },
        onCancel: function () {
          console.log()
        },
        events: {
          'addBoilerplate': value => {
            self.data.free_text += value + " "
            self.$buefy.toast.open({
              message: '"' + value + '" hinzugefügt.',
              position: 'is-bottom',
              type: 'is-success'
            })
          }
        }
      })
    },


    // BEGIN prevent dirty form to close
    confirmLeave() {
      return window.confirm('Es gibt ungespeicherte Änderungen. Diese werden beim Verlassen nicht gespeichert.')
    },

    confirmStayInDirtyForm() {
      return this.isChanged() && !this.confirmLeave()
    },

    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
      }
    },
    // END prevent dirty form to close

  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  },



  //// BEGIN prevent dirty form to close

  beforeRouteLeave (to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()){
      next(false)
    } else {
      // Navigate to next view
      next()
    }
  },

  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  //// END prevent dirty form to close
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
