<template>
  <div class="article">

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent">
            <article class="tile is-child notification is-primary">
              <p class="title">Artikel</p>
              <p class="subtitle">{{ title }}</p>
            </article>
          </div>
        </div>
      </div>
    </div>



    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child notification box">
              <b-field label="ID" :hidden="isNew">
                <b-input v-model="data.id" disabled="disabled"></b-input>
              </b-field>

<!--              <b-field label="Artikelnummer">-->
<!--                <b-input v-model="data.article_nr"></b-input>-->
<!--              </b-field>-->

              <b-field label="Herstellernummer">
                <b-input v-model="data.manufacturer_nr"></b-input>
              </b-field>

              <b-field label="EAN">
                <b-input v-model="data.ean"></b-input>
              </b-field>

              <b-field label="Artikelgruppe">
                <ArticleCategoryPicker v-model="data.article_category" />
              </b-field>

              <b-field label="Basis-Einheit">
                <UnitPicker v-model="data.default_unit" />
              </b-field>
              <b-button icon-left="ruler" icon-pack="fa" class="is-primary is-outlined" @click="openArticleUnits" :disabled="isNew">
                Alternativeinheiten
              </b-button>

              <b-field label="Standard-USt.">
                <VatTypePicker v-model="data.default_vat" />
              </b-field>

              <b-field label="Skonto">
                <b-checkbox v-model="data.cash_discountable">
                  skontierfähig
                </b-checkbox>
              </b-field>

              <b-field label="Bestellung via Serie">
                <b-checkbox v-model="data.orderout_use_series">
                  <b-tooltip label="Ist diese Option ausgewählt, werden bei Ausgangs-Bestellungen die Serieninformationen (Hersteller, Serie, Farbe, ...) genutzt und nicht die Bezeichnung." dashed multilined>
                    Bestellung via Serie
                  </b-tooltip>
                </b-checkbox>
              </b-field>

              <b-field label="Vereinfachter Lagerbestand">
                <b-checkbox v-model="data.is_simple_stockvalue" :disabled="!isNew">
                  <b-tooltip label="Ist diese Option ausgewählt, wird für den Bestandswert im Lager ein vereinfachter Durchschnittswert genutzt." dashed multilined>
                    Vereinfachter Lagerbestand
                  </b-tooltip>
                </b-checkbox>
              </b-field>

              <b-field label="Preis ausblenden">
                <b-checkbox v-model="data.hide_sales_price">
                  <b-tooltip label="Ist diese Option ausgewählt, wird an öffentlichen Stellen (z.B. Labels) kein Preis angezeigt." dashed multilined>
                    Preis ausblenden
                  </b-tooltip>
                </b-checkbox>
              </b-field>

              <b-field label="Archiviert">
                <b-checkbox v-model="data.archived">
                  Archiviert (ausgeblendet)
                </b-checkbox>
              </b-field>

              <b-field label="Im Sortiment seit">
                 <b-input v-model="data.intro_date" disabled=""></b-input>
              </b-field>

            </article>

          </div>

          <div class="tile is-parent is-16">
            <article class="tile is-child notification box">

<!--              <b-field label="Marketingname / Kundenbezeichnung">-->
<!--                <b-input v-model="data.marketing_name"></b-input>-->
<!--              </b-field>-->


              <div class="columns">
                <div class="column">
                  <b-field label="Hersteller">
                    <b-input v-model="data.manufacturer"></b-input>
                  </b-field>
                </div>

                <div class="column">

                  <b-field label="Serie">
                    <b-input v-model="data.series"></b-input>
                  </b-field>

                </div>
              </div>

              <div class="columns">
                <div class="column">
                  <b-field label="Farbe">
                    <b-input v-model="data.color"></b-input>
                  </b-field>
                </div>

                <div class="column">

                  <b-field label="Rutschhemmung">
                    <b-input v-model="data.anti_slip_property"></b-input>
                  </b-field>

                </div>

                <div class="column">

                  <b-field label="rektifiziert">
                    <b-checkbox v-model="data.rectified">
                      ja
                    </b-checkbox>
                  </b-field>

                </div>
              </div>

              <b-field label="Bezeichnung">
                <b-input v-model="data.name" required expanded></b-input>
                <b-tooltip :label="data.name + extendName()">
                  <b-button type="" @click="data.name = data.name + extendName()"><b-icon pack="mdi" icon="auto-fix" size="is-small"></b-icon></b-button>
                </b-tooltip>
              </b-field>

              <b-field label="Beschreibung">
                <b-input type="textarea" v-model="data.description"></b-input>
              </b-field>

              <b-field label="Interne Notiz">
                <b-input type="textarea" v-model="data.internal_note"></b-input>
              </b-field>


            </article>
          </div>
        </div>
      </div>

    </div>

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child notification box">
              <b-field label="">
<!--                <UnitPicker v-model="data.format_unit" group="length" />-->
              </b-field>

              <b-field label="Länge">
                <b-numberinput controls-position="compact" :controls="false" v-model="data.format_length" step="0.01"></b-numberinput>
                <UnitPicker v-model="data.format_length_unit" group="length" />
              </b-field>

              <b-field label="Breite">
                <b-numberinput controls-position="compact" :controls="false" controls-rounded v-model="data.format_width" step="0.01"></b-numberinput>
                <UnitPicker v-model="data.format_width_unit" group="length" />
              </b-field>

              <b-field label="Stärke / Tiefe">
                <b-numberinput controls-position="compact" :controls="false" controls-rounded v-model="data.format_depth" step="0.01"></b-numberinput>
                <UnitPicker v-model="data.format_depth_unit" group="length" />
              </b-field>

              <b-field label="Gewicht">
                <b-numberinput controls-position="compact" :controls="false" controls-rounded v-model="data.weight" step="0.01"></b-numberinput>
                <UnitPicker v-model="data.weight_unit" group="weight" />
              </b-field>

            </article>

          </div>


        <div class="tile is-parent is-4">
          <article class="tile is-child notification box">
<!--            <b-field label="Lieferant">-->
<!--&lt;!&ndash;              <SupplierPicker v-model="data.supplier" />&ndash;&gt;-->
<!--            </b-field>-->

<!--            <b-field label="EK-Preis (€)">-->
<!--              <b-numberinput controls-position="compact" controls-rounded v-model="data.cost_price" step="0.01"></b-numberinput>-->
<!--            </b-field>-->


<!--            <b-field label="Marge (€)">-->
<!--              <b-numberinput controls-position="compact" controls-rounded  v-model="price_delta" step="0.01" disabled></b-numberinput>-->
<!--            </b-field>-->

            <b-field label="VK-Preis netto (€)">
              <b-numberinput controls-position="compact" controls-rounded v-model="data.sales_price" step="0.01"></b-numberinput>
            </b-field>

            <b-field label="VK-Preis brutto (€)">
<!--              <b-numberinput controls-position="compact" controls-rounded v-model="data.sales_price" step="0.01"></b-numberinput>-->

              <b-tag type="is-primary is-light is-large">{{ sales_price_gross }}</b-tag>

            </b-field>

            <b-field>
            <b-button icon-left="people-carry" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="openArticleServices" :disabled="isNew">
              Zugehörige Dienstleistungen
            </b-button>
            </b-field>
            <b-field>
            <b-button icon-left="truck" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="openArticleSuppliers" :disabled="isNew">
              Lieferanten
            </b-button>
            </b-field>

          </article>
        </div>


          <div class="tile is-parent is-4">
            <article class="tile is-child notification box">
              <div class="title">Lager</div>
              <ArticleStock :article_id="this.$route.params.id" v-if="!this.isNew"></ArticleStock>
              <span v-if="this.isNew">Lagerfunktionen stehen erst zur Verfügung, wenn der Artikel angelegt (gespeichert) wurde.</span>
            </article>
          </div>

      </div>
















        <div class="tile is-ancestor">
          <div class="tile is-vertical is-16">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child box is-16">
                  <MediaViewer object_type="article" :object_id="this.$route.params.id" v-if="!this.isNew" />

                </article>

              </div>

            </div>
          </div>

        </div>


































































        <div class="tile is-parent">
          <article class="tile is-child notification box">
            <div class="field is-grouped">
              <div class="control">
                <router-link class="" to="/articles">
                  <button class="button is-secondary">Zurück</button>
                </router-link>
              </div>
              <div class="control" :hidden="isNew">
                <button type="submit" class="button is-link" @click="update">Speichern</button>
              </div>
              <div class="control" :hidden="!isNew">
                <button type="submit" class="button is-link" @click="create">Erstellen</button>
              </div>
              <div class="control" :hidden="isNew">
                <button type="submit" class="button is-light" @click="createLabel">In neues Label überführen</button>
              </div>
            </div>
          </article>
        </div>
      </div>


    </div>





















<!--      <div class="tile is-parent">-->
<!--        <article class="tile is-child notification is-primary">-->
<!--          <p class="title">Artikelkategorie: {{ title }}</p>-->
<!--          <div class="content">-->
<!--            &lt;!&ndash; Content &ndash;&gt;-->
<!--          </div>-->
<!--        </article>-->
<!--      </div>-->

<!--    <div class="tile is-parent">-->
<!--      <div class="tile is-vertical is-16">-->
<!--        <div class="tile">-->
<!--          <div class="tile is-parent is-vertical">-->
<!--            <article class="tile is-child notification">-->




<!--            </article>-->

<!--          </div>-->

<!--        </div>-->

<!--      </div>-->

<!--    </div>-->
  </div>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import ArticleCategoryPicker from "@/components/ArticleCategoryPicker";
// import SupplierPicker from "@/components/SupplierPicker";
import UnitPicker from "@/components/UnitPicker";
import apiservice from "@/services/apiservice";
import ArticleStock from "@/components/ArticleStock";
import VatTypePicker from "@/components/VatTypePicker";
import ArticleServices from "@/components/ArticleServices";
import ArticleUnits from "@/components/ArticleUnits";
import ArticleSuppliers from "@/components/ArticleSuppliers";
import MediaViewer from "@/components/MediaViewer";


export default {
  name: 'Article',
  props: {
  },
  data() {
            return {
              data: {sales_price: 1, cost_price:0},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name + " (" + this.data.id + ")"
      }
    },
    price_delta() {
      return (this.data.sales_price - this.data.cost_price).toPrecision(2)
    },
    sales_price_gross() {
      const euroFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })

      let vat = 0;
      if (this.data.default_vat != null) {
        vat = this.data.default_vat.rate;
      }
      console.log(vat);
      return euroFormatter.format(this.data.sales_price * (1+vat/100) )
      // return (this.data.sales_price * (1+vat/100) ).toPrecision(3)
    }
  },
  components: {
    MediaViewer,
    ArticleCategoryPicker, UnitPicker, ArticleStock, VatTypePicker
  },
  methods: {
    fetchData: function () {
      apiservice()
          .get('articles/' + this.$route.params.id)
          .then(response => (this.data = response.data.data));
    },
    update_ids: function () {
      console.log(this.data.article_category_id)
      this.data.article_category_id = (this.data.article_category ? this.data.article_category.id : null);
      console.log(this.data.article_category_id)

      this.data.default_unit_id = (this.data.default_unit ? this.data.default_unit.id : null);
      this.data.default_vat_id = (this.data.default_vat ? this.data.default_vat.id : null);

      this.data.format_length_unit_id = (this.data.format_length_unit ? this.data.format_length_unit.id : null);
      this.data.format_width_unit_id = (this.data.format_width_unit ? this.data.format_width_unit.id : null);
      this.data.format_depth_unit_id = (this.data.format_depth_unit ? this.data.format_depth_unit.id : null);
      this.data.weight_unit_id = (this.data.weight_unit ? this.data.weight_unit.id : null);

      this.data.supplier_id = (this.data.supplier ? this.data.supplier.id : null);
    },
    update: function () {
      // let self = this

      this.update_ids()

      apiservice()
          .post('articles/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Artikel erfolgreich geändert.',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            // self.$router.push("/articles");
          })
          .catch(function (error) {
            Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    create: function () {
      this.data.id = "new"

      this.update_ids()

      console.log(this.data);

      let self = this

      apiservice().post('articles/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Artikel erfolgreich hinzugefügt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/articles/" + response.data.data.id);
          })
          .catch(function (error) {
            Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    openArticleServices: function () {
      this.$buefy.modal.open({
        component: ArticleServices,
        parent: this,
        props: {
          'article_id': this.data.id
        },
        onCancel: function () {
          console.log()
        },
        events: {}
      })
    },
    openArticleSuppliers: function () {
      this.$buefy.modal.open({
        component: ArticleSuppliers,
        parent: this,
        props: {
          'article': this.data
        },
        onCancel: function () {
          console.log()
        },
        events: {}
      })
    },
    openArticleUnits: function () {
      this.$buefy.modal.open({
        component: ArticleUnits,
        parent: this,
        props: {
          'article': this.data,
          'default_unit': this.data.default_unit
        },
        onCancel: function () {
          console.log()
        },
        events: {}
      })
    },
    extendName: function () {
      let ext = ""
      console.log(this.data.color);
      if (this.data.color) {
        ext = ext + " " + this.data.color;
      }
      if (this.data.anti_slip_property) {
        ext = ext + " " + this.data.anti_slip_property;
      }
      if (this.data.rectified) {
        ext = ext + " rekt.";
      }
      return ext;
    },
    createLabel: function () {


      let payload = {'id': this.data.id}

      let self = this

      apiservice().post('labels/create_by_article', payload)
          .then(function (response) {
            Toast.open({
              message: 'Label erstellt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/labels/" + response.data.data.id);
          })
          .catch(function (error) {
            Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + error.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
      this.data.cash_discountable = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
