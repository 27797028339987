<template>
  <div class="articles">
    <section>
      <b-field group-multiline grouped>
        <div class="control">
          <router-link class="" to="/articles/new">
            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neuer Artikel</b-button> &nbsp;
          </router-link>
        </div>

        <div class="control">

          <b-button type="is-primary" @click="fetchData" v-bind:class="{ 'is-loading': isLoading }"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>



        <!--<b-select v-model="defaultSortDirection">
            <option value="asc">Default sort direction: ASC</option>
            <option value="desc">Default sort direction: DESC</option>
        </b-select>-->
         <div class="control mr-6">
            <b-select v-model="perPage" :disabled="!isPaginated">
              <option value="10">10 je Seite</option>
              <option value="20">20 je Seite</option>
              <option value="50">50 je Seite</option>
              <option value="100">100 je Seite</option>
            </b-select>
         </div>


        <div class="control">

          <b-field>
            <b-checkbox-button v-model="includeArchived"
                               :native-value=true
                               type="is-success"
                               @input="fetchData">
              <b-icon v-if="!includeArchived" icon="plus"></b-icon>
              <b-icon v-if="includeArchived" icon="check"></b-icon>
              <span>Archiv</span>
            </b-checkbox-button>
          </b-field>

        </div>

<!--        <div class="control ml-6 mr-6">-->
<!--&lt;!&ndash;          <b-icon icon="grip-lines-vertical" pack="md" size="is-medium" style="margin: 0 auto"/>&ndash;&gt;-->
<!--        </div>-->

        <div class="control">
          <b-field>
            <b-checkbox-button v-model="columnsVisible"
                               native-value="id"
                               type="is-primary is-light">
              <span>Artikelnr.</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="name"
                               type="is-primary is-light">
              <span>Bezeichnung</span>
            </b-checkbox-button>
            <b-checkbox-button v-model="columnsVisible"
                               native-value="manufacturer"
                               type="is-primary is-light">
              <span>Hersteller</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="series"
                               type="is-primary is-light">
              <span>Serie</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="color"
                               type="is-primary is-light">
              <span>Farbe</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="format_length"
                               type="is-primary is-light">
              <span>Länge</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="format_width"
                               type="is-primary is-light">
              <span>Breite</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="format_depth"
                               type="is-primary is-light">
              <span>Tiefe</span>
            </b-checkbox-button>

            <b-checkbox-button v-model="columnsVisible"
                               native-value="anti_slip_property"
                               type="is-primary is-light">
              <span>Rutschhemmung</span>
            </b-checkbox-button>

          </b-field>
        </div>




        <!--<div class="control">
            <button class="button" @click="currentPage = 2" :disabled="!isPaginated">Set page to 2</button>
        </div>-->
<!--        <div class="control is-flex">-->
<!--          <b-switch v-model="isPaginated">Paginated</b-switch>-->
<!--        </div>-->
<!--        <div class="control is-flex">-->
<!--          <b-switch v-model="isPaginationSimple" :disabled="!isPaginated">Simple pagination</b-switch>-->
<!--        </div>-->
<!--        <b-select v-model="paginationPosition" :disabled="!isPaginated">-->
<!--          <option value="bottom">bottom pagination</option>-->
<!--          <option value="top">top pagination</option>-->
<!--          <option value="both">both</option>-->
<!--        </b-select>-->
<!--        <b-select v-model="sortIcon">-->
<!--          <option value="arrow-up">Arrow sort icon</option>-->
<!--          <option value="menu-up">Caret sort icon</option>-->
<!--          <option value="chevron-up">Chevron sort icon</option>-->
<!--        </b-select>-->
<!--        <b-select v-model="sortIconSize">-->
<!--          <option value="is-small">Small sort icon</option>-->
<!--          <option value="">Regular sort icon</option>-->
<!--          <option value="is-medium">Medium sort icon</option>-->
<!--          <option value="is-large">Large sort icon</option>-->
<!--        </b-select>-->

      </b-field>

      <b-table
          :current-page.sync="currentPage"
          :data="data"
          :default-sort-direction="defaultSortDirection"
          :paginated="isPaginated"
          :pagination-position="paginationPosition"
          :pagination-simple="isPaginationSimple"
          :per-page="perPage"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          hoverable
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="name">

        <template>
          <b-table-column field="id" :visible="columnsVisible.includes('id')" label="Artikelnr." searchable :custom-search="idsearch" sortable width="70" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

<!--          <b-table-column field="article_nr" label="Artikelnummer" searchable sortable width="45" v-slot="props">-->
<!--            {{ props.row.article_nr }}-->
<!--          </b-table-column>-->

          <b-table-column field="name" :visible="columnsVisible.includes('name')" label="Bezeichnung"  searchable sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

<!--          <b-table-column field="name" label="Beschreibung" searchable sortable width="200">-->
<!--            {{ (props.row.description).substring(0, 20) }}...-->
<!--          </b-table-column>-->

<!--          <b-table-column field="marketing_name" label="Kundenbezeichnung" searchable sortable width="500" v-slot="props">-->
<!--            {{ (props.row.marketing_name) }}-->
<!--          </b-table-column>-->

          <b-table-column field="manufacturer" :visible="columnsVisible.includes('manufacturer')" label="Hersteller" searchable sortable width="150" v-slot="props">
            {{ props.row.manufacturer }}
          </b-table-column>

          <b-table-column field="series" :visible="columnsVisible.includes('series')" label="Serie" searchable sortable width="150" v-slot="props">
            {{ (props.row.series) }}
          </b-table-column>

          <b-table-column field="color" :visible="columnsVisible.includes('color')" label="Farbe" searchable sortable width="150" v-slot="props">
            {{ (props.row.color) }}
          </b-table-column>

          <b-table-column field="format_length" :visible="columnsVisible.includes('format_length')" label="Länge" searchable sortable numeric width="80" v-slot="props">
            <span v-if="props.row.format_length">{{ parseFloat(props.row.format_length) || "" }} {{ props.row.format_unit_symbol }}</span>
          </b-table-column>

          <b-table-column field="format_width" :visible="columnsVisible.includes('format_width')" label="Breite" searchable sortable numeric width="80" v-slot="props">
            <span v-if="props.row.format_width">{{ parseFloat(props.row.format_width) || "" }} {{ props.row.format_unit_symbol }}</span>
          </b-table-column>

          <b-table-column field="format_depth" :visible="columnsVisible.includes('format_depth')" label="Tiefe" searchable sortable numeric width="80" v-slot="props">
            <span v-if="props.row.format_depth">{{ parseFloat(props.row.format_depth) || "" }} mm</span>
          </b-table-column>

          <b-table-column field="anti_slip_property" :visible="columnsVisible.includes('anti_slip_property')" label="R" searchable sortable width="80" v-slot="props">
            {{props.row.anti_slip_property}}
          </b-table-column>

<!--          <b-table-column field="rectified" label="rekt." checkable searchable sortable v-slot="props">-->
<!--            {{ (props.row.rectified) }}-->
<!--          </b-table-column>-->

          <b-table-column label="Aktionen" width="160" v-slot="props">
<!--            <b-button type="is-light">Stornieren</b-button>-->
<!--            <router-link class="" to="/invoice">-->

            <b-button type="is-primary is-light" @click="openArticleSummary(props.row.id)"><i class="fa fa-eye"></i></b-button>
            <b-button type="is-primary" @click="openItem(props.row.id, true)"><i class="fa fa-pen"></i></b-button>

<!--            <b-button type="is-primary is-light" @click="openItem(props.row.id, true)"><i class="fa fa-folder-plus"></i></b-button>-->
<!--            </router-link>-->


          </b-table-column>
        </template>
      </b-table>
    </section>
    <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
  </div>
</template>

<script>

import ArticleCategory from "@/views/ArticleCategory";
import apiservice from "@/services/apiservice";
import ArticleSummary from "@/components/ArticleSummary";

export default {
  name: 'ArticleCategories',
  props: {
    msg: String
  },
  components: {
    // ArticleCategory
  },
  data() {
    return {
      data: [],
      includeArchived: false,
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 50,
      isLoading: false,
      columnsVisible: ['id', 'name', 'manufacturer', 'series', 'format_length', 'format_width', 'color']
    }
  },
  methods: {
    fetchData: function() {
      this.isLoading = true;
      let url = "articles"
      if (this.includeArchived) {
        url += "/includearchived"
      }
      apiservice()
          .get(url)
          .then(response => {
            this.data = response.data.data;
            this.isLoading = false;
          });
    },
    createNew: function(){
      this.$buefy.modal.open({
        parent: this,
        component: ArticleCategory,
        hasModalCard: true,
        trapFocus: true
      })
    },
    // eslint-disable-next-line no-unused-vars
    openItem: function(id, newTab=false){
      if (newTab) {
        let routeData = this.$router.resolve({ path: `/articles/${id}` })
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ path: `/articles/${id}` })
      }
    },
    openArticleSummary: function(article_id) {
      this.$buefy.modal.open({
        component: ArticleSummary,
        parent: this,
        props: {
          'article_id': article_id
        },
        onCancel: function () {
          console.log()
        },
        events: {
        }
      })
    },
    idsearch: function(a, input) {
      console.log(a.id)
      console.log(input)
      return a.id.toString().includes(input)
    }
  },
  beforeMount(){
    this.fetchData()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
