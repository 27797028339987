import Vue from 'vue'
import App from './App.vue'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import router from './routes.js'
import VueRouter from 'vue-router';
import axios from "axios";

// import { createApp } from 'vue';
// import PrimeVue from 'primevue/config';

Vue.use(Buefy)
// Vue.use(PrimeVue);
Vue.use(VueRouter)


Vue.config.productionTip = false

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


new Vue({
  render: h => h(App), 
  router
}).$mount('#app')
