<template>
  <div class="article_stock">
    <div class="control"><b-button type="is-primary" expanded icon-left="table" icon-right="arrow-right" @click="openStock()">Lagerdetails</b-button></div>

    <div class="notification is-primary" :hidden="charges.length > 0">
      Keine Lagerinformationen vorhanden.
    </div>

    <b-field group-multiline grouped :hidden="readonly">
      <div class="control">
        <b-button class="button is-success is-light" @click="openNewCharge" icon-left="plus">
          Neue Charge
        </b-button>

        <b-button class="button is-secondary is-light" @click="fetchData" icon-left="refresh">
          Aktualisieren
        </b-button>
      </div>
    </b-field>

    <table class="table">
      <thead>
        <tr>
          <th>Charge</th>
          <th>Bestand</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr
            v-for="c in charges"
            :value="c.id"
            :key="c.id">
          <td>{{ c.charge }}</td>
          <td>{{ c.amount }}</td>
          <td>
<!--            <b-tooltip label="Eingang buchen">-->
<!--              <button disabled class="button is-small is-success" @click="openNewEntry(c.id, c.charge, +1)">+</button>-->
<!--            </b-tooltip>-->
<!--            <b-tooltip label="Ausgang buchen">-->
<!--              <button disabled class="button is-small is-danger" @click="openNewEntry(c.id, c.charge, -1)">-</button>-->
<!--            </b-tooltip>-->
<!--            <b-tooltip label="Inventurbuchung" :hidden="readonly">-->
<!--              <button class="button is-small" @click="equal">=</button>-->
<!--            </b-tooltip>-->
            <b-tooltip label="Details">
              <button class="button is-small" @click="openChargeDetails(c.id, c.charge)"><i class="fa fa-info"></i></button>
            </b-tooltip>
          </td>
<!--          <td :hidden="!readonly">-->
<!--            <b-tooltip label="Details">-->
<!--              <b-button icon-pack="fa" icon-left="info" class="button" @click="openChargeDetails(c.id, c.charge)">Details</b-button>-->
<!--            </b-tooltip>-->
<!--          </td>-->

        </tr>
      </tbody>
    </table>

    <b-loading :active="isLoading" :is-full-page="false" :can-cancel="false" />
  </div>

</template>

<script>

import apiservice from "@/services/apiservice";
import { ToastProgrammatic as Toast } from 'buefy'
import NewStockEntry from "@/components/NewStockEntry";
import StockChargeDetails from "@/components/StockChargeDetails";
// import {DialogProgrammatic as Dialog} from "buefy/types/components";



export default {
  name: 'ArticleStock',
  data() {
    return {
      charges: [],
      isLoading: true
    }
  },
  props: ['article_id', 'readonly'],
  methods: {
    fetchData: function() {
      this.isLoading = true;
      apiservice()
          .get("stock/article/"+this.article_id+"/summary")
          .then(response => {
            this.charges = response.data.data;
            this.isLoading = false;
          });
    },
    openStock: function() {
      this.$router.push({ path: `/articles/${this.article_id}/stock` })
    },
    equal: function () {
      Toast.open({
        message: 'Inventurbuchung (=IST) noch nicht möglich.',
        position: 'is-bottom',
        type: 'is-danger',
        duration: 3500,
        queue: false
      })
    },
    // updateValue() {
    //   this.$emit('input', this.value)
    // },
    openNewCharge: function() {
      let a_id = this.article_id
      let s = this;
      this.$buefy.dialog.prompt({
        title: 'Neue Charge',
        message: `Name/Bezeichnung der Charge:`,
        inputAttrs: {
          placeholder: 'Chargenbezeichnung',
          maxlength: 30
        },
        confirmText: 'OK',
        cancelText: 'Abbrechen',
        trapFocus: true,
        onConfirm: function(value) {
          let newCharge = {'id': 'new', 'name': value};

          apiservice().post('/articles/' + a_id + '/charges/', newCharge)
              .then(function (response) {
                this.$buefy.toast.open({
                  message: 'Charge erfolgreich hinzugefügt',
                  position: 'is-bottom',
                  type: 'is-success'
                })
                s.fetchData();
                console.log(response);
              })
              .catch(function (error) {
                this.$buefy.dialog.alert({
                  title: 'Fehler',
                  message: 'Ein Fehler ist aufgetreten: ' + error,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                console.log(error);
              });
        }
      })
    },
    openNewEntry: function(charge_id, charge_name, mode) {
      this.$buefy.modal.open({
        parent: this,
        component: NewStockEntry,
        props: {'charge_id': charge_id, 'charge_name': charge_name, 'article_id': this.article_id, 'stock_entry_mode': mode},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
    },
    openChargeDetails: function(charge_id, charge_name) {
      this.$buefy.modal.open({
        parent: this,
        component: StockChargeDetails,
        props: {'charge_id': charge_id, 'charge_name': charge_name},
        hasModalCard: true,
        customClass: '',
        trapFocus: true
      })
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>