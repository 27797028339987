<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Alternativeinheit hinzufügen für "{{ article.name }}"</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <!--        <b-field grouped>-->
        <!--&lt;!&ndash;          <b-input v-model="data.color"></b-input>&ndash;&gt;-->
        <!--          <b-numberinput controls-position="compact" controls-rounded v-model="data.factor" step="0.01"></b-numberinput>-->
        <!--          <UnitPicker  />-->
        <!--          <b-button type="is-text" style="text-decoration: none">je {{ default_unit.name }} ({{ default_unit.symbol }})</b-button>-->
        <!--        </b-field>-->



        <b-field position="is-centered">
          <b-radio-button v-model="calculationBase"
                          :native-value="0"
                          type="is-primary">
            <b-icon icon="alpha-a-circle"></b-icon>
            <span>Alternativeinheiten je Basiseinheit</span>
          </b-radio-button>

          <b-radio-button v-model="calculationBase"
                          :native-value="1"
                          type="is-primary">
            <b-icon icon="alpha-b-circle"></b-icon>
            <span>Basiseinheiten je Alternativeinheit</span>
          </b-radio-button>
        </b-field>


<!--        <b-field label="Alternativeinheit" horizontal>-->
<!--          <UnitPicker v-model="data.unit" />-->
<!--        </b-field>-->

<!--        <b-field label="Faktor (in %)" horizontal>-->
<!--          <b-numberinput controls-position="compact" controls-rounded v-model="data.factor" step="1"></b-numberinput>-->
<!--        </b-field>-->

<!--        <b-field label="Basiseinheit" horizontal>-->
<!--          {{ article.default_unit.name }} ({{ article.default_unit.symbol }})-->
<!--        </b-field>-->

<!--        <b-field label="A" horizontal>-->
<!--           {{ data.factor/100 }} {{ data.unit ? data.unit.symbol : '' }} je {{ article.default_unit.symbol }}-->
<!--        </b-field>-->

        <b-field grouped position="is-centered" class="mt-6 mb-6" v-show="calculationBase == 0">
          <b-field>
            <b-numberinput step="1" min-step="0.0000001" controls-position="compact" controls-rounded v-model="calculationA" />
          </b-field>
          <b-field>
            <UnitPicker v-model="data.unit" />
          </b-field>
          <b-field>
            <span class="is-size-4">je</span>
          </b-field>
          <b-field>
            <span class="is-size-4"><b>{{ article.default_unit.symbol }}</b></span>
          </b-field>
        </b-field>

        <b-field grouped position="is-centered" class="mt-6 mb-6" v-show="calculationBase == 1">
          <b-field>
            <b-numberinput step="1" min-step="0.0000001" controls-position="compact" controls-rounded v-model="calculationB" />
          </b-field>
          <b-field>
            <span class="is-size-4"><b>{{ article.default_unit.symbol }}</b></span>

          </b-field>
          <b-field>
            <span class="is-size-4">je</span>
          </b-field>
          <b-field>
            <UnitPicker v-model="data.unit" />
          </b-field>
        </b-field>

<!--        {{data.factor}}-->

<!--        <b-field label="Umrechnung" horizontal>-->
<!--          <div>{{ data.factor/100 }} {{ data.unit ? data.unit.symbol : '' }} je {{ article.default_unit.symbol }}</div> <div>{{ (1/(data.factor/100)).toFixed(2) }} {{ article.default_unit.symbol }} je {{ data.unit ? data.unit.symbol : '' }}</div>-->
<!--        </b-field>-->



      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="OK"
            type="is-primary"
            :disabled="data.factor==null || data.unit==null || !data.factor"
            @click="confirm" />
        <!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


// import apiservice from "@/services/apiservice";
import UnitPicker from "@/components/UnitPicker";

export default {
  name: 'StockPlacePicker',
  components: {UnitPicker},
  data() {
    return {
      data: {'unit': null, 'factor': 100},
      isFetching: true,
      columnsVisible: ['id', 'name', 'manufacturer', 'series', 'format_length', 'format_width', 'color'],
      calculationBase: 1,
      calculationA: 1,
      calculationB: 1
    }
  },
  props: {
    // value: Object,
    // default_unit: Object
    article: Object
  },
  methods: {
    fetchData: function() {
      // this.isFetching = true;
      // apiservice()
      //     .get("articles")
      //     .then(response => (this.data = response.data.data));
      // this.isFetching = false;
    },
    confirm() {
      this.data.unit_id = this.data.unit.id
      this.$emit('confirm', this.data)
      this.$emit('close')
      // apiservice()
      //     .get("articles/" + this.value.id + "/units")
      //     .then(response => {
      //       this.$emit('confirm', response.data.data)
      //       this.$emit('close')
      //     })
      }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  },
  watch: {
    calculationA: function (val) {
      if(this.calculationBase == 0) {
        this.data.factor = parseInt(1000000/val)
        this.calculationB = (this.data.factor/1000000).toFixed(3)
      }
    },
    calculationB: function (val) {
      if(this.calculationBase == 1) {
        this.data.factor = parseInt((val * 1000000).toFixed(0))
        this.calculationA = (1/this.data.factor*1000000).toFixed(3)
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>