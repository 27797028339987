<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Lieferant auswählen</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="isPaginated"
            :pagination-position="paginationPosition"
            :pagination-simple="isPaginationSimple"
            :per-page="20"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
            :selected.sync="value"
            focusable>

          <template>
            <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">
              {{ props.row.id }}
            </b-table-column>

            <b-table-column field="name" label="Name" searchable sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="place" label="Ort" searchable sortable v-slot="props">
              {{ props.row.place }}
            </b-table-column>
          </template>
        </b-table>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="OK"
            type="is-primary"
            :disabled="value==null"
            @click="confirm" />
        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";

export default {
  name: 'SupplierPickerPromt',
  data() {
    return {
      data: [],
      isFetching: true
    }
  },
  props: {
    value: Object
  },
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("suppliers")
          .then(response => (this.data = response.data.data));
      this.isFetching = false;
    },
    confirm() {
      // this.$emit('input', this.value)
      this.$emit('confirm', this.value)
      this.$emit('close')
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>