<template>

  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Ansprechpartner</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
          <div class="control">
            <b-button class="button is-primary" @click="addContactPerson()" expanded icon-pack="fa" icon-left="link">Neuer Ansprechpartner</b-button>
          </div>

        <b-table
            :current-page.sync="currentPage"
            :data="data"
            :default-sort-direction="defaultSortDirection"
            :paginated="true"
            :pagination-position="bottom"
            :pagination-simple="true"
            :per-page="perPage"
            :sort-icon="sortIcon"
            :sort-icon-size="sortIconSize"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            default-sort="name"
        >

          <template>

            <b-table-column field="function" label="Funktion" searchable sortable v-slot="props">
              {{ props.row.function }}
            </b-table-column>

            <b-table-column field="name" label="Name" searchable sortable v-slot="props">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="location" label="Standort" searchable sortable v-slot="props">
              {{ props.row.location }}
            </b-table-column>

            <b-table-column field="phone" label="Telefon" searchable sortable v-slot="props">
              {{ props.row.phone }}
            </b-table-column>

            <b-table-column field="mobile" label="Mobil" searchable sortable v-slot="props">
              {{ props.row.mobile }}
            </b-table-column>

            <b-table-column field="email" label="E-Mail" searchable sortable v-slot="props">
              {{ props.row.email }}
            </b-table-column>

            <b-table-column label="Aktionen"  v-slot="props">
              <b-button type="is-primary" @click="editContactPerson(props.row.id)"><i class="fa fa-edit"></i></b-button>
            </b-table-column>

          </template>
        </b-table>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Schließen"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>
  </form>




</template>

<script>

// import apiservice from "@/services/apiservice";


import apiservice from "@/services/apiservice";
import ContactPerson from "@/components/ContactPerson";

export default {
  name: 'ContactPersons',
  data() {
    return {
      data: [],
      isFetching: true
    }
  },
  props: ['relation_id', 'relation_type'],
  methods: {
    fetchData: function() {
      this.isFetching = true;
      apiservice()
          .get("contactpersons/" + this.relation_type + "/" + this.relation_id)
          .then(response => (this.data = response.data.data));
      this.isFetching = false;

    },
    confirm() {
      this.$emit('confirm', this.value)
      this.$emit('close')
    },
    addContactPerson: function() {
      const modal = this.$buefy.modal.open({
        component: ContactPerson,
        parent: this,
        props: {
          // 'default_unit': this.default_unit,
          'id': 'new',
          'relation_type': this.relation_type,
          'relation_id': this.relation_id
        },

        // onClose: function () {
        //   this.fetchData()
        //   console.log()
        // },
        // onConfirm: function () {
        //   this.fetchData()
        //   console.log()
        // },
        events: {
          // 'close': this.fetchData(),
          // 'confirm': this.fetchData()
        }
      })
      modal.$on('close', () => this.fetchData())
      this.fetchData()
    },
    editContactPerson: function(id) {
      const modal = this.$buefy.modal.open({
        component: ContactPerson,
        parent: this,
        props: {
          // 'default_unit': this.default_unit,
          'id': id,
          'relation_type': this.relation_type,
          'relation_id': this.relation_id
        },

        // onClose: function () {
        //   this.fetchData()
        //   console.log()
        // },
        // onConfirm: function () {
        //   this.fetchData()
        //   console.log()
        // },
        events: {
          // 'close': this.fetchData(),
          // 'confirm': this.fetchData()
        }
      })
      modal.$on('close', () => this.fetchData())
      this.fetchData()
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>