import { render, staticRenderFns } from "./ArticleServices.vue?vue&type=template&id=28db61ab&scoped=true&"
import script from "./ArticleServices.vue?vue&type=script&lang=js&"
export * from "./ArticleServices.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28db61ab",
  null
  
)

export default component.exports