<template>
  <div class="article">

    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Artikel</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">


        <div class="tile is-ancestor">
          <div class="tile is-vertical is-16">
            <div class="tile">
              <div class="tile is-parent is-vertical is-4">
                <article class="tile is-child notification box">
<!--                  <b-field label="ID" :hidden="isNew">-->
<!--                    <b-input v-model="data.id" disabled="disabled"></b-input>-->
<!--                  </b-field>-->


                  <b-field label="Herstellernummer">
                    {{ data.manufacturer_nr }}
                  </b-field>

                  <b-field label="EAN">
                    {{data.ean}}
                  </b-field>

                  <b-field label="Artikelgruppe">
                    {{ data.article_category ? data.article_category.name : 0}}
                  </b-field>

                  <b-field label="Basis-Einheit">
                    {{ data.default_unit? data.default_unit.symbol : ''}}
                  </b-field>

<!--                  <b-field label="Alternativ-Einheit">-->
                  <b>Alternativ-Einheiten</b>

                      <b-field v-for="unit in data.units" :key="unit.id">
                        <b-taglist attached>
                          <b-tag type="is-dark" size="is-medium">1 {{ unit.unit.symbol }}</b-tag>
                          <b-tag type="is-info" size="is-medium">{{ unit.factor/1000000 }} {{ data.default_unit ? data.default_unit.symbol : "x" }}</b-tag>
                        </b-taglist>
                      </b-field>


<!--                    <b-table-->
<!--                        :data="data.units"-->
<!--                        :bordered="false"-->
<!--                        :striped="true"-->
<!--                        :mobile-cards="true">-->


<!--                      <b-table-column field="ab" label="A/B" v-slot="props">-->
<!--                        {{  (props.row.factor/1000000).toFixed(2) }} {{ data.default_unit.symbol }} je {{ props.row.unit.symbol }}-->

<!--                      </b-table-column>-->

<!--&lt;!&ndash;                      <b-table-column field="ba" label="B/A" v-slot="props">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{ (1/(props.row.factor/1000000)).toFixed(2) }} {{ props.row.unit.symbol }} je {{ data.default_unit.symbol }}&ndash;&gt;-->
<!--&lt;!&ndash;                      </b-table-column>&ndash;&gt;-->

<!--                    </b-table>-->
<!--                  </b-field>-->

                  <b-field label="Standard-USt.">
                    {{ data.default_vat ? data.default_vat.rate : '-' }}%
                  </b-field>

                  <b-field label="Im Sortiment seit">
                    {{ data.intro_date }}
                  </b-field>

                </article>

              </div>

              <div class="tile is-parent is-16">
                <article class="tile is-child notification box">


                  <div class="columns">
                    <div class="column">
                      <b-field label="Hersteller">
                        {{ data.manufacturer }}
                      </b-field>
                    </div>

                    <div class="column">

                      <b-field label="Serie">
                        {{ data.series }}
                      </b-field>

                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Farbe">
                        {{ data.color }}
                      </b-field>
                    </div>

                    <div class="column">

                      <b-field label="Rutschhemmung">
                        {{ data.anti_slip_property }}
                      </b-field>

                    </div>

                    <div class="column">

                      <b-field label="rektifiziert">
                        <b-checkbox v-model="data.rectified" disabled>
                          ja
                        </b-checkbox>
                      </b-field>

                    </div>
                  </div>

                  <b-field label="Bezeichnung">
                    {{ data.name }}
                  </b-field>

                  <b-field label="Beschreibung">
                    {{ data.description }}
                  </b-field>

                  <b-field label="Interne Notiz">
                    {{ data.internal_note }}
                  </b-field>

                </article>
              </div>
            </div>
          </div>

        </div>

        <div class="tile is-ancestor">
          <div class="tile is-vertical is-16">
            <div class="tile">
              <div class="tile is-parent is-vertical is-4">
                <article class="tile is-child notification box">
                  <b-field label="Länge">
                    {{ data.format_length }} {{ data.format_length_unit ? data.format_length_unit.symbol : "" }}
                  </b-field>

                  <b-field label="Breite">
                    {{ data.format_width }} {{ data.format_width_unit ? data.format_width_unit.symbol : "" }}
                  </b-field>

                  <b-field label="Stärke / Tiefe">
                    {{ data.format_depth }} {{ data.format_depth_unit ? data.format_depth_unit.symbol : "" }}
                  </b-field>

                  <b-field label="Gewicht">
                    {{ data.weight }} {{ data.weight_unit ? data.weight_unit.symbol : "" }}
                  </b-field>

                </article>

              </div>


              <div class="tile is-parent is-4">
                <article class="tile is-child notification box">
                  <b-field label="VK-Preis (netto)">
                    {{ data.sales_price }} €
                  </b-field>

                  <b-field label="VK-Preis (brutto)">
                    {{ sales_price_gross }}
                  </b-field>
<!--                  <h3>Lieferanten</h3>-->
                  <b-table
                      :data="data.suppliers"
                      :bordered="false"
                      :striped="true"
                      :mobile-cards="true">


                    <b-table-column field="supplier.name" label="Lieferant" v-slot="props">
                      {{ props.row.supplier.name }}
                    </b-table-column>

                    <b-table-column field="cost_price" label="EK (€)" v-slot="props">
                      {{ props.row.cost_price }}
                    </b-table-column>

                    <b-table-column field="list_price" label="LP (€)" v-slot="props">
                      {{ props.row.list_price }}
                    </b-table-column>

                    <b-table-column field="last_name" label="Standard" v-slot="props">
                      <b-icon
                          pack="fa"
                          :icon="props.row.default_supplier === true ? 'check' : 'none'">
                      </b-icon>
                    </b-table-column>

                  </b-table>

                </article>
              </div>


              <div class="tile is-parent is-4">
                <article class="tile is-child notification box">
                  <div class="title">Lager</div>
                  <ArticleStock :article_id="data.id" v-if="data.id"></ArticleStock>
                </article>
              </div>

            </div>

          </div>
        </div>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="OK"-->
<!--            type="is-primary"-->
<!--            :disabled="value==null"-->
<!--            @click="confirm" />-->
<!--        <div>Aktuelle Auswahl: {{ value ? value.name : "Noch keine" }}</div>-->
      </footer>
    </div>

  </div>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'

import apiservice from "@/services/apiservice";
import ArticleStock from "@/components/ArticleStock";



export default {
  name: 'ArticleSummary',
  props: ['article_id'],
  data() {
            return {
              data: {sales_price: 1, cost_price:0},
              isNew: null
            }
  },
  computed: {
    title() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.name + " (" + this.data.article_nr + ")"
      }
    },
    price_delta() {
      return (this.data.sales_price - this.data.cost_price).toPrecision(2)
    },
    sales_price_gross() {
      const euroFormatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      })

      let vat = 0;
      if (this.data.default_vat != null) {
        vat = this.data.default_vat.rate;
      }
      console.log(vat);
      return euroFormatter.format(this.data.sales_price * (1+vat/100) )
      // return (this.data.sales_price * (1+vat/100) ).toPrecision(3)
    }
  },
  components: {
    // ArticleCategoryPicker, SupplierPicker, UnitPicker, , VatTypePicker
    ArticleStock
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('articles/' + this.article_id)
          .then(response => (this.data = response.data.data));
    },
    update: function() {
      let self = this
      apiservice()
          .post('articles/', this.data)
          .then(function (response) {
              Toast.open({
                message: 'Artikel erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              console.log(response);
            self.$router.push("/articles");
            })
            .catch(function (error) {
              Dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error.message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
            });
    },
    create: function() {
      this.data.id = "new"
      let self = this

      apiservice().post('articles/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Artikel erfolgreich hinzugefügt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/articles/");
          })
          .catch(function (error) {
            Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error.message,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
            console.log(error);
          });
    }
  },
  beforeMount() {
      this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
