<template>
  <div class="image">

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent">
            <article class="tile is-child notification is-primary">
              <p class="title">Bild</p>
            </article>
          </div>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child">
            hallo
<!--            <MediaUploader v-on:fileUploaded="handleFileUploaded" v-on:allFilesUploaded="handleAllFilesUploaded" />-->
            <file-pond
                name="file"
                ref="pond"
                label-idle="Dateien hier ablegen oder <span class='filepond--label-action'>Dateien auswählen</span>."
                v-bind:allow-multiple="true"
                v-bind:allowRemove="false"
                v-bind:allowRevert="false"
                :credits="false"
                labelFileLoading="Laden"
                labelFileProcessing="Hochladen"
                labelFileProcessingComplete="Fertig hochgeladen"
                labelTapToCancel="Tappen, um abzubrechen"
                labelTapToRetry="Tappen, um nochmal zu versuchen"
                accepted-file-types="image/jpeg, image/png"
                server="/api/media/image"

                v-bind:files="myFiles"
                v-on:init="handleFilePondInit"
            />
<!--            <div v-for="file in uploadedFiles" :key="file.id">-->
<!--              <img :src="file.url" :alt="file.name">-->
<!--            </div>-->
          </article>
        </div>

        <div class="tile is-parent">
          <article class="tile is-child">
            foot
          </article>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
)
export default {
  name: 'MediaImage',
  data: function() {
    return { myFiles: [] }
  },
  components: {
    FilePond
  },
  methods: {
    handleFilePondInit: function () {
      console.log("FilePond has initialized");

      // FilePond instance methods are available on `this.$refs.pond`
    },
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
