import VueRouter from 'vue-router';
import Dashboard from './views/Dashboard.vue'
// import Invoices from './views/Invoices.vue'
// import Invoice from './views/Invoice.vue'
import ArticleCategoryOverview from "./views/ArticleCategoryOverview.vue";
import ArticleCategory from "@/views/ArticleCategory";
import Login from "@/views/Login";
import SupplierOverview from "@/views/SupplierOverview";
import Supplier from "@/views/Supplier";
import ArticleOverview from "@/views/ArticleOverview";
import Article from "@/views/Article";
import UnitOverview from "@/views/UnitOverview";
import Unit from "@/views/Unit";
import CustomerOverview from "@/views/CustomerOverview";
import Customer from "@/views/Customer";
import ProjectOverview from "@/views/ProjectOverview";
import Project from "@/views/Project";
import Document from "@/views/Document";
// import InvoiceOverview from "@/views/InvoiceOverview";
import DocumentOverview from "@/views/DocumentOverview";
import ServiceOverview from "@/views/ServiceOverview";
import Service from "@/views/Service";
import PaymentConditionOverview from "@/views/PaymentConditionOverview";
import PaymentCondition from "@/views/PaymentCondition";
// import Stock from "@/views/Stock";
import Boilerplate from "@/views/Boilerplate";
import BoilerplateOverview from "@/views/BoilerplateOverview";
import StockMobile from "@/views/StockMobile";
import StockIncoming from "@/views/mobile/StockIncoming";
import StockPicking from "@/views/mobile/StockPicking";
import StockShipping from "@/views/mobile/StockShipping";
import LabelOverview from "@/views/LabelOverview";
import Label from "@/views/Label";
import MediaImage from "@/views/MediaImage";
import OrderOutOverview from "@/views/OrderOutOverview";
import OrderOut from "@/views/OrderOut";
import StockOverview from "@/views/StockOverview";
import StockLog from "@/views/StockLog";
import StockValue from "@/views/StockValue";
import StockRecentEntriesOld from "@/views/StockRecentEntriesOld";
import StockTaking from "@/views/StockTaking";

let routes = [
  {
    path: '/',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: Login,
    name: "login",
    meta: {
      requiresAuth: false
    }
  },
  // {
  //   path: '/invoiceold',
  //   component: Invoice,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/articlecategories',
    component: ArticleCategoryOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articlecategories/:id',
    component: ArticleCategory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/suppliers',
    component: SupplierOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/suppliers/:id',
    component: Supplier,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles',
    component: ArticleOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/:id',
    component: Article,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/services',
    component: ServiceOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/services/:id',
    component: Service,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/units',
    component: UnitOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/units/:id',
    component: Unit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/paymentconditions',
    component: PaymentConditionOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/paymentconditions/:id',
    component: PaymentCondition,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers',
    component: CustomerOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/customers/:id',
    component: Customer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects',
    component: ProjectOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:id',
    component: Project,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documents/invoice',
    component: DocumentOverview,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'invoice'
    }
  },
  {
    path: '/documents/invoice/:id',
    component: Document,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'invoice'
    }
  },
  {
    path: '/documents/quote',
    component: DocumentOverview,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'quote'
    }
  },
  {
    path: '/documents/quote/:id',
    component: Document,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'quote'
    }
  },
  {
    path: '/documents/order',
    component: DocumentOverview,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'order'
    }
  },
  {
    path: '/documents/order/:id',
    component: Document,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'order'
    }
  },
  {
    path: '/documents/deliverynote',
    component: DocumentOverview,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'deliverynote'
    }
  },
  {
    path: '/documents/deliverynote/:id',
    component: Document,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'deliverynote'
    }
  },
  {
    path: '/documents/pickingnote',
    component: DocumentOverview,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'pickingnote'
    }
  },
  {
    path: '/documents/pickingnote/:id',
    component: Document,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'pickingnote'
    }
  },
  {
    path: '/documents/creditnote',
    component: DocumentOverview,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'creditnote'
    }
  },
  {
    path: '/documents/creditnote/:id',
    component: Document,
    meta: {
      requiresAuth: true
    },
    props: {
      document_type: 'creditnote'
    }
  },
  {
    path: '/stock',
    component: StockOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/:article_id/stock',
    component: StockOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock/log',
    component: StockLog,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock/taking',
    component: StockTaking,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock/value',
    component: StockValue,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stock/logold',
    component: StockRecentEntriesOld,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/boilerplates',
    component: BoilerplateOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/boilerplates/:id',
    component: Boilerplate,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/labels',
    component: LabelOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/labels/:id',
    component: Label,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/stockmobile',
    component: StockMobile,
    meta: {
      requiresAuth: true,
      focusMode: true
    }
  },
  {
    path: '/stockmobile/incoming',
    component: StockIncoming,
    meta: {
      requiresAuth: true,
      focusMode: true
    }
  },
  {
    path: '/stockmobile/picking',
    component: StockPicking,
    meta: {
      requiresAuth: true,
      focusMode: true
    }
  },
  {
    path: '/stockmobile/shipping',
    component: StockShipping,
    meta: {
      requiresAuth: true,
      focusMode: true
    }
  }
  ,
  {
    path: '/images/',
    component: MediaImage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orderout',
    component: OrderOutOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orderout/:id',
    component: OrderOut,
    meta: {
      requiresAuth: true
    }
  },

];


let router = new VueRouter({
  routes,
  linkActiveClass: 'is-active'
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && JSON.parse(localStorage.getItem('user')) == null)  next({ name: 'login' })
  else next()
})


export default router