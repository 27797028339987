<template>
  <div class="supplier">
    <section>
      <b-field group-multiline grouped>
        <div class="control">
          <router-link class="" to="/suppliers/new">
            <b-button type="is-primary"><i class="fa fa-plus"></i>&nbsp;Neuer Lieferant</b-button> &nbsp;
          </router-link>
        </div>

        <div class="control">

          <b-button type="is-primary" @click="fetchData"><i class="fa fa-recycle"></i>&nbsp;Aktualisieren</b-button>

        </div>
        <!--<b-select v-model="defaultSortDirection">
            <option value="asc">Default sort direction: ASC</option>
            <option value="desc">Default sort direction: DESC</option>
        </b-select>-->
        <b-select v-model="perPage" :disabled="!isPaginated">
          <option value="10">10 je Seite</option>
          <option value="20">20 je Seite</option>
          <option value="50">50 je Seite</option>
        </b-select>

      </b-field>

      <b-table
          :current-page.sync="currentPage"
          :data="data"
          :default-sort-direction="defaultSortDirection"
          :paginated="isPaginated"
          :pagination-position="paginationPosition"
          :pagination-simple="isPaginationSimple"
          :per-page="perPage"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          aria-current-label="Current page"
          aria-next-label="Next page"
          aria-page-label="Page"
          aria-previous-label="Previous page"
          default-sort="name">

        <template>
          <b-table-column field="id" label="ID" numeric sortable width="40" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="name" label="Name" searchable sortable v-slot="props">
            {{ props.row.name }}
          </b-table-column>

          <b-table-column label="Aktionen" v-slot="props">

          <b-button type="is-primary" @click="openItem(props.row.id)"><i class="fa fa-eye"></i>&nbsp;Bearbeiten</b-button>

          </b-table-column>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'SupplierOverview',
  props: {
    msg: String
  },
  components: {
    // ArticleCategory
  },
  data() {
    return {
      data: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      defaultSortDirection: 'asc',
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      currentPage: 1,
      perPage: 10
    }
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('suppliers')
          .then(response => (this.data = response.data.data));
    },
    // eslint-disable-next-line no-unused-vars
    openItem: function(id){
      this.$router.push({ path: `/suppliers/${id}` })
    }
  },
  beforeMount(){
    this.fetchData()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
