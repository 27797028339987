<template>
  <div class="articlecategory">

    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent">
            <article class="tile is-child notification is-primary">
              <p class="title">Label</p>
              <p class="subtitle">{{ pagetitle }}</p>
            </article>
          </div>
        </div>
      </div>
    </div>



    <div class="tile is-ancestor">
      <div class="tile is-vertical is-16">
        <div class="tile">
          <div class="tile is-parent is-vertical is-4">
            <article class="tile is-child notification box">
              <b-field label="ID" :hidden="isNew">
                <b-input v-model="data.id" disabled="disabled"></b-input>
              </b-field>

              <b-field label="QR-Code" :hidden="isNew">
                <VueQRCodeComponent :text="data.url" error-level="M" bg-color="#fff"></VueQRCodeComponent >
              </b-field>

              <b-field label="URL" :hidden="isNew">
                {{data.url}}
              </b-field>

              <div :hidden="isNew">
<!--                <h3 class="title is-5">Druckoptionen</h3>-->
                <b-button :type="printMode ? 'is-success' : 'is-primary'" :loading="printerLoading" @click="initPrinting"><i class="fa fa-print"></i>&nbsp;Druckmodus</b-button>

                <div v-if="printMode">
                  <b-select placeholder="Drucker auswählen" v-if="labelService && labelService.printers" v-model="selectedPrinter">
                    <option
                        v-for="option in labelService.printers"
                        :value="option.name"
                        :key="option.name"
                        selected="true">
                      {{ option.name }}
                    </option>
                  </b-select>


                  <b-field grouped>
                    <b-button v-if="printMode || 1" type="is-success is-warning" @click="previewLabel"><i class="fa fa-eye"></i> Vorschau</b-button>
                    <b-button v-if="printMode || 1" type="is-primary" @click="printLabel"><i class="fa fa-print"></i> Drucken</b-button>
                  </b-field>

                  <b-image
                      v-if="labelPreview"
                      :src="'data:image/png;base64,' + this.labelPreview"
                      alt="Label Preview"
                  ></b-image>


                </div>

              </div>




            </article>

          </div>

          <div class="tile is-parent is-16">
            <article class="tile is-child notification box">

              <b-field label="Titel">
                <b-input v-model="data.title"></b-input>
              </b-field>


              <div class="columns">
                <div class="column">
                  <b-field label="Location 1">
                    <b-input v-model="data.location1"></b-input>
                  </b-field>
                </div>

                <div class="column">

                  <b-field label="Location 2">
                    <b-input v-model="data.location2"></b-input>
                  </b-field>

                </div>

                <div class="column">
                  <b-field label="Location 3">
                    <b-input v-model="data.location3"></b-input>
                  </b-field>
                </div>
              </div>

              <b-field label="Beschreibung">
                <b-input type="textarea" v-model="data.description"></b-input>
              </b-field>

              <b-field label="Interne Notiz">
                <b-input type="textarea" v-model="data.internal_note"></b-input>
              </b-field>


<!--              {{data.articles}}-->
<hr>
              <div class="control" :hidden="isNew">
                <button class="button is-primary" @click="addArticles()"><i class="fa fa-plus"></i>&nbsp; Artikel</button>
              </div>



              <table class="table" width="100%" :hidden="isNew">
                <thead>
                <tr>
                  <th>Artikelnr.</th>
                  <th>Bezeichnung</th>
                  <th>Aktionen</th>
                </tr>
                </thead>

                <tbody>
                <tr
                    v-for="a in data.articles"
                    :value="a.id"
                    :key="a.id">
                  <td>{{ a.id }}</td>
                  <td>{{ a.name }}</td>
                  <td :hidden="readonly">
  <!--                  <b-tooltip label="Eingang buchen">-->
  <!--                    <button class="button is-small is-success" @click="openNewEntry(c.id, c.charge, +1)">+</button>-->
  <!--                  </b-tooltip>-->
                    <b-tooltip label="Mehr Informationen">
                      <button class="button is-small" @click="showArticleSummary(a.id)"><i class="fa fa-eye"></i></button>
                    </b-tooltip>


                    <b-tooltip label="Aus diesem Label entfernen">
                      <button class="button is-small is-danger" @click="deleteArticle(a.id)">-</button>
                    </b-tooltip>
                    <!--            <b-tooltip label="Inventurbuchung" :hidden="readonly">-->
                    <!--              <button class="button is-small" @click="equal">=</button>-->
                    <!--            </b-tooltip>-->

                  </td>

                </tr>
                </tbody>
              </table>



            </article>
          </div>
        </div>






        <div class="tile is-ancestor">
          <div class="tile is-vertical is-16">
            <div class="tile">
              <div class="tile is-parent is-vertical">
                <article class="tile is-child box is-16">

                  <MediaViewer object_type="label" :object_id="this.$route.params.id" v-if="!this.isNew" />

                </article>

              </div>

            </div>
          </div>

        </div>






<!--        <b-field>-->
<!--          <b-button icon-left="user" icon-pack="fa" expanded class="is-primary is-outlined is-medium" @click="openContactPersons" :disabled="isNew">-->
<!--            Ansprechpartner-->
<!--          </b-button>-->
<!--        </b-field>-->

        <div class="tile is-parent">
          <article class="tile is-child">
            <div class="field is-grouped">
              <div class="control">
                <router-link class="" to="/labels">
                  <button class="button is-secondary">Zurück</button>
                </router-link>
              </div>
              <div class="control" :hidden="isNew">
                <button class="button is-link" @click="update">Speichern</button>
              </div>
              <div class="control" :hidden="!isNew">
                <button class="button is-link" @click="create">Erstellen</button>
              </div>
            </div>
          </article>
        </div>


      </div>
    </div>
  </div>
</template>

<script>

import { ToastProgrammatic as Toast, DialogProgrammatic as Dialog } from 'buefy'
import apiservice from "@/services/apiservice";
import VueQRCodeComponent from 'vue-qrcode-component'
import MultipleArticlesPickerPrompt from "@/components/MultipleArticlesPickerPrompt";
import ArticleSummary from "@/components/ArticleSummary";
import MediaViewer from "@/components/MediaViewer";
import LabelService from "@/services/labelservice";
// import ContactPersons from "@/components/ContactPersons";


export default {
  name: 'Label',
  props: {
  },
  data() {
            return {
              data: {},
              isNew: null,

              labelService: null,
              selectedPrinter: null,
              printerLoading: false,
              printMode: false,
              labelPreview: null,
            }
  },
  computed: {
    pagetitle() {
      if (this.isNew){
        return "Neu"
      } else {
        return this.data.title
      }
    }
  },
  components: {
    VueQRCodeComponent, MediaViewer
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get('labels/' + this.$route.params.id)
          .then(response => (this.data = response.data.data))
          .catch(function (error) {
            Dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist beim Abrufen der Daten aufgetreten.',
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    update: function() {
      // let self = this
      apiservice()
          .post('labels/', this.data)
          .then(function (response) {
              Toast.open({
                message: 'Label erfolgreich geändert.',
                position: 'is-bottom',
                type: 'is-success'
              })
              console.log(response);
            // self.$router.push("/labels");
            })
            .catch(function (error) {
              Dialog.alert({
                title: 'Fehler',
                message: 'Ein Fehler ist aufgetreten: ' + error,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
              })
              console.log(error);
            });
    },
    create: function() {
      this.data.id = "new"
      let self = this

      apiservice().post('/labels/', this.data)
          .then(function (response) {
            Toast.open({
              message: 'Label erfolgreich hinzugefügt',
              position: 'is-bottom',
              type: 'is-success'
            })
            console.log(response);
            self.$router.push("/labels/"+response.data.data.id);
          })
          .catch(function (error) {
            Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + error,
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
            console.log(error);
          });
    },
    addArticles: function() {
      this.$buefy.modal.open({
        component: MultipleArticlesPickerPrompt,
        parent: this,
        props: {
          // "question": question
        },
        onCancel: function () {
          console.log()
        },
        events: {
          'confirm': value => {

            let self = this
            apiservice()
                .post('labels/' + this.$route.params.id + "/articles", value)
                .then(function (response) {
                  Toast.open({
                    message: 'Artikel erfolgreich hinzugefügt.',
                    position: 'is-bottom',
                    type: 'is-success'
                  })
                  console.log(response);
                  self.fetchData()
                })
                .catch(function (error) {
                  Dialog.alert({
                    title: 'Fehler',
                    message: 'Ein Fehler ist aufgetreten: ' + JSON.stringify(error),
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                  })
                  console.log(error);
                });
            this.refresh();
          }

        }
      })
    },
    showArticleSummary: function(article_id) {
      this.$buefy.modal.open({
        component: ArticleSummary,
        parent: this,
        props: {
          'article_id': article_id
        },
        onCancel: function () {
          console.log()
        },
        events: {
        }
      })
    },
    deleteArticle: function(id) {
      let self = this;

      this.$buefy.dialog.confirm({
        title: 'Artikel entkoppeln?',
        message: 'Sind Sie sicher, dass Sie den Artikel von diesem Label entkoppeln möchten?',
        confirmText: 'Ja, entkopplen',
        cancelText: 'Nein',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          apiservice().delete('labels/' + this.$route.params.id + '/articles/' + id)
              .then(function (response) {

                self.$buefy.toast.open({
                  message: 'Artikel entkoppelt',
                  position: 'is-bottom',
                  type: 'is-success'
                });
                console.log(response);
                self.fetchData()
              })
              .catch(function (error) {
                self.$buefy.dialog.alert({
                  title: 'Fehler',
                  message: 'Ein Fehler ist aufgetreten: ' + error,
                  type: 'is-danger',
                  hasIcon: true,
                  icon: 'times-circle',
                  iconPack: 'fa',
                  ariaRole: 'alertdialog',
                  ariaModal: true
                })
                console.log(error);
              });
        }
      })
    },
    async initPrinting(){
      this.printerLoading = true;
      this.labelService = new LabelService()
      await this.labelService.init()
      await this.labelService.loadPrinters()
      if (this.labelService.printers && this.labelService.printers.length > 0){
        this.selectedPrinter = this.labelService.printers[0].name
      }
      this.debugOut = this.labelService.printers;
      this.printMode = true;
      this.printerLoading = false;
    },
    printLabel: function() {
      this.labelService.printLabel(this.data, this.selectedPrinter)
    },
    previewLabel: async function() {
      this.labelPreview = await this.labelService.printLabel(this.data, this.selectedPrinter, true)
      console.log(this.labelPreview)
    },
    // openContactPersons: function() {
    //   this.$buefy.modal.open({
    //     component: ContactPersons,
    //     parent: this,
    //     props: {
    //       'relation_type': 'supplier',
    //       'relation_id': this.data.id
    //     },
    //     onCancel: function () {
    //       console.log()
    //     },
    //     events: {
    //     }
    //   })
    // }
  },
  beforeMount() {
    if (this.$route.params.id == "new") {
      this.isNew = true
    } else {
      this.isNew = false
      this.fetchData()
    }

    //this.fetchData();

    // if (this.isNew) {
    //
    // } else {
    //
    // }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
