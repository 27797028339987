<template>
  <form action="">
    <div >
      <p class="title">Wareneingang</p>




      <b-steps
          v-model="activeStep"
          :animated="true"
          :rounded="true"
          :has-navigation="true"
          :label-position="bottom"
          :mobile-mode="compact">
        <b-step-item step="1" label="Artikel">

          <b-field>
            <b-button class="is-large is-center" expanded @click="enableScanArticle = !enableScanArticle" icon-left="qrcode" icon-pack="fa">Scannen</b-button>
          </b-field>
          <b-field>
            <b-button class="is-large is-center" expanded @click="enableScanArticle = !enableScanArticle" icon-left="search" icon-pack="fa">Auswählen</b-button>
          </b-field>

          <b-modal v-model="enableScanArticle">
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">Artikel scannen</p>
              </header>

              <section class="modal-card-body">
                <QRScanner v-if="enableScanArticle" @result="onArticleScan"/>
              </section>

              <footer class="modal-card-foot">
<!--                <b-button-->
<!--                    label="Abbrechen"-->
<!--                    @click="$emit('close')" />-->
              </footer>
            </div>
          </b-modal>

        </b-step-item>

        <b-step-item step="2" label="Charge" :type="{'is-success': isProfileSuccess}">

          <b-field expanded>
            <b-button @click="activeStep=2">Standardcharge</b-button>
          </b-field>
        </b-step-item>

        <b-step-item step="3" label="Lagerplatz">
          <b-field>
            <b-button class="is-large is-center" expanded @click="enableScanStockPlace = !enableScanStockPlace" icon-left="qrcode" icon-pack="fa">Scannen</b-button>
          </b-field>
          <b-field>
            <b-button class="is-large is-center" expanded @click="enableScanStockPlace = !enableScanStockPlace" icon-left="search" icon-pack="fa">Auswählen</b-button>
          </b-field>

          <b-modal v-model="enableScanStockPlace">
            <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                <p class="modal-card-title">Lagerplatz scannen</p>
              </header>

              <section class="modal-card-body">
                <QRScanner v-if="enableScanStockPlace" @result="onStockPlaceScan"/>
              </section>

              <footer class="modal-card-foot">
                <!--                <b-button-->
                <!--                    label="Abbrechen"-->
                <!--                    @click="$emit('close')" />-->
              </footer>
            </div>
          </b-modal>
        </b-step-item>

        <b-step-item step="4" label="Menge">
          <b-field grouped label="Anzahl">

            <b-numberinput
                type="number"
                v-model="amount_input"
                placeholder="Veränderung"
                step="1"
                min-step="0.01"
                controls-position="compact"
                controls-rounded
                required
                :controls="false">
            </b-numberinput>
          </b-field>
          <b-field grouped label="Einheit">
            <ArticleUnitPicker v-model="unit_input" :article_id="article_id" />
          </b-field>
          <b-field>
            <b-button @click="activeStep=4" class="is-primary" expanded>OK</b-button>
          </b-field>
        </b-step-item>



        <b-step-item step="5" label="Kommentar">
          <b-field label="Kommentar:">
            <b-input type="textfield" maxlength="255" has-counter v-model="data.comment"></b-input>
          </b-field>
        </b-step-item>

        <template
            v-if="customNavigation"
            #navigation="{previous, next}">
          <b-button
              outlined
              type="is-danger"
              icon-pack="fas"
              icon-left="backward"
              :disabled="previous.disabled"
              @click.prevent="previous.action">
            Previous
          </b-button>
          <b-button
              outlined
              type="is-success"
              icon-pack="fas"
              icon-right="forward"
              :disabled="next.disabled"
              @click.prevent="next.action">
            Next
          </b-button>
        </template>
      </b-steps>




      <section class="modal-card-body" v-if="false">
        <b-field label="Charge:">
          <b-input
              type="text"
              :value="charge_name"
              disabled>
          </b-input>
        </b-field>

        <b-field>
          <template #label>
            Lagerplatz:
            <b-button class="is-medium" @click="enableScanStockPlace = !enableScanStockPlace"><b-icon icon="qrcode" pack="fa" /></b-button>
<!--            <b-modal v-model="enableScanStockPlace">-->
              <QRScanner v-if="enableScanStockPlace" @result="onStockPlaceScan"/>
<!--            </b-modal>-->

          </template>
          <div :hidden="!stock_place_desc">{{stock_place_desc}}</div>
          <StockPlacePicker v-model="data.stock_place" v-if="stock_place_desc==null" :hidden="enableScanStockPlace" required id />
        </b-field>

        <b-field></b-field>

        <b-field label="Kommentar:">
          <b-input
              type="text"
              v-model="data.comment"
              placeholder="Kommentar zur Buchung"
              required>
          </b-input>
        </b-field>





        <b-field label="Zu buchen in Basiseinheit:" v-if="amount_input && unit_input && article && unit_input.unit.id != article.default_unit.id">
          <span class="is-size-4">{{ amount }} {{article.default_unit.symbol}}</span>
        </b-field>

      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Close"
            @click="$emit('close')" />
        <b-button
            label="Buchen"
            type="is-primary"
            @click="save"
            :disabled="!data.stock_place || !data.comment || !amount_input"
        />
      </footer>
    </div>
    <b-loading is-full-page="true" v-if="isLoading" />
  </form>
</template>

<script>

import StockPlacePicker from "@/components/StockPlacePicker";
import apiservice from "@/services/apiservice";
import ArticleUnitPicker from "@/components/ArticleUnitPicker";
import QRScanner from "@/components/mobile/QRScanner";

// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'StockIncoming',
  components: {ArticleUnitPicker, StockPlacePicker, QRScanner},
  props: ['charge_id', 'charge_name', 'article_id'],
  data() {
    return {
      enableScanStockPlace: false,
      enableScanArticle: false,
      isLoading: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      stock_place_desc: null,
      data: {comment:'Wareneingang'},
      amount_input: 1,
      unit_input: null,
      article: null,
      activeStep: 0
    }
  },
  methods: {
    save: function (){
      this.data.article_charge = this.charge_id
      this.data.amount = this.amount
      let t = this;
      apiservice().post('stock/stock_entry/', this.data)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {

            t.$emit('close')
            // Toast.open({
            //   message: 'Bestandänderung gebucht',
            //   position: 'is-bottom',
            //   type: 'is-success'
            // })
            // console.log(response);
          })
          .catch(function (error) {
            // Dialog.alert({
            //   title: 'Fehler',
            //   message: 'Ein Fehler ist aufgetreten: ' + error,
            //   type: 'is-danger',
            //   hasIcon: true,
            //   icon: 'times-circle',
            //   iconPack: 'fa',
            //   ariaRole: 'alertdialog',
            //   ariaModal: true
            // })
            console.log(error);
          });
    },
    onStockPlaceScan: function(result){
      console.log(result)
      let self = this

      try {
        let resultdata = JSON.parse(result)
        console.log(resultdata)
        this.$buefy.toast.open(resultdata.type)
        this.$buefy.toast.open(resultdata.id.toString())

        this.enableScanStockPlace = false
        this.isLoading = true

        apiservice()
            .get('stock/stock_places/' + resultdata.id)
            .then(response => {
              self.data.stock_place = response.data.data.id
              self.stock_place_desc = response.data.data.warehouse + ", " + response.data.data.rack + ", " + response.data.data.shelf
              self.activeStep = 3;
            });

      } catch(e) {
        if (e instanceof SyntaxError) {
          this.$buefy.toast.open("Kein gültiger Code")
        }
      }


    },
    onArticleScan: function(result){
      this.enableScanArticle = false
      this.activeStep+=1
      console.log(result)
    },
    fetchData: function() {
      apiservice()
          .get('articles/' + this.article_id)
          .then(response => (this.article = response.data.data));
    }
  },
  computed: {
    amount: function() {
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100)
    }
  },
  beforeMount(){
    // this.fetchData()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
