<template>
  <b-datepicker
      v-model="date"
      locale="de-DE"
      :date-formatter="dateFormatter"
      placeholder="Datum"
      icon="calendar-today"
      :disabled="this.$attrs.disabled"
      @input="updateValue"
      @change="updateValue"
      @select="updateValue"
      trap-focus>
  </b-datepicker>
</template>

<script>

export default {
  name: 'DatePicker',
  data() {
    return {
      date: Date
    }
  },
  props: {
    value: String
  },
  methods: {
    updateValue() {
      if (!(this.date.getTime() === this.date.getTime()) || this.date == null) { // NaN ist nie gleich NaN -> date is invalid
        this.value = null
      } else {
        this.value = this.dateISOFormatter(this.date)
      }
      this.$emit('input', this.value)
      this.$emit('change', this.value)
      this.$emit('select', this.value)
    },
    dateFormatter: function(date) {
      if (!(date.getTime() === date.getTime()) || date == null) { // NaN ist nie gleich NaN -> date is invalid
        return null;
      }

      let months = ["Januar", "Februar", "März", "April", "Mai", "Juni" , "Juli", "August", "September", "Oktober", "November", "Dezember"]

      let d = new Date(date)
      return `${d.getDate()}. ${months[d.getMonth()]} ${d.getFullYear()}`
      // return `${("00" + d.getDate()).slice(-2)}.${("00" + (d.getMonth()+1)).slice(-2)}.${d.getFullYear()}`
    },
    dateISOFormatter: function (date) {
      if (!(date.getTime() === date.getTime()) || date == null) { // NaN ist nie gleich NaN -> date is invalid
        return null
      }
      return `${date.getFullYear()}-${("00" + (date.getMonth()+1)).slice(-2)}-${("00" + date.getDate()).slice(-2)}`
    },
    dateParser: function(date) {
      if (date == null)
        return null

      let d = new Date(date);
      if (!(d.getTime() === d.getTime()) || d == null) { // NaN ist nie gleich NaN -> d is invalid
        d = null
      }
      return d
    },
  },
  computed: {
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    'value': function(val, oldVal){
      this.date = this.dateParser(val)
    }
  }
  ,
  beforeMount(){
    this.date = this.dateParser(this.value)
    this.updateValue();
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>