<template>
  <form action="">

    <div>
      <p class="title">Kommissionieren</p>
      <b-field><b-button @click="fetchData" icon-pack="fa" icon-left="sync" expanded>Aktualisieren</b-button></b-field>



      <b-steps
          v-model="activeStep"
          :animated="true"
          :rounded="true"
          :has-navigation="true"
          :label-position="bottom"
          :mobile-mode="compact">

        <b-step-item step="1" label="Auftrag">

          <div v-if="orders_to_pick.length==0">
            <b-message type="is-info" has-icon>
            </b-message>
            Aktuell gibt es keine Aufträge mit offenen Kommissionieraufträgen
            <b-field><b-button @click="fetchData" icon-pack="fa" icon-left="sync" class="is-info is-light" expanded>Aktualisieren</b-button></b-field>
          </div>

          <div class="card mt-3" v-for="item in orders_to_pick" :key="item.id">
            <div class="card-content">
              <div class="media">
                <div class="media-left">
                  <i class="fa fa-box-open"></i>
                </div>
                <div class="media-content">
                  <p class="title is-4">{{ item.customer.name }}</p>
                  <p class="subtitle is-6">
                    <b-tag type="is-primary is-light" v-if="item.project">{{ item.project.name }}</b-tag>
                  </p>
                </div>
              </div>
              <p>Auftrag {{ item.document_nr }}</p>
              <b-field label="Adresse:">{{item.to_name}}<br />{{ item.to_street }}<br />{{ item.to_zip }} {{item.to_place}}</b-field>

              <b-field v-if="item.project" label="Projektadresse:">{{ item.project.street }}<br />{{ item.project.zip }} {{item.project.place}}</b-field>

              <b-field v-if="item.use_delivery_address" label="Lieferadresse:">{{ item.delivery_name }}<br />{{item.delivery_street}}<br />{{ item.delivery_zip }} {{item.delivery_place}}</b-field>

            </div>
            <footer class="card-footer">
              <a href="#" class="card-footer-item" @click="selectOrder(item)"><i class="fa fa-check"></i> Auswählen</a>
            </footer>
          </div>




<!--          <div class="card mt-3">-->
<!--            &lt;!&ndash;            <div class="card-image">&ndash;&gt;-->
<!--            &lt;!&ndash;              <figure class="image is-4by3">&ndash;&gt;-->
<!--            &lt;!&ndash;                <img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image">&ndash;&gt;-->
<!--            &lt;!&ndash;              </figure>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            <div class="card-content">-->
<!--              <div class="media">-->
<!--                <div class="media-left">-->
<!--                  &lt;!&ndash;                  <figure class="image is-48x48">&ndash;&gt;-->
<!--                  &lt;!&ndash;                    <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">&ndash;&gt;-->
<!--                  &lt;!&ndash;                  </figure>&ndash;&gt;-->
<!--                  <i class="fa fa-box-open"></i>-->
<!--                </div>-->
<!--                <div class="media-content">-->
<!--                  <p class="title is-4">Erika Mustermann</p>-->
<!--                  <p class="subtitle is-6">-->
<!--                    <b-taglist>-->
<!--                      <b-tag type="is-primary is-light">Ausbesserung</b-tag>-->
<!--                      &lt;!&ndash;                      <b-tag>First</b-tag>&ndash;&gt;-->
<!--                    </b-taglist>-->
<!--                  </p>-->
<!--                </div>-->
<!--              </div>-->
<!--              <p>Kommissionierschein 1003</p>-->
<!--              <b-field label="Lieferadresse:">Graeser Book 16<br />48683 Ahaus<br />Deutschland</b-field>-->

<!--            </div>-->
<!--            <footer class="card-footer">-->
<!--              <a href="#" class="card-footer-item"><i class="fa fa-check"></i> Auswählen</a>-->
<!--            </footer>-->
<!--          </div>-->

        </b-step-item>

        <b-step-item step="2" label="Kommissionierung">

          <b-field>
            <b-switch v-model="showOnlySpare" class="is-medium">
              Nur offene Positionen
            </b-switch>
          </b-field>


          <div class="card mt-3" v-for="po in picking_orders" :key="po.id" :hidden="showOnlySpare && po.is_fully_prepicked">
            <div class="card-content is-primary">
              <div class="media">
                <p class="card-header-title">
                  {{ po.article.name }}
                </p>

              </div>

<!--                  <b-field label="Benötigte Menge:" horizontal class="is-medium">{{ po.amount }} {{po.unit.symbol}}</b-field>-->


              <table style="width: 100%; text-align: center">
                <thead>
                  <tr>
                    <th>Gesamt:</th>
                    <th>Warenausgang:</th>
                    <th>Kommissioniert:</th>
                    <th>Offen:</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
<!--                    <td><b-tag class="is-medium is-primary">{{ po.amount }} {{po.unit.symbol}}</b-tag></td>-->
<!--                    <td><b-tag class="is-medium is-success is-light">{{ po.picked_amount.toFixed(2) }} {{po.unit.symbol}}</b-tag></td>-->
<!--                    <td></td>-->
<!--                    <td><b-tag class="is-medium is-danger is-light">{{ (po.amount - po.picked_amount).toFixed(2) }} {{po.unit.symbol}}</b-tag></td>-->

                        <td><b-tag class="is-medium is-primary">{{ po.amount }} {{po.article.default_unit ? po.article.default_unit.symbol : ""}}</b-tag></td>
                        <td><b-tag class="is-medium is-success is-light">{{po.shipped_amount}} {{po.article.default_unit ? po.article.default_unit.symbol : ""}}</b-tag></td>
                        <td><b-tag class="is-medium is-warning is-light">{{po.prepicked_amount}} {{po.article.default_unit ? po.article.default_unit.symbol : ""}}</b-tag></td>
                        <td><b-tag class="is-medium is-danger is-light">{{po.amount - po.prepicked_amount - po.shipped_amount}} {{po.article.default_unit ? po.article.default_unit.symbol : ""}}</b-tag></td>
                  </tr>
                </tbody>

              </table>
              <div class="mt-3 mb-3">
<!--                <b-progress :value="(po.prepicked_amount / po.amount)*100" size="is-large" show-value-->
<!--                            v-bind:type="{'is-danger' : po.picked_amount < po.amount, 'is-success' : po.picked_amount >= po.amount}">-->
<!--                  {{ po.picked_amount.toFixed(2) }} / {{ po.amount }} {{po.unit.symbol}}-->
<!--                </b-progress>-->




                <b-progress format="percent" :max="po.amount" size="is-large" show-value>
                  <template #bar>
                    <b-progress-bar :value="po.shipped_amount" type="is-success" show-value></b-progress-bar>
                    <b-progress-bar :value="po.prepicked_amount" type="is-warning" show-value></b-progress-bar>
                    <b-progress-bar :value="(po.amount - po.prepicked_amount - po.shipped_amount)" type="is-danger" show-value></b-progress-bar>
                  </template>
                </b-progress>

              </div>

<!--              <b-field label="Gesamt:"><b-tag class="is-medium is-primary">{{ po.amount }} {{po.unit.symbol}}</b-tag></b-field>-->
<!--              <b-field label="Gepickt:" horizontal><b-tag class="is-medium is-success is-light">{{ po.amount }} {{po.unit.symbol}}</b-tag></b-field>-->
<!--              <b-field label="Gepickt:" horizontal><b-tag class="is-medium is-success is-light">{{ po.amount }} {{po.unit.symbol}}</b-tag></b-field>-->

<!--              <div class="columns">-->
<!--                <div class="column">-->
<!--&lt;!&ndash;                  <div style="text-align: center;"><p style="font-size: 1.4em;">Benötigt gesamt:</p><b-tag class="is-large is-primary">{{ po.amount }} {{po.unit.symbol}}</b-tag></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <b-field label="Gesamt:" horizontal><b-tag class="is-large is-primary">{{ po.amount }} {{po.unit.symbol}}</b-tag></b-field>&ndash;&gt;-->

<!--                </div>-->
<!--                <div class="column">-->
<!--&lt;!&ndash;                  <div style="text-align: center;"><p style="font-size: 1.4em;">Bereits gepickt:</p><b-tag class="is-large is-success is-light">{{ po.amount }} {{po.unit.symbol}}</b-tag></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <b-field label="Gepickt:"><b-tag class="is-medium is-success is-light">{{ po.amount }} {{po.unit.symbol}}</b-tag></b-field>&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="column">-->
<!--&lt;!&ndash;                  <div style="text-align: center;"><p style="font-size: 1.4em;">Noch benötigt:</p><b-tag class="is-large is-danger is-light">{{ po.amount }} {{po.unit.symbol}}</b-tag></div>&ndash;&gt;-->
<!--&lt;!&ndash;                  <b-field label="Gepickt:"><b-tag class="is-medium is-success is-light">{{ po.amount }} {{po.unit.symbol}}</b-tag></b-field>&ndash;&gt;-->

<!--                </div>-->
<!--              </div>-->





<!--                  <ArticleStock :article_id="po.article.id" :readonly="true" />-->




              <div v-for="charge in po.stock" :key="charge.charge_id">
                <hr>
                <p class="subtitle is-5">
                  Charge: {{ charge.charge_name }}
                </p>


                <table class="table">
                  <thead>
                  <tr>
                    <th>Halle</th>
                    <th>Regal</th>
                    <th>Fach</th>
                    <th>Bestand</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="p in charge.stockplaces"
                      :value="p.stock_place_id"
                      :key="p.stock_place_id">
                    <td>{{ p.warehouse }}</td>
                    <td>{{ p.rack }}</td>
                    <td>{{ p.shelf }}</td>
                    <td>
                      <b-button v-bind:class="{ 'is-success' : p.amount >= (po.amount - po.prepicked_amount - po.shipped_amount), 'is-danger' : p.amount<(po.amount - po.picked_amount - po.shipped_amount) }"
                                    @click="openNewEntry(po.id, po.article.id, charge.charge_id, p.stock_place_id, po.amount - po.prepicked_amount - po.shipped_amount)">
                        {{p.amount.toFixed(2)}} {{po.article.default_unit ? po.article.default_unit.symbol : ""}}
                      </b-button>
                    </td>

                  </tr>
                  </tbody>
                </table>

              </div>







<!--              <p>Kommissionierschein {{ po.document_nr }}</p>-->
<!--              <b-field label="Adresse:">{{po.to_name}}<br />{{ po.to_street }}<br />{{ po.to_zip }} {{po.to_place}}</b-field>-->

<!--              <b-field v-if="po.project" label="Projektadresse:">{{ po.project.street }}<br />{{ po.project.zip }} {{po.project.place}}</b-field>-->

<!--              <b-field v-if="po.use_delivery_address" label="Lieferadresse:">{{ po.delivery_name }}<br />{{po.delivery_street}}<br />{{ po.delivery_zip }} {{po.delivery_place}}</b-field>-->

            </div>

          </div>

<!--          <b-field><b-button class="is-medium is-primary mt-5" expanded @click="activeStep=2">Abschließen</b-button></b-field>-->

        </b-step-item>

<!--        <b-step-item step="3" label="Abschließen">-->

<!--          <p class="title is-4">Diesen Kommissionierschein als fertig bearbeitet markieren?</p>-->

<!--          <b-field><b-button class="is-large is-primary" expanded @click="finalize()" icon-pack="fa" icon-left="check">Abschließen</b-button></b-field>-->
<!--          <b-field><b-button class="is-large is-primary is-light" expanded @click="activeStep=1; fetchData()" icon-pack="fa" icon-left="chevron-left">Zurück</b-button></b-field>-->

<!--        </b-step-item>-->

      </b-steps>
    </div>

    <b-loading is-full-page="true" v-if="isLoading" />
  </form>
</template>

<script>


import apiservice from "@/services/apiservice";
import NewPrePickingEntry from "@/components/mobile/NewPrePickingEntry";
// import {DialogProgrammatic as Dialog, ToastProgrammatic as Toast} from "buefy/types/components";

export default {
  name: 'StockPicking',
  components: {},
  props: ['charge_id', 'charge_name', 'article_id'],
  data() {
    return {
      enableScanStockPlace: false,
      enableScanArticle: false,
      isLoading: false,
      username: "",
      password: "",
      // amount: 0,
      comment: "",
      stock_place: null,
      stock_place_desc: null,
      data: {},
      orders_to_pick: null,
      picking_orders: null,
      selected_order: 0,
      amount_input: 1,
      unit_input: null,
      article: null,
      activeStep: 0,
      showOnlySpare: true
    }
  },
  methods: {
    finalize: function (){
      let data = {id: this.selected_order.id}
      let t = this;
      apiservice().post('stock/picking/finalize_picking_note', data)
          // eslint-disable-next-line no-unused-vars
          .then(function (response) {

            // t.$emit('close')
            t.$buefy.toast.open("Kommissionierschein abgeschlossen!")
            t.$router.push({ path: '/stockmobile' })

          })
          .catch(function (error) {
            t.$buefy.dialog.alert({
              title: 'Fehler',
              message: 'Ein Fehler ist aufgetreten: ' + JSON.stringify(error.data),
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
            console.log(error);
          });
    },
    onStockPlaceScan: function(result){
      console.log(result)
      let self = this

      try {
        let resultdata = JSON.parse(result)
        console.log(resultdata)
        this.$buefy.toast.open(resultdata.type)
        this.$buefy.toast.open(resultdata.id.toString())

        this.enableScanStockPlace = false
        this.isLoading = true

        apiservice()
            .get('stock/stock_places/' + resultdata.id)
            .then(response => {
              self.data.stock_place = response.data.data.id
              self.stock_place_desc = response.data.data.warehouse + ", " + response.data.data.rack + ", " + response.data.data.shelf
            });

      } catch(e) {
        if (e instanceof SyntaxError) {
          this.$buefy.toast.open("Kein gültiger Code")
        }
      }


    },
    onArticleScan: function(result){
      this.enableScanArticle = false
      this.activeStep+=1
      this.fetchData()
      console.log(result)
    },
    openNewEntry: function(picking_order_id, article_id, article_charge_id, source_stock_place_id, amount_needed) {
      let self = this;
      this.$buefy.modal.open({
        parent: this,
        component: NewPrePickingEntry,
        props: {
          'article_id': article_id,
          'article_charge_id': article_charge_id,
          'picking_order_id': picking_order_id,
          'source_stock_place_id': source_stock_place_id,
          'amount_needed': amount_needed
        },
        hasModalCard: true,
        customClass: '',
        trapFocus: true,
        events: {
          'close': function () {
            self.fetchData();
          }
        }
      })
    },
    fetchData: function() {
      this.isLoading = true

      // Kommissionierschein
      if (this.activeStep == 0) {
        apiservice()
            .get('stock/picking/orders')
            .then(response => (this.orders_to_pick = response.data.data));
      }

      // Items to Pick
      if (this.activeStep == 1) {
        apiservice()
            .get('stock/picking/order/' + this.selected_order.id  + '/pickingorders/')
            .then(response => (this.picking_orders = response.data.data));
      }

    },

    selectOrder: function (order) {
      this.selected_order = order
      this.activeStep = 1
      this.fetchData()
    }
  },
  computed: {
    amount: function() {
      return parseFloat(Math.round((this.amount_input*this.unit_input.factor)/10000)/100)
    }
  },
  beforeMount(){
    this.fetchData()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
