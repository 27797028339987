<template>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Lagerbestand: {{charge_name}}</p>
        <button
            type="button"
            class="delete"
            @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <table class="table">
          <thead>
            <tr>
              <th>Halle</th>
              <th>Regal</th>
              <th>Fach</th>
              <th>Bestand</th>
            </tr>
          </thead>
          <tbody>
            <tr
                v-for="p in places"
                :value="p.id"
                :key="p.id">
              <td>{{ p.warehouse }}</td>
              <td>{{ p.rack }}</td>
              <td>{{ p.shelf }}</td>
              <td>{{ p.amount }}</td>

            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <b-button
            label="Schließen"
            @click="$emit('close')" />
<!--        <b-button-->
<!--            label="Buchen"-->
<!--            type="is-primary" />-->
      </footer>
    </div>
</template>

<script>

//const API_URL = 'api/auth/';


//import apiservice from "@/services/apiservice";

import apiservice from "@/services/apiservice";

export default {
  name: 'StockChargeDetails',
  props: ['charge_id', 'charge_name'],
  data() {
    return {
      places: [],
      inProgress: false,
      username: "",
      password: "",
      amount: 0,
      comment: ""
    }
  },
  methods: {
    fetchData: function () {
      apiservice()
          .get("stock/charge/" + this.charge_id + "/stock_places/")
          .then(response => (this.places = response.data.data));
    }
  },
  beforeMount(){
    this.fetchData()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  color: black;
}
</style>
