<template>

  <div class="columns">
    <div class="column">
      <b-field label="Halle" custom-class="is-small">
        <b-select placeholder="Halle" v-model="warehouse"
                  v-bind:class="{ 'is-loading': stockplaces == [] }" @input="selectedWarehouse()" expanded>
          <option
              v-for="option in Object.keys(this.stockplaces)"
              :value="option"
              :key="option"
              :class="isStocked(option, null, null) && !hide_nonstocked ? 'highlighted' : '' "
              :hidden="!isStocked(option, null, null) && hide_nonstocked">

            {{ option }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column">
      <b-field label="Regal" custom-class="is-small">
        <b-select placeholder="Regal" v-model="rack" v-if="warehouse != ''"
                  v-bind:class="{ 'is-loading': stockplaces == [] }" @input="selectedRack()" expanded>
          <option
              v-for="option in racks"
              :value="option"
              :key="option"
              :class="isStocked(warehouse, option, null) && !hide_nonstocked ? 'highlighted' : '' "
              :hidden="!isStocked(warehouse, option, null) && hide_nonstocked">

            {{ option }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="column">
      <b-field label="Fach" custom-class="is-small">
        <b-select placeholder="Fach" v-model="value" v-if="rack != ''"
                  v-bind:class="{ 'is-loading': stockplaces == [] }"
                  @input="updateValue" @change="updateValue" @emptied="updateValue" expanded>
          <option
              v-for="(k, v) in shelves"
              :value="k"
              :key="v"
              :class="isStocked(warehouse, rack, v) && !hide_nonstocked ? 'highlighted' : '' "
              :hidden="!isStocked(warehouse, rack, v) && hide_nonstocked">

            {{ v }}
          </option>
        </b-select>
      </b-field>
    </div>
  </div>

</template>

<script>

import apiservice from "@/services/apiservice";


export default {
  name: 'StockPlacePicker',
  data() {
    return {
      stockplaces: [],
      warehouse: "",
      rack: "",
      charge_stock_places : []
    }
  },
  props: {
    value: Object,
    article_charge_id: Number,
    article_charge: Object,
    hide_nonstocked: Boolean
  },
  watch: {
    // article_charge_id: function() {
    //   console.log("charge change triggered")
    //   this.fetchData()
    // },
    article_charge: function() {
      console.log("charge change triggered")
      // this.warehouse = "";
      // this.rack = "";
      // this.value = null;
      this.fetchData()

      this.updateValue();
    }
  },
  methods: {
    fetchData: function() {
      apiservice()
          .get("stock/stock_places/")
          .then(response => (this.stockplaces = response.data.data));

      if (this.article_charge) {
        apiservice()
            .get("stock/charge/" + this.article_charge.id + "/stock_places/")
            .then(response => (this.charge_stock_places = response.data.data));
      }
    },
    updateValue() {
      this.$emit('input', this.value)
    },
    selectedWarehouse: function (){
      this.rack = "";
      this.value = null;
      this.updateValue();
      // this.debug = Object.keys(this.stockplaces[this.warehouse])[0]
      // if (this.stockplaces[this.warehouse].length == 1) {
      //   this.rack = Object.keys(this.stockplaces[this.warehouse])[0];
      // }
    },
    selectedRack: function (){
      this.value = null;
      this.updateValue();
    },
    isStocked: function (warehouse, rack, shelf) {
      // console.log((warehouse + " ||||| " +  rack + " |||||| " + shelf))
      for (let i = 0; i<this.charge_stock_places.length; i++) {
        let place = this.charge_stock_places[i]
        // console.log(place)
        // console.log("place.warehouse: " + place.warehouse)
        // console.log("warehouse: " + warehouse)
        if (place.warehouse == warehouse) {
          if (!rack && !shelf) {
            // console.log(("w" + warehouse))
            return true
          }
          if (place.rack == rack) {
            if (!shelf) {
              return true
            }
            if (place.shelf == shelf) {
              return true
            }
          }
        }
      }
      return false
    }
  },
  // watch: {
  //   value: function (newvalue) {
  //     // eslint-disable-next-line no-unused-vars
  //     for (const [wk, wv] of Object.entries(this.stockplaces)) {
  //       // eslint-disable-next-line no-unused-vars
  //       for (const [rk, rv] of Object.entries(this.stockplaces[wk])) {
  //         // eslint-disable-next-line no-unused-vars
  //         for (const [sk, sv] of Object.entries(this.stockplaces[wk][rk])) {
  //           if (sv == newvalue) {
  //             this.warehouse = wk;
  //             this.rack = rk;
  //           }
  //         }
  //       }
  //     }
  //   }
  // },
  computed: {
    racks: function (){
      if (this.warehouse == "")
        return [];

      return Object.keys(this.stockplaces[this.warehouse])
    },
    shelves: function (){
      if (this.racks == "")
          return [];

      return this.stockplaces[this.warehouse][this.rack]

    }

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .highlighted {
    font-weight: bold;
    background: #8c67ef;
    color: #fff;
  }
</style>