<template>
<!--  <div class="block">-->
<!--    <div class="field"-->
<!--        v-for="i in paymentconditions"-->
<!--        :key="i.id">-->
<!--      <b-radio v-model="value"-->
<!--               name="value"-->
<!--               :native-value="i.id"-->
<!--               @input="updateValue"-->
<!--               >-->
<!--        <b-tooltip :label="i.text" multilined>-->
<!--          {{ i.short }}-->
<!--        </b-tooltip>-->
<!--      </b-radio>-->
<!--    </div>-->

<!--  </div>-->
  <b-select placeholder="Zahlungsziel" v-model="value"
  v-bind:class="{ 'is-loading': suppliers == [] }"
  @input="updateValue">
    <option
      v-for="option in paymentconditions"
      :value="option"
      :key="option.id">

        {{ option.short }}

    </option>
  </b-select>
</template>

<script>

import apiservice from "@/services/apiservice";

export default {
  name: 'PaymentConditionPicker',
  data() {
    return {
      paymentconditions: []
    }
  },
  props: ['value'],
  methods: {
    fetchData: function() {
      apiservice()
          .get("basic/payment_conditions")
          .then(response => (this.paymentconditions = response.data.data));
    },
    updateValue() {
      this.$emit('input', this.value)
      this.$emit('change', this.value)
    }
  },
  computed: {

  },
  beforeMount(){
    this.fetchData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>